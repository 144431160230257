import * as React from "react";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { initializeIcons } from '@fluentui/react';
import { withTranslation, WithTranslation } from "react-i18next";
import { TFunction } from "i18next";
import moment, { locale } from "moment";
import { Utils } from "../../../utility/Utils";
import { UxUtils } from "../../../utility/UxUtils";
import { Flex, List, Loader, FocusZone, Text, Avatar } from "@fluentui/react-northstar";

interface IUserInfoViewProps {
    userName: string;
    accessibilityLabel?: string;
}

export interface NonResponderViewState {
    loader: boolean;
}

export interface NonResponderViewProps extends WithTranslation {
    
}

class NonResponderView extends React.Component<NonResponderViewProps, NonResponderViewState> {

    private localize: TFunction;
   
    constructor(props: NonResponderViewProps) {
        super(props);
        
        this.localize = this.props.t;
        
        this.state = {
            loader: false
        }
    }

    public render() {
        
        if (this.state.loader){
            return <Loader />;
        }

        const items = [
            {
                key: 'robert',
                media: (
                  <Avatar
                    image="https://fabricweb.azureedge.net/fabric-website/assets/images/avatar/RobertTolbert.jpg"
                  />
                ),
                header: 'Robert Tolbert',
                headerMedia: '7:26:56 AM',
                content: 'Program the sensor to the SAS alarm through the haptic SQL card!',
              },
              {
                key: 'celeste',
                media: (
                  <Avatar
                    image="https://fabricweb.azureedge.net/fabric-website/assets/images/avatar/CelesteBurton.jpg"
                  />
                ),
                header: 'Celeste Burton',
                headerMedia: '11:30:17 PM',
                content: 'Use the online FTP application to input the multi-byte application!',
              },
              {
                key: 'cecil',
                media: (
                  <Avatar
                    image="https://fabricweb.azureedge.net/fabric-website/assets/images/avatar/CecilFolk.jpg"
                  />
                ),
                header: 'Cecil Folk',
                headerMedia: '5:22:40 PM',
                content: 'The GB pixel is down, navigate the virtual interface!',
              },
        ]    

        //   // GET USERS NONRESPONDERS
        // if (getStore().progressStatus.nonResponder == ProgressState.Completed) {
        //     for (let userProfile of getStore().nonResponders) {
        //         userProfile = getStore().userProfile[userProfile.id];

        //         if (userProfile) {
        //             rowsWithUser.push({
        //                 userName: userProfile.displayName,
        //                 accessibilityLabel: userProfile.displayName,
        //             });
        //         }
        //     }
        // }

        return (
            <FocusZone className="zero-padding" isCircularNavigation={true}>
                <Flex column className="list-container" gap="gap.small">
                    <List items={items} />
                </Flex>
            </FocusZone>
        );
    }
}

const nonResponderViewWithTranslation = withTranslation()(NonResponderView);
export default nonResponderViewWithTranslation;