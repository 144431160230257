import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { UxUtils } from "../../utility/UxUtils";
// import "./Settings.scss";
import { DateTimePickerView } from "../Shared/DateTime/DateTimePickerView";
// import { RadioGroupMobile } from "../RadioGroupMobile";
import { ProgressState, PagesQuiz, Visibility, VisibilityQuiz, VisibilityResultQuiz } from "../../utility/SharedEnum";
import { Flex, Text, ChevronStartIcon, Checkbox, RadioGroup } from "@fluentui/react-northstar";
// import { Label } from "office-ui-fabric-react";


export interface ISettingsQuizComponentProps {
    dueDate: string;
    locale?: string;
    showCorrectAnswer: boolean;
    visibilityResult?: VisibilityResultQuiz;
    itemsVisibility?: VisibilityQuiz;
    rowsVisibility?: VisibilityQuiz;
    itemsEditable?: boolean;
    canUserAddMultipleItems?: boolean;
    stringsLocalize?: any;
    onChange?: (props: ISettingsQuizComponentProps) => void;
    onMount?: () => void;
    onBack?: () => void;    
}

/**
 * <Settings> Settings component of creation view of poll
 */
 class SettingsQuiz extends React.Component<ISettingsQuizComponentProps, any> {
    private settingProps: any = null;
    constructor(props: ISettingsQuizComponentProps) {
        super(props);

        this.settingProps = {
            dueDate: this.props.dueDate,
            locale: this.props.locale,
            showCorrectAnswer: this.props.showCorrectAnswer
        };
    }

    componentDidMount() {
        if (this.props.onMount) {
            this.props.onMount();
        }
    }

    render() {
        return (
            <Flex column>
                {this.renderDueBySection()}
            </Flex>
        );
    }

    /**
     * Rendering due date section for settings view
     */
    private renderDueBySection() {
        // handling mobile view differently
        let className = "settings-indentation";
        return (
            <Flex className="settings-item-margin" role="group" aria-label={this.props.stringsLocalize("dueBy")} column gap="gap.smaller">
                <label className="settings-item-title">{this.props.stringsLocalize("dueBy")}</label>
                <div className={className}>
                    <DateTimePickerView
                        minDate={new Date()}
                        locale={this.props.locale}
                        value={new Date(this.props.dueDate)}
                        placeholderDate={this.props.stringsLocalize("datePickerPlaceholder")}
                        placeholderTime={this.props.stringsLocalize("timePickerPlaceholder")}
                        onSelect={(date: Date) => {
                            if(this.props.dueDate){
                               this.settingProps.dueDate = date.toISOString();
                               if(this.props.onChange){
                                    this.props.onChange(this.settingProps);
                               }                                
                            }                            
                        }} />
                </div>
            </Flex>
        );
    }

     /**
     * Rendering result show correct answer radio button
     */
    private renderShowCorrectAnswer() {
       
        return (
            <>
                <Checkbox 
                    label={this.props.stringsLocalize("showCorrectAnswer")} 
                    checked={this.props?.showCorrectAnswer} 
                    onChange={(event: any, data: any) => {                        
                        this.settingProps.showCorrectAnswer = data.checked;
                        
                        if(this.props.onChange){
                            this.props.onChange(this.settingProps);
                        }
                    }}
                />
                <Text content={this.props.stringsLocalize("answerCannotChange")} />
            </>
        );
    }

    /**
     * Footer for settings view
     */
    private getBackElement() {
        return (
            <Flex className="footer-layout" gap={"gap.smaller"}>
                <Flex vAlign="center" className="pointer-cursor" {...UxUtils.getTabKeyProps()}
                    onClick={() => {
                        if(this.props.onBack){
                            this.props.onBack();
                        }
                    }}
                >
                    <ChevronStartIcon xSpacing="after" size="small" />
                    <Text content={this.props.stringsLocalize("Back")} />
                </Flex>
            </Flex>
        );
    }

    private getVisibilityItems(resultsVisibleToAllLabel: string, resultsVisibleToSenderLabel: string) {
        return [
            {
                key: "1",
                label: resultsVisibleToAllLabel,
                value: Visibility.All,
                className: "settings-radio-item"
            },
            {
                key: "2",
                label: resultsVisibleToSenderLabel,
                value: Visibility.Sender,
                className: "settings-radio-item-last"
            },
        ];
    }
}

const settingsQuizWithTranslation = withTranslation()(SettingsQuiz);
export default settingsQuizWithTranslation;