import * as React from "react";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { initializeIcons } from '@fluentui/react';
import { withTranslation, WithTranslation } from "react-i18next";
import { TFunction } from "i18next";
import moment, { locale } from "moment";
import { Flex, FlexItem, Image, Input, Text, TextArea } from "@fluentui/react-northstar";
import {
    CameraIcon
} from '@fluentui/react-icons-northstar';

export interface IImageContent {
    title?: string;
    titleError?: boolean;
    description?: string;
    image?: string;
    imageError?: boolean;
    isImageAdded?: boolean;
}

export interface ImageContentTrainingProps extends WithTranslation{
    content?: IImageContent;
    onUpdateImageContent?: (title?:string, image?:string, description?: string) => void;
}

class ImageContentTraining extends React.Component<ImageContentTrainingProps, any> {

    private localize: TFunction;
    constructor(props: ImageContentTrainingProps) {
        super(props);
        
        initializeIcons();
        this.localize = this.props.t;
    }

    render(){
        
        return(
            <Flex column gap="gap.medium">
                <FlexItem>
                    <div className="inputField">
                        <Flex space="between">
                            <FlexItem>
                                <Text className="label-field" content={this.localize("titleContent")}></Text>
                            </FlexItem>
                            <FlexItem push>
                                <Text className={this.props.content?.titleError ? "text-alert label-field" : "text-alert label-field display-none"} content={this.localize("required")}></Text>
                            </FlexItem>
                        </Flex>

                        <Input
                            fluid
                            className={this.props.content?.titleError ? "inputField input-alert" : "inputField"}
                            placeholder={this.localize("addTitlePlaceholder")}
                            value={this.props.content?.title}
                            onChange={(e: any, data: any) => {
                                if(this.props.onUpdateImageContent){
                                    this.props.onUpdateImageContent(data.value);
                                }
                            }}
                        />
                    </div>
                </FlexItem>
                <FlexItem>
                    <div className="inputField" style={{marginBottom: "0px"}}>
                        <Flex space="between">
                            <FlexItem>
                                <Text className="label-field" content={this.localize("photo")}></Text>
                            </FlexItem>
                            <FlexItem push>
                                <Text className={this.props.content?.imageError ? "text-alert label-field" : "text-alert label-field display-none"} content={this.localize("required")}></Text>
                            </FlexItem>
                        </Flex>

                        {this.props.content?.image ?                                  
                            <Flex column>
                                <Flex>
                                    <FlexItem push>
                                        <Text className="btn-text" content={this.localize("clear")} onClick={() => { this.clearImagePreview() }}></Text>
                                    </FlexItem>                                                                                                
                                </Flex> 
                                <Flex hAlign="center">
                                    <FlexItem>
                                        <Image className="quiz-img-preview" src={this.props.content?.image} />
                                    </FlexItem>
                                </Flex>  
                            </Flex>                                                                                                     
                        :
                            <FlexItem>
                                <div className="inputField" onClick={this.triggerHandleUpload.bind(this)}>
                                    <input type="file" name="input-file" id="input-file" className="inputFile" accept="image/*" onChange={(event) => { this.handleUpload(event); }} />
                                    <Flex column hAlign="center" vAlign="center" className="photo-box">
                                        <CameraIcon
                                            outline
                                        />
                                        
                                        <Text content={this.localize("tapUploadPhoto")}></Text>
                                    </Flex>
                                </div>                                
                            </FlexItem>
                        }                                    
                    </div>                                
                </FlexItem>
                <FlexItem>
                    <div className="inputField">
                        <Text className="label-field" content={this.localize("descriptionContent")}></Text>
                        <TextArea
                            fluid
                            styles={{
                                height: "200px"
                            }}
                            placeholder={this.localize("addDescriptionPlaceholder")}
                            value={this.props.content?.description}
                            onChange={(e: any, data: any) => {
                                if(this.props.onUpdateImageContent){
                                    this.props.onUpdateImageContent(null as any, null as any, data.value);
                                }
                            }}
                        >
                        </TextArea>
                    </div>
                </FlexItem>
            </Flex>            
        );
    }

    private handleUpload(event: any) {
        
        let file = event.target.files[0];

        if (file) {

            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {

                var image = reader.result as string;

                if(this.props.onUpdateImageContent){
                    this.props.onUpdateImageContent(null as any, image);
                }
                
                // this.setState({
                //     image: reader.result as string,
                //     isImageAdded: true
                // })

                // if ((this.state.fileBase64 === "") || !(this.state.fileBase64.startsWith("data:image/png;base64,") || this.state.fileBase64.startsWith("data:image/jpeg;base64,") || this.state.fileBase64.startsWith("data:image/jpg;base64,") || this.state.fileBase64.startsWith("data:image/gif;base64,"))) {
                //     this.setState({
                //         errorImageUrlMessage: this.localize("ErrorUpload"),
                //         fileBase64: "",
                //         file: null,
                //         fileName: "",
                //         isImageAdded: false
                //     });
                //     (document.getElementById("input-file") as HTMLInputElement).value = "";
                //     return;

                // } else {
                //     this.setState({
                //         errorImageUrlMessage: "",
                //     });
                // }
            };
        }
    }

    private triggerHandleUpload(event: any) {

        document.getElementById("input-file")!.click();
    }

    private clearImagePreview(){
        if(this.props.onUpdateImageContent){
            this.props.onUpdateImageContent(null as any, "");
        }
        
    }
}

const imageContentTrainingWithTranslation = withTranslation()(ImageContentTraining);
export default imageContentTrainingWithTranslation;