import * as React from 'react';
import { withTranslation, WithTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { Button, Flex, FlexItem, Image, Text } from "@fluentui/react-northstar";
import { ISentNotification } from "./../galleryWrapperPage";
import MarkdownIt from "markdown-it";
import parse from 'html-react-parser';
import { MediaType } from '../../../utility/SharedEnum';
import * as AdaptiveCards from "adaptivecards";
import { getInitAdaptiveCardVideo, setCardVideoAuthor, setCardVideoBtns, setCardVideoLink, setCardVideoPosterLink, setCardVideoSummary, setCardVideoTitle } from '../../AdaptiveCard/adaptiveCardVideo';
import {
    getInitAdaptiveCard, setCardTitle, setCardImageLink, setCardSummary,
    setCardAuthor, setCardBtns
} from '../../AdaptiveCard/adaptiveCard';

interface MessageCardViewProps extends WithTranslation {
    cardDetails: ISentNotification;
    index: number;
}

export interface IMessageCardViewState {
    cardDetails: ISentNotification;
    card?: any,
}

class MessageCardView extends React.Component<MessageCardViewProps, IMessageCardViewState> {
    readonly localize: TFunction;
    private card: any;

    constructor(props: MessageCardViewProps) {
        super(props);
        this.localize = this.props.t;
        this.card = this.props.cardDetails?.mediaType == MediaType.Video ? getInitAdaptiveCardVideo(this.localize) : getInitAdaptiveCard(this.localize);

        this.localize = this.props.t;
        this.state = {
            cardDetails: this.props.cardDetails,
            card: this.card,
        }
    }

    public async componentDidMount() {
        this.setCardProperties();
    }

    /**
    * Renders the component
    */
    public render(): JSX.Element {
        return (
            <Flex gap="gap.smaller" column vAlign="start">
                <Flex column wrap>
                    <FlexItem>
                        <div className={`${this.state.cardDetails?.id} adaptive-card`}>
                        </div>
                    </FlexItem>
                </Flex>
            </Flex>
        );
    }

    private setCardProperties = () => {
        if (this.props.cardDetails?.mediaType == MediaType.Video) {
            setCardVideoAuthor(this.card, this.state.cardDetails.author);
            setCardVideoBtns(this.card, this.state.cardDetails.buttons);
            setCardVideoPosterLink(this.card, this.state.cardDetails.video?.poster?.fileLocalPath ?? this.state.cardDetails.video?.poster?.urlBlob);
            setCardVideoSummary(this.card, this.state.cardDetails.summary);
            setCardVideoTitle(this.card, this.state.cardDetails.title);
            setCardVideoLink(this.card, this.state.cardDetails.video?.sources?.[0]);
        }
        else {
            setCardAuthor(this.card, this.state.cardDetails.author);
            setCardBtns(this.card, this.state.cardDetails.buttons);
            setCardImageLink(this.card, this.state.cardDetails.imageLink);
            setCardSummary(this.card, this.state.cardDetails.summary);
            setCardTitle(this.card, this.state.cardDetails.title);
        }        

        this.setState({
            card: this.card,
        }, () => {
            this.updateCard();
        })
    }

    private updateCard = () => {
        const adaptiveCard = new AdaptiveCards.AdaptiveCard();
        adaptiveCard.parse(this.state.card);
        adaptiveCard.hostConfig.actions.maxActions = 6;
        const renderedCard = adaptiveCard.render();
        const container = document.getElementsByClassName(`${this.state.cardDetails?.id}`)[0].firstChild;

        if (container != null) {
            container.replaceWith(renderedCard!);
        } else {
            document.getElementsByClassName(`${this.state.cardDetails?.id}`)[0].appendChild(renderedCard!);
        }
    }
}

const MessageCardViewWithTranslation = withTranslation()(MessageCardView);
export default MessageCardViewWithTranslation;