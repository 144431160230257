import * as React from "react";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { initializeIcons, Icon } from '@fluentui/react';
import { withTranslation, WithTranslation } from "react-i18next";
import { TFunction } from "i18next";
import moment, { locale } from "moment";
import {
    getInitAdaptiveCardTraining
} from '../AdaptiveCard/adaptiveCardTraining';
import * as ACData from "adaptivecards-templating";
import * as AdaptiveExpressions from "adaptive-expressions";
import * as AdaptiveCards from "adaptivecards";
import { Input, Image, Flex, FlexItem, CircleIcon, Button, Loader, ArrowLeftIcon, Segment, SettingsIcon, SplitButton, Text, TextArea, Video } from "@fluentui/react-northstar";
import {
    AcceptIcon, CallVideoIcon, CameraIcon, ChevronStartIcon, FilesEmptyIcon, FilesImageIcon, FormatIcon, QuestionCircleIcon, TrashCanIcon
} from '@fluentui/react-icons-northstar';
import { Utils } from "../../utility/Utils";
import { UxUtils } from "./../../utility/UxUtils";
import { PagesTraining, TypesContentsTraining, VisibilityTraining, VisibilityResultTraining } from "../../utility/SharedEnum";
import SettingsTraining, {ISettingsTrainingComponentProps} from "./SettingsTraining";
import TextContentTraining, {ITextContent} from "./Contents/textContentTraining";
import QuestionContainer, { IQuestion, IQuestionOptions } from "../Shared/QuestionContainer/QuestionContainer";
import ImageContentTraining, {IImageContent} from "./Contents/imageContentTraining";
import VideoContentTraining, {IVideoContent} from "./Contents/videoContentTraining";
import DocumentContentTraining, {IDocumentContent, IFile} from "./Contents/documentContentTraining";
import { NewQuizConstants } from "../Constants/newQuiz";
import './newTraining.scss';
import { throws } from "assert";
import { TooltipHostBase } from "office-ui-fabric-react";

export interface NewTrainingState {
    settings: ISettingsTrainingComponentProps;
    isImageAdded: boolean;
    file: any;
    fileName: string;
    fileBase64: string;
    errorImageUrlMessage: string;
    currentPage: PagesTraining; 
    title: string;
    titleError: boolean;
    description: string;
    typeContent: TypesContentsTraining | null;    
    textContent?: ITextContent;
    imageContent?: IImageContent;
    videoContent?: IVideoContent;
    documentContent?: IDocumentContent;
    questionsContent: IQuestion[];
    contents: any[];
    contentsError: boolean;
    card?: any; 
    openBtnAddContent?: boolean;
}

export interface ITraining {
    id?: string,
    title: string,
    description: string,
    imageTraining: string,
    status: string,
    contents: any[],
    settings: ISettingsTrainingComponentProps,
    summary: ISummary,
    teams: any[],
    rosters: any[],
    groups: any[],
    allUsers: boolean,
    strings?: string[]
}

export interface ISummary {
    rowCreatorCount: number
}

export type NewTrainingProps = {} & RouteComponentProps & WithTranslation;

class NewTraining extends React.Component<NewTrainingProps, NewTrainingState> {

    private localize: TFunction;
    private card: any;
    private itemsBtnAddContent: any[] = [];
    private numberQuestions: any;

    constructor(props: NewTrainingProps) {
        super(props);
        
        initializeIcons();
        this.localize = this.props.t;
        this.card = getInitAdaptiveCardTraining(this.localize);

        this.itemsBtnAddContent.push(
            {
            key: TypesContentsTraining.Text,
            content: this.localize("text"),
            icon: <FormatIcon outline />
            },
            {
            key: TypesContentsTraining.Image,
            content: this.localize("photo"),
            icon: <FilesImageIcon outline />
            },
            {
            key: TypesContentsTraining.Document,
            content: this.localize("document"),
            icon: <Icon iconName="Document" />
            },
            {
            key: TypesContentsTraining.Video,
            content: this.localize("video"),
            icon: <CallVideoIcon outline />
            },
            {
            key: TypesContentsTraining.Quiz,
            content: this.localize("quiz"),
            icon: <QuestionCircleIcon outline />
            }
        );

        this.state = {
            card: this.card,
            settings: {
                showCorrectAnswer: false,   // result of poll will be visible to everyone
                allowMultipleAttempt: false,
                dueDate: Utils.getDefaultExpiry(7).getTime(), // default due date for poll is one week
            },
            isImageAdded: false,
            file: null,
            fileName: "",
            fileBase64: "",
            errorImageUrlMessage: "",
            currentPage: PagesTraining.Main,
            title: "",
            titleError: false,
            description: "",  
            openBtnAddContent: false,  
            typeContent: null,
            questionsContent: [
                {
                    text: "",
                    valueError: false,
                    image: "",
                    questionPlaceholder: this.localize("enterTheQuestion"),
                    checkedCorrectAnswer: true,
                    options: [
                            NewQuizConstants.newOption(this.localize("option"))
                        ,
                            NewQuizConstants.newOption(this.localize("option"))
                    ]
                }
            ],
            contents: [],
            contentsError: false
        }
    }

    public async componentDidMount() {    
    
    }

    public render(): JSX.Element {

        if (this.state.currentPage == PagesTraining.Main){
            return(
                <div className="new-training">
                    <div className="formContainer">
                        <Flex column className="body-container">

                            {this.state.currentPage == PagesTraining.Main &&                            
                                <>
                                    <Flex column gap="gap.medium">
                                        
                                        <FlexItem>
                                            <>
                                                <Flex styles={{marginBottom: "0.8rem !important"}}>                                                    
                                                    <FlexItem>
                                                        <Text content={this.localize("trainingTitle")}></Text>
                                                    </FlexItem>
                                                    <FlexItem push>
                                                        <Text className={this.state.titleError ? "text-alert label-field" : "text-alert label-field display-none"} content={this.localize("required")}></Text>
                                                    </FlexItem>
                                                </Flex>
                                                
                                                <Input
                                                    fluid 
                                                    className={this.state.titleError ? "inputField input-alert" : "inputField"}
                                                    placeholder={this.localize("trainingTitle")}
                                                    value={this.state.title}
                                                    onChange={this.updateTrainingTitle}
                                                />
                                            </>
                                            
                                        </FlexItem>

                                        <FlexItem>
                                            <div className="inputField">
                                                <Text className="label-field" content={this.localize("trainingDescriptionOptional")}></Text>
                                                <TextArea
                                                    fluid
                                                    variables={{
                                                        height: '90px'
                                                    }}
                                                    placeholder={this.localize("trainingDescriptionOptional")}
                                                    value={this.state.description}
                                                    onChange={this.updateTrainingDescription}
                                                />
                                            </div> 
                                        </FlexItem>                                             

                                        <FlexItem>
                                            <div className="inputField" style={{marginBottom: "0px"}}>
                                                <Text className="label-field" content={this.localize("coverImage")}></Text>

                                                {this.state.isImageAdded ?                                     
                                                    <Flex column>
                                                        <Flex>
                                                            <FlexItem push>
                                                                <Text className="btn-text" content={this.localize("clear")} onClick={() => { this.clearImagePreview() }}></Text>
                                                            </FlexItem>                                                                                                
                                                        </Flex> 
                                                        <Flex hAlign="center">
                                                            <FlexItem>
                                                                <Image className="training-img-preview" src={this.state.fileBase64} />
                                                            </FlexItem>
                                                        </Flex>  
                                                    </Flex>                                                                                                     
                                                :
                                                    <FlexItem>
                                                        <div className="inputField" onClick={this.triggerHandleUpload.bind(this)}>
                                                            <input type="file" name="input-file" id="input-file" className="inputFile" accept="image/*" onChange={(event) => { this.handleUpload(event); }} />
                                                            <Flex column hAlign="center" vAlign="center" className="photo-box">
                                                                <CameraIcon
                                                                    outline
                                                                />
                                                                
                                                                <Text content={this.localize("uploadCoverImage")}></Text>
                                                            </Flex>
                                                        </div>                                
                                                    </FlexItem>
                                                }                                    
                                            </div>                                
                                        </FlexItem>
                                    </Flex>

                                    {this.renderFooterSection()}    
                                </>              
                            }
                        </Flex>
                    </div>
                </div>
            )
        }
        else if(this.state.currentPage == PagesTraining.Settings){
            return (
                <div className="new-training">
                    <div className="formContainer">
                        <SettingsTraining {...this.getPropsSettings()} />                      
                    </div>
                </div>
            );
        }
        else if(this.state.currentPage == PagesTraining.ContentContainer){
            return(
                <div className="new-training">
                    <div className="formContainer">
                        <Flex column gap="gap.medium" className="body-container">
                            
                            <Flex column gap="gap.medium">
                                <Flex hAlign="start">
                                    <FlexItem>
                                        <Image className="training-img-preview" src={this.state.fileBase64} />
                                    </FlexItem>
                                </Flex>
                                <FlexItem>
                                    <Text weight="bold" size="large" content={this.state.title} />
                                </FlexItem>
                                <FlexItem>
                                    <Text content={this.state.description} />
                                </FlexItem>
                            </Flex>

                            {this.renderContentContainer()}

                            <Flex>
                                <FlexItem>
                                    <SplitButton
                                        primary
                                        align={"end"}
                                        menu={this.itemsBtnAddContent}
                                          button={{
                                            content: this.localize("addContent"),
                                            'aria-roledescription': 'splitbutton',
                                            'aria-describedby': 'instruction-message',
                                          }}
                                          onOpenChange={(e) => {
                                            this.setState({
                                                openBtnAddContent: !this.state.openBtnAddContent
                                            })
                                          }}
                                          onMainButtonClick={(e, data) => {
                                              this.setState({
                                                  openBtnAddContent: !this.state.openBtnAddContent
                                              })
                                            ;
                                          }}
                                          open={this.state.openBtnAddContent}
                                          onMenuItemClick={(e, data) => {
                                                this.goToPage(PagesTraining.AddContentContainer);
                                                this.setActiveIndexButton(e, data);
                                            }}
                                    >
                                    </SplitButton>
                                    
                                </FlexItem>
                            </Flex>

                            <Flex>
                                <Text className={this.state.contentsError ? "text-alert label-field" : "text-alert label-field display-none"} content={this.localize("atleastOneContent")}></Text>
                            </Flex>

                            <Flex className="footer-layout" gap={"gap.smaller"}>
                                <FlexItem>
                                    <Flex vAlign="center" className="pointer-cursor" {...UxUtils.getTabKeyProps()}
                                        onClick={() => {
                                            this.goToPage(PagesTraining.Main);                                        
                                        }}
                                    >
                                        <ChevronStartIcon xSpacing="after" size="small" />
                                        <Text content={this.localize("Back")} />
                                    </Flex>
                                </FlexItem>

                                <FlexItem push>
                                    <Button
                                        primary
                                        content={this.localize("Next")}
                                        onClick={() => {
                                            if(this.state.contents.length > 0){
                                                this.setState({
                                                    contentsError: false
                                                })
                                                this.createTraining();
                                            }
                                            else{
                                                this.setState({
                                                    contentsError: true
                                                })
                                            }
                                            
                                        }}>
                                    </Button>
                                </FlexItem>
                            </Flex>

                        </Flex>
                    </div>
                </div>
            );
        }
        else if(this.state.currentPage == PagesTraining.AddContentContainer){
            return (
                <div className="new-training">
                    <div className="formContainer">
                        <Flex column gap="gap.medium" className="body-container">
                            {this.renderAddContentSection()}
                        </Flex>
                        {this.renderFooterAddContentSection()}
                    </div>
                </div>
            );
        }
        else if(this.state.currentPage == PagesTraining.Preview){
            return (
                <div className="new-training">
                    <div className="formContainer">
                        <Flex gap="gap.medium" column className="body-container">
                            <Text styles={{"display": "block"}} content="Aqui está uma visualização do cartão que você enviará." />
                            {this.renderPreviewSection()}
                        </Flex>
                        {this.renderFooterPreviewSection()}
                    </div>
                </div>
            );
        }
        else {
            return (<div>Error</div>);
        }
    }


    private goToPage = (page: any) => {
        this.setState({
            currentPage: page
        }, () => {
            if(page == PagesTraining.Preview){
                this.updateCard();
            } 
            else if(page == PagesTraining.AddContentContainer){
                this.clearContents();
            }           
        });
    }

    private updateCard = () => {        
        const adaptiveCard = new AdaptiveCards.AdaptiveCard();
        adaptiveCard.parse(this.state.card);
        const renderedCard = adaptiveCard.render();
        const container = document.getElementsByClassName('adaptiveCardContainerTraining')[0].firstChild;
        if (container != null) {
            container.replaceWith(renderedCard!);
        } else {
            document.getElementsByClassName('adaptiveCardContainerTraining')[0].appendChild(renderedCard!);
        }
    }

    /**
     * Helper function to provide footer for main page
     * @param isMobileView true or false based of whether its for mobile view or not
     */
     public renderFooterSection(isMobileView?: boolean) {

        let className = isMobileView ? "" : "footer-layout";
        return (
            <Flex className={className} gap={"gap.smaller"}>
                {this.renderFooterSettingsSection()}
                <FlexItem push>
                    <Button
                        primary
                        content={this.localize("Next")}
                        onClick={() => {

                            if(this.state.title){
                                this.goToPage(PagesTraining.ContentContainer);
                            }
                            else{
                                this.setState({
                                    titleError: true
                                })
                            }
                        }}>
                    </Button>
                </FlexItem>
            </Flex>
        );
    }

    /**
     * Setting summary and button to switch from main view to settings view
     */
     public renderFooterSettingsSection() {
        return (
            <div className="settings-summary-footer" {...UxUtils.getTabKeyProps()}                
                onClick={() => {
                    this.goToPage(PagesTraining.Settings);
                }}>
                <SettingsIcon className="settings-icon" outline={true} styles={({ theme: { siteVariables } }) => ({
                    color: siteVariables.colorScheme.brand.foreground,
                })} />
                <Text content={this.getSettingsSummary()} size="small" color="brand" />
            </div>
        );
    }

    private renderPreviewSection() {
        return (
            <Flex column>                
                <div className="indentation">
                    <Flex.Item>
                        <div className="adaptiveCardContainerTraining">
                        </div>
                    </Flex.Item>
                </div>
            </Flex>
        );
    }

    private renderFooterPreviewSection(isMobileView?: boolean) {
        let className = isMobileView ? "" : "footer-layout";
        return (
            <Flex className={className} gap={"gap.smaller"} hAlign="end">                
                <FlexItem>
                    <Button
                        primary
                        content={this.localize("Edit")}
                        onClick={() => {
                            // callActionInstanceCreationAPI();
                            this.goToPage(PagesTraining.ContentContainer);
                        }}>
                    </Button>
                </FlexItem>
                <FlexItem>
                    <Button
                        primary
                        content={this.localize("Send")}
                        onClick={() => {
                            // // callActionInstanceCreationAPI();
                            // this.createQuiz();
                        }}>
                    </Button>
                </FlexItem>
            </Flex>
        );
    }

    private renderContentContainer() {
        
        let items: JSX.Element[] = [];
        
        for(let i = 0; i < this.state.contents.length; i++){
            let numberContent = i + 1; 
            items.push(            
                <div key={"content-" + numberContent}>
                    <div className="card-box card-border card-bg">
                        <Segment 
                            styles={{ boxShadow: "rgba(0 0 0 / 10%) 0px 0.2rem 0.4rem -0.075rem" }}
                        >
                            {this.state.contents[i].type === TypesContentsTraining[TypesContentsTraining.Text] && 
                                this.renderTextContent(i)
                            }

                            {this.state.contents[i].type === TypesContentsTraining[TypesContentsTraining.Image] && 
                                this.renderImageContent(i)
                            }

                            {this.state.contents[i].type === TypesContentsTraining[TypesContentsTraining.Document] && 
                                this.renderDocumentContent(i)
                            }

                            {this.state.contents[i].type === TypesContentsTraining[TypesContentsTraining.Video] && 
                                this.renderVideoContent(i)
                            }

                            {this.state.contents[i].type === TypesContentsTraining[TypesContentsTraining.Quiz] && 
                                <Flex column gap={"gap.medium"}>
                                   {this.renderQuizContent(i)}
                                </Flex>
                            }
                        </Segment>
                    </div>  
                </div>
            )
        }
                
        return(
            <>{items}</>
        );
    }

    public renderTextContent = (i: any) => {

        return(
            <Flex column gap="gap.medium">
                <Flex>
                    <FlexItem>
                        <Text 
                            className={"content-title"} 
                            content={this.state.contents[i].title}                                             
                        />
                    </FlexItem>

                    <FlexItem push>
                        <TrashCanIcon                                                 
                            outline
                            styles={{cursor: "pointer"}}
                            onClick={() => {
                                if(this.deleteContent){
                                    this.deleteContent(i);
                                }
                            }}   
                        /> 
                    </FlexItem>
                    
                </Flex>

                <Flex>
                    <Text
                        content={this.state.contents[i].description}
                    />
                </Flex>
            </Flex>
        );
    }

    public renderImageContent = (i: any) => {
        
        return (
            <Flex column gap="gap.medium">
                <Flex>
                    <FlexItem>
                        <Text 
                            className={"content-title"} 
                            content={this.state.contents[i].title}                                             
                        />
                    </FlexItem>

                    <FlexItem push>
                        <TrashCanIcon                                                 
                            outline
                            styles={{cursor: "pointer"}}
                            onClick={() => {
                                if(this.deleteContent){
                                    this.deleteContent(i);
                                }
                            }}   
                        /> 
                    </FlexItem>
                    
                </Flex>

                <Flex hAlign="center">
                    <FlexItem>
                        <Image className="quiz-img-preview" src={this.state.contents[i].image} />
                    </FlexItem>
                </Flex>

                <Flex>
                    <Text
                        content={this.state.contents[i].description}
                    />
                </Flex>
            </Flex>
        );
    }

    public renderDocumentContent = (i: any) => { 
        return(
            <Flex column gap="gap.medium">
                <Flex>
                    <FlexItem>
                        <Text 
                            className={"content-title"} 
                            content={this.state.contents[i].title}                                             
                        />
                    </FlexItem>

                    <FlexItem push>
                        <TrashCanIcon                                                 
                            outline
                            styles={{cursor: "pointer"}}
                            onClick={() => {
                                if(this.deleteContent){
                                    this.deleteContent(i);
                                }
                            }}   
                        /> 
                    </FlexItem>
                    
                </Flex>

                <Flex gap="gap.small">
                    <FlexItem>
                        <Icon iconName="TextDocument" color="black" />
                    </FlexItem>
                    <FlexItem>
                        <Text 
                            className="btn-text" 
                            content={this.state.contents[i].document.fileName} 
                            // onClick={() => {
                            //     window.location.href = this.props.content?.document?.fileBase64 ? this.props.content?.document?.fileBase64 : "";
                            // }}
                        />
                    </FlexItem>
                </Flex>

                <Flex>
                    <Text
                        content={this.state.contents[i].description}
                    />
                </Flex>
            </Flex>
        );
    }

    public renderVideoContent = (i: any) => {
        return(
            <Flex column gap="gap.medium">
                <Flex>
                    <FlexItem>
                        <Text 
                            className={"content-title"} 
                            content={this.state.contents[i].title}                                             
                        />
                    </FlexItem>

                    <FlexItem push>
                        <TrashCanIcon                                                 
                            outline
                            styles={{cursor: "pointer"}}
                            onClick={() => {
                                if(this.deleteContent){
                                    this.deleteContent(i);
                                }
                            }}   
                        /> 
                    </FlexItem>
                    
                </Flex>

                <Flex hAlign="center">
                    <FlexItem>
                        <Video 
                            className="quiz-img-preview" 
                            src={this.state.contents[i].video} 
                            styles={{
                                width: "100%",
                                height: "100%"
                            }}
                        />
                    </FlexItem>
                </Flex>

                <Flex>
                    <Text
                        content={this.state.contents[i].description}
                    />
                </Flex>
            </Flex>
        );
    }

    public renderQuizContent = (i: any) => {
        let items: JSX.Element[] = [];
        
        for(let indexQuestion = 0; indexQuestion < this.state.contents[i]?.questions.length; indexQuestion++){
            
            let numberQuestion = indexQuestion + 1; 
            items.push(
                <Flex column gap="gap.medium">
                    <Flex>
                        <FlexItem>
                            <Text 
                                className={"question-number"} 
                                content={ `${this.localize("question")} # ${(numberQuestion)}` }                                             
                            />
                        </FlexItem> 
                        
                        {indexQuestion == 0 &&
                            <FlexItem push>
                                <TrashCanIcon                                                 
                                    outline
                                    styles={{cursor: "pointer"}}
                                    onClick={() => {
                                        if(this.deleteContent){
                                            this.deleteContent(i);
                                        }
                                    }}   
                                /> 
                            </FlexItem>
                        }
                                               
                    </Flex>

                    <Flex column>
                        <Flex className="questions-img-box">
                            <FlexItem>
                                <Image className="questions-img-preview" src={this.state.contents[i]?.questions[indexQuestion].image}></Image>
                            </FlexItem>  
                        </Flex>  

                        <FlexItem>
                            <Input
                                fluid 
                                value={this.state.contents[i]?.questions[indexQuestion].value}
                                disabled={true}
                            />                             
                        </FlexItem>                        
                    </Flex>

                   {this.renderQuestionOptions(this.state.contents[i]?.questions[indexQuestion].options, indexQuestion)}

                                                    
                </Flex>



            )
        }
                
        return(items);
    }

    public renderQuestionOptions = (options: any, indexQuestion: any) => {
        let itemsQuestionOptions = [];

        for(let i = 0; i < options.length; i++){
            itemsQuestionOptions.push(            
                <Flex gap="gap.smaller">
                    <FlexItem>
                        <CircleIcon outline size="small" className="choice-item-circle" disabled /> 
                    </FlexItem>
                    
                    <FlexItem grow>
                        <Flex column>
                            {options[i].image && 
                                <Flex className="questions-img-box">
                                    <FlexItem>
                                        <Image className="questions-img-preview" src={options[i].image}></Image>
                                    </FlexItem>  
                                </Flex>
                            }          

                            <FlexItem>    
                                <Flex>                                        
                                    <Input 
                                        className={options[i].optionError ? "input-options input-alert" : "input-options"} 
                                        icon={
                                            <Flex>                                                                                    
                                                <Flex gap="gap.small">                                                                                          
                                                    <FlexItem>
                                                        <AcceptIcon 
                                                            styles={options[i].correctOption ? {color: "rgb(146 195 83)"} : {}}
                                                            className="icons-click"
                                                            outline                                                                 
                                                        />
                                                    </FlexItem>
                                                </Flex>
                                            </Flex>                            
                                        } 
                                        placeholder={options[i].choicePlaceholder}
                                        value={options[i].value}
                                        disabled={true}
                                        fluid 
                                    />
                                </Flex>
                            </FlexItem>
                            
                        </Flex>
                    </FlexItem>         
                </Flex>
            );
        }

        return itemsQuestionOptions;
    }

    private renderAddContentSection() {
        
        var content;
        
        if(this.state.typeContent == TypesContentsTraining.Text){
            content = 
                <TextContentTraining
                    onUpdateTextContent={(title, description) => {
                        this.updateTextContent(title, description);
                    }}
                    content={this.state.textContent}
                />
        }
        else if(this.state.typeContent == TypesContentsTraining.Quiz){
            content =
                <QuestionContainer 
                    questions={this.state.questionsContent}
                    onAddQuestion={() => {
                        this.addQuestion();
                    }}
                    onUpdateQuestion={(i, value, imageQuestion) => {
                        this.updateQuestion(i, value, imageQuestion);
                    }}
                    onDeleteQuestion={(i) => {
                        this.deleteQuestion(i);
                    }}
                    onAddOption={(indexQuestion: any) => {
                        this.addOption(indexQuestion);
                    }} 
                    onUpdateChoice={(i, indexChoice, value, correctOption, imageOption) => {                        
                        this.updateChoice(i, indexChoice, value, correctOption, imageOption);
                    }}
                    onDeleteChoice={(i, indexChoice) => {
                        this.deleteChoice(i, indexChoice);
                    }}
                />
        }
        else if(this.state.typeContent == TypesContentsTraining.Image){
            content = 
                <ImageContentTraining 
                    onUpdateImageContent={(title, image, description) => {
                        this.updateImageContent(title, image, description);
                    }}   
                    content={this.state.imageContent}          
                />
        }
        else if(this.state.typeContent == TypesContentsTraining.Video){
            content = 
                <VideoContentTraining 
                    onUpdateVideoContent={(title, video, description) => {
                        this.updateVideoContent(title, video, description);
                    }}   
                    content={this.state.videoContent}          
                />
        }
        else if(this.state.typeContent == TypesContentsTraining.Document){
            content = 
                <DocumentContentTraining 
                    onUpdateDocumentContent={(title, document, description) => {
                        this.updateDocumentContent(title, document, description);
                    }}   
                    content={this.state.documentContent}          
                />
        }
        
        return content;
    }

    private renderFooterAddContentSection() {
        return (
            <Flex className="footer-layout" gap={"gap.smaller"}>
                <FlexItem>
                    <Flex vAlign="center" className="pointer-cursor" {...UxUtils.getTabKeyProps()}
                        onClick={() => {
                            this.clearContents();
                            this.goToPage(PagesTraining.ContentContainer);                                        
                        }}
                    >
                        <ChevronStartIcon xSpacing="after" size="small" />
                        <Text content={this.localize("Back")} />
                    </Flex>
                </FlexItem>

                <FlexItem push>
                    <Button
                        primary
                        content={this.localize("done")}
                        onClick={() => {
                            this.validContents();
                        }}>
                    </Button>
                </FlexItem>
            </Flex>
        );
    }

    private setActiveIndexButton =  (event: any, item: any) => {
        
        let activeIndex = this.itemsBtnAddContent[item.index];
        
        this.setState({
            typeContent: activeIndex.key
        });
    }

    private deleteContent = (indexContent: any) => {
        const contentsCopy = [...this.state.contents];
        contentsCopy.splice(indexContent, 1);
        this.setState({
            contents: contentsCopy
        });
    }
   
    private getPropsSettings(){
        let settingsProps: ISettingsTrainingComponentProps = {
            ...this.getCommonSettingsProps(),
            onBack: () => {
                this.goToPage(PagesTraining.Main);
            }
        };

        return settingsProps;
    }

    /**
     * Helper method to provide common settings props for both mobile and web view
     */
     public getCommonSettingsProps() {
        return {
            showCorrectAnswer: this.state.settings.showCorrectAnswer,
            allowMultipleAttempt: this.state.settings.allowMultipleAttempt,
            dueDate: this.state.settings.dueDate,
            locale: locale(),
            stringsLocalize: this.localize,
            onChange: (props: ISettingsTrainingComponentProps) => {
                this.updateSettings(props);
            },
            onMount: () => {
                
            },
        };
    }

    private updateSettings = (settingProps: ISettingsTrainingComponentProps) => {
        this.setState({
            settings: settingProps
        });
    }

    /**
     * method to get the setting summary from selected due date and result visibility
     */
     private getSettingsSummary(): string {
        let settingsStrings: string[] = [];
        let dueDate = new Date(this.state.settings.dueDate);
        let showCorrectAnswer = this.state.settings.showCorrectAnswer;
        if (dueDate) {
            let dueDateString: string;
            let dueDateValues: number[];
            let dueIn: any = Utils.getTimeRemaining(dueDate);
            if (dueIn["Utils.YEARS"] > 0) {
                dueDateString = dueIn[Utils.YEARS] == 1 ? "DueInYear" : "DueInYears";
                dueDateValues = [dueIn[Utils.YEARS]];
            } else if (dueIn[Utils.MONTHS] > 0) {
                dueDateString = dueIn[Utils.MONTHS] == 1 ? "DueInMonth" : "DueInMonths";
                dueDateValues = [dueIn[Utils.MONTHS]];
            } else if (dueIn[Utils.WEEKS] > 0) {
                dueDateString = dueIn[Utils.WEEKS] == 1 ? "DueInWeek" : "DueInWeeks";
                dueDateValues = [dueIn[Utils.WEEKS]];
            } else if (dueIn[Utils.DAYS] > 0) {
                dueDateString = dueIn[Utils.DAYS] == 1 ? "DueInDay" : "DueInDays";
                dueDateValues = [dueIn[Utils.DAYS]];
            } else if (dueIn[Utils.HOURS] > 0 && dueIn[Utils.MINUTES] > 0) {
                if (dueIn[Utils.HOURS] == 1 && dueIn[Utils.MINUTES] == 1) {
                    dueDateString = "DueInHourAndMinute";
                } else if (dueIn[Utils.HOURS] == 1) {
                    dueDateString = "DueInHourAndMinutes";
                } else if (dueIn[Utils.MINUTES] == 1) {
                    dueDateString = "DueInHoursAndMinute";
                } else {
                    dueDateString = "DueInHoursAndMinutes";
                }
                dueDateValues = [dueIn[Utils.HOURS], dueIn[Utils.MINUTES]];
            } else if (dueIn[Utils.HOURS] > 0) {
                dueDateString = dueIn[Utils.HOURS] == 1 ? "DueInHour" : "DueInHours";
                dueDateValues = [dueIn[Utils.HOURS]];
            } else {
                dueDateString = dueIn[Utils.MINUTES] == 1 ? "DueInMinute" : "DueInMinutes";
                dueDateValues = [dueIn[Utils.MINUTES]];
            }
            settingsStrings.push(Utils.getStringLocalizer(this.localize(dueDateString), ...dueDateValues));
        }

        if (showCorrectAnswer) {           
            settingsStrings.push(this.localize("correctAnswer"));
        }

        return settingsStrings.join(". ");
    }

    private clearContents = () => {
        this.setState({
            questionsContent: [
                {
                    text: "",
                    valueError: false,
                    image: "",
                    questionPlaceholder: this.localize("enterTheQuestion"),
                    checkedCorrectAnswer: true,
                    options: [
                            NewQuizConstants.newOption(this.localize("option"))
                        ,
                            NewQuizConstants.newOption(this.localize("option"))
                    ]
                }
            ],
            textContent: {
                title: "",
                titleError: false,
                description: "",
            },
            imageContent: {
                title: "",
                titleError: false,
                description: "",
                image: "",
                imageError: false,
                isImageAdded: false
            },
            documentContent: {
                title: "",
                titleError: false,
                description: "",
                document: "" as any,
                documentError: false
            },
            videoContent: {
                title: "",
                titleError: false,
                description: "",
                video: "",
                videoError: false
            }
        })
    }

    private validContents = () => {
        
        var contents = this.state.contents;

        if(this.state.typeContent == TypesContentsTraining.Text){
            
            if(!this.state.textContent?.title){
                this.setState({
                    textContent: {
                        titleError: true
                    }
                })
            }
            else{
                var textContent = {
                    type: TypesContentsTraining[this.state.typeContent],
                    title: this.state.textContent.title,
                    description: this.state.textContent.description
                }

                contents.push(textContent);

                this.setState({
                    contents: contents
                })

                this.goToPage(PagesTraining.ContentContainer);
            }

        }
        else if(this.state.typeContent == TypesContentsTraining.Image){
            
            var titleError = false;
            var imageError = false;

            if(!this.state.imageContent?.title){
                titleError = true;
            }

            if(!this.state.imageContent?.image){
                imageError = true;
            }

            if(!titleError && !imageError)
            {
                var imageContent = {
                    type: TypesContentsTraining[this.state.typeContent],
                    title: this.state.imageContent?.title,
                    image: this.state.imageContent?.image,
                    description: this.state.imageContent?.description
                }

                contents.push(imageContent);

                this.setState({
                    contents: contents
                })

                this.goToPage(PagesTraining.ContentContainer);
            }
            else{
                this.setState({
                    imageContent: {
                        title: this.state.imageContent?.title,
                        titleError: titleError,
                        image: this.state.imageContent?.image,
                        imageError: imageError,
                        description: this.state.imageContent?.description
                    }
                })
            }
        }
        else if(this.state.typeContent == TypesContentsTraining.Document){
            
            var titleError = false;
            var documentError = false;

            if(!this.state.documentContent?.title){
                titleError = true;
            }

            if(!this.state.documentContent?.document){
                documentError = true;
            }

            if(!titleError && !documentError)
            {
                var documentContent = {
                    type: TypesContentsTraining[this.state.typeContent],
                    title: this.state.documentContent?.title,
                    document: this.state.documentContent?.document,
                    description: this.state.documentContent?.description
                }

                contents.push(documentContent);

                this.setState({
                    contents: contents
                })

                this.goToPage(PagesTraining.ContentContainer);
            }
            else{
                this.setState({
                    documentContent: {
                        title: this.state.documentContent?.title,
                        titleError: titleError,
                        document: this.state.documentContent?.document,
                        documentError: documentError,
                        description: this.state.documentContent?.description
                    }
                })
            }
        }
        else if(this.state.typeContent == TypesContentsTraining.Video){
            
            var titleError = false;
            var videoError = false;

            if(!this.state.videoContent?.title){
                titleError = true;
            }

            if(!this.state.videoContent?.video){
                videoError = true;
            }

            if(!titleError && !videoError)
            {
                var videoContent = {
                    type: TypesContentsTraining[this.state.typeContent],
                    title: this.state.videoContent?.title,
                    video: this.state.videoContent?.video,
                    description: this.state.videoContent?.description
                }

                contents.push(videoContent);

                this.setState({
                    contents: contents
                })

                this.goToPage(PagesTraining.ContentContainer);
            }
            else{
                this.setState({
                    videoContent: {
                        title: this.state.videoContent?.title,
                        titleError: titleError,
                        video: this.state.videoContent?.video,
                        videoError: videoError,
                        description: this.state.videoContent?.description
                    }
                })
            }
        }
        else if(this.state.typeContent == TypesContentsTraining.Quiz){
            var validQuestion = true;
            var checkedCorrectAnswer = false;

            const questionsCopy = [...this.state.questionsContent];

            this.state.questionsContent.forEach((question, indexQuestion) => {

                if(!question.text && !question.image){
                    validQuestion = false;                
                    
                    questionsCopy[indexQuestion].valueError = true;    
                }

                // Check if question contains option checked correct answer
                checkedCorrectAnswer = question.options.some(opt => opt.correctOption === true);
                questionsCopy[indexQuestion].checkedCorrectAnswer = checkedCorrectAnswer; 

                question.options.forEach((option, indexOption) => {
                    
                    if(!option.text && !option.image){
                        validQuestion = false;

                        questionsCopy[indexQuestion].options[indexOption].optionError = true; 
                    }
                })
            })

            this.setState({
                questionsContent: questionsCopy
            })

            if(validQuestion && checkedCorrectAnswer){
                var questionsContent = {
                    type: TypesContentsTraining[this.state.typeContent],
                    questions: this.state.questionsContent
                }

                contents.push(questionsContent);

                this.setState({
                    contents: contents
                })

                this.goToPage(PagesTraining.ContentContainer);
            }
            
        }
    }

    /* INICIAL - MÉTODOS PARA TRAINING */
    private updateTrainingTitle = (event: any) => {
                        
        this.setState({
            title: event.target.value,
            titleError: false
        });
    }

    private updateTrainingDescription = (event: any) => {
        this.setState({
            description: event.target.value
        });
    }

    private handleUpload(event: any) {
        
        let file = event.target.files[0];

        if (file) {

            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                this.setState({
                    fileBase64: reader.result as string,
                    file: file,
                    fileName: file.name,
                    isImageAdded: true
                })

                if ((this.state.fileBase64 === "") || !(this.state.fileBase64.startsWith("data:image/png;base64,") || this.state.fileBase64.startsWith("data:image/jpeg;base64,") || this.state.fileBase64.startsWith("data:image/jpg;base64,") || this.state.fileBase64.startsWith("data:image/gif;base64,"))) {
                    this.setState({
                        errorImageUrlMessage: this.localize("ErrorUpload"),
                        fileBase64: "",
                        file: null,
                        fileName: "",
                        isImageAdded: false
                    });
                    (document.getElementById("input-file") as HTMLInputElement).value = "";
                    return;

                } else {
                    this.setState({
                        errorImageUrlMessage: "",
                    });
                }
            };
        }
    }

    private triggerHandleUpload(event: any) {
        document.getElementById("input-file")!.click();
    }

    private clearImagePreview(){
        this.setState({
            fileBase64: "",
            file: null,
            fileName: "",
            isImageAdded: false
        })
    }
    /* FIM - MÉTODOS PARA TRAINING */

    /* INÍCIO - MÉTODOS PARA TEXT CONTENT */
    private updateTextContent = (title?: string, description?: string) => {
        
        this.setState({
            textContent: {
                title: title !== null && title !== undefined ? title : this.state.textContent?.title,
                description: description !== null && description !== undefined ? description : this.state.textContent?.description,
                titleError: false
            }
        })
    }
    /* FIM - MÉTODOS PARA TEXT CONTENT */

    /* INÍCIO - MÉTODOS PARA IMAGE CONTENT */
    private updateImageContent = (title?: string, image?:string, description?: string) => {
        
        this.setState({
            imageContent: {
                title: title !== null && title !== undefined ? title : this.state.imageContent?.title,
                image: image !== null && image !== undefined ? image : this.state.imageContent?.image,
                description: description !== null && description !== undefined ? description : this.state.imageContent?.description
            }
        })
    }
    /* FIM - MÉTODOS PARA IMAGE CONTENT */

     /* INÍCIO - MÉTODOS PARA VÍDEO CONTENT */
     private updateVideoContent = (title?: string, video?:string, description?: string) => {        
        this.setState({
            videoContent: {
                title: title !== null && title !== undefined ? title : this.state.videoContent?.title,
                video: video !== null && video !== undefined ? video : this.state.videoContent?.video,
                description: description !== null && description !== undefined ? description : this.state.videoContent?.description
            }
        })
    }
    /* FIM - MÉTODOS PARA VÍDEO CONTENT */

    /* INÍCIO - MÉTODOS PARA DOCUMENT CONTENT */
    private updateDocumentContent = (title?: string, document?:IFile, description?: string) => {        
        this.setState({
            documentContent: {
                title: title !== null && title !== undefined ? title : this.state.documentContent?.title,
                document: document !== null && document !== undefined ? document : this.state.documentContent?.document,
                description: description !== null && description !== undefined ? description : this.state.documentContent?.description
            }
        })
    }
    /* FIM - MÉTODOS PARA VÍDEO CONTENT */

    /* INÍCIO - MÉTODOS PARA QUESTION */
    private addQuestion = () => {
        const questionsCopy = [...this.state.questionsContent];
        questionsCopy.push(
            {
                text: "",
                valueError: false,
                image: "",
                checkedCorrectAnswer: true,
                questionPlaceholder: this.localize("enterTheQuestion"),
                options: [
                        NewQuizConstants.newOption(this.localize("option"))
                    ,
                        NewQuizConstants.newOption(this.localize("option"))
                ]
            }
        )
        this.setState({
            questionsContent: questionsCopy
        })
    }

    private updateQuestion = (indexQuestion: any, value: any, imageQuestion: any) => {
        const questionsCopy = [...this.state.questionsContent];

        if(value != null && value != undefined){
            questionsCopy[indexQuestion].text = value; 
            questionsCopy[indexQuestion].valueError = false;           
        }

        if(imageQuestion != null && imageQuestion != undefined){
            questionsCopy[indexQuestion].image = imageQuestion;
            questionsCopy[indexQuestion].valueError = false;            
        }

        this.setState({
            questionsContent: questionsCopy
        });
    }

    private deleteQuestion = (indexQuestion: any) => {
        const questionsCopy = [...this.state.questionsContent];
        questionsCopy.splice(indexQuestion, 1);
        this.setState({
            questionsContent: questionsCopy
        });
    }

    private addOption = (indexQuestion: any) => {
        const questionsCopy = [...this.state.questionsContent];
        questionsCopy[indexQuestion].options.push(
            NewQuizConstants.newOption(this.localize("option"))
        );
       this.setState({
        questionsContent: questionsCopy
       });
    }

    private updateChoice = (index: any, indexQuestion: any, value?: any, correctOption?: boolean, imageOption?: any) => {
        const questionsCopy = [...this.state.questionsContent];
        
        if(value != null && value != undefined){
            questionsCopy[indexQuestion].options[index].text = value;
            questionsCopy[indexQuestion].options[index].optionError = false;
        }

        if(correctOption != null && correctOption != undefined){
            questionsCopy[indexQuestion].options[index].correctOption = correctOption;            
        }

        if(imageOption != null && imageOption != undefined){
            questionsCopy[indexQuestion].options[index].image = imageOption;
            questionsCopy[indexQuestion].options[index].optionError = false;
        }

        this.setState({
            questionsContent: questionsCopy
        });
    }

    private deleteChoice = (index: any, indexQuestion: any) => {
        const questionsCopy = [...this.state.questionsContent];
        questionsCopy[indexQuestion].options.splice(index, 1);
        this.setState({
            questionsContent: questionsCopy
        });
    }
    /* FIM - MÉTODOS PARA QUESTION */

    private createTraining = () => { 

        const selectedTeams: string[] = [];
        const selctedRosters: string[] = [];
        const selectedGroups: string[] = [];

        let training: ITraining = {
            status: "Active",
            title: this.state.title,
            description: this.state.description,
            imageTraining: this.state.fileBase64,
            contents: this.state.contents,
            teams: selectedTeams,
            rosters: selctedRosters,
            groups: selectedGroups,
            allUsers: false, // this.state.allUsersOptionSelected
            strings: this.getStringsForPreview(),
            settings: {
                dueDate: this.state.settings.dueDate,
                showCorrectAnswer: this.state.settings.showCorrectAnswer,
                allowMultipleAttempt: this.state.settings.allowMultipleAttempt,
                visibilityResult: VisibilityResultTraining.OnlyMe,
                itemsVisibility: VisibilityTraining.All,
                rowsVisibility: VisibilityTraining.All,
                itemsEditable: false,
                canUserAddMultipleItems: false,
            },
            summary: {
                rowCreatorCount: 0
            }                
        }
        
        var templateAC = new ACData.Template(this.card);
        var adaptiveCardData = templateAC.expand({
            $root: {training}
        })

        this.setState({
            card: adaptiveCardData
        });
        
        this.goToPage(PagesTraining.Preview);
    }

    private getStringsForPreview = () => {
        
        var dueDate = moment(this.state.settings.dueDate).format('llll');
        
        let previewComponentStrings: any = {
            totalContentsTraining: this.localize("total") + " " + this.state.contents.length + " " + this.localize("trainingItems"),
            be_the_first_one_to_respond: this.localize("be_the_first_one_to_respond"),
            dueByDate: Utils.getStringLocalizer(this.localize("due_by_date"), [dueDate]),
            start: this.localize("start"),
            view_result: this.localize("viewResult"),  
        };
        return previewComponentStrings;
    }

}

const newTrainingWithTranslation = withTranslation()(NewTraining);
export default newTrainingWithTranslation;