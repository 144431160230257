
import * as React from 'react';
import { withTranslation, WithTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { formatNumber } from '../../../i18n';
import { Box, Flex, FlexItem, Text } from "@fluentui/react-northstar";
import './reactionsPopUp.scss';
import {getPathEmojiCdn} from "../../../utility/Reactions";


interface ReactionsPopUpProps extends WithTranslation {
    message: any;
}

interface IReactionsPopUpState {
    message: any;
}

class ReactionsPopUp extends React.Component<ReactionsPopUpProps, IReactionsPopUpState> {

    readonly localize: TFunction;

    constructor(props: ReactionsPopUpProps) {
        super(props);

        this.localize = this.props.t;

        this.state = {
            message: this.props.message,
        }
    }

    public render(): JSX.Element {
        if (this.state.message.reactions != null && this.state.message.reactions.total > 0) {

            return (
                <Flex gap='gap.small' column>

                    {
                        Object.entries(this.state.message.reactions).map(([key, val], i) => {
                            if (key != "total") {
                                return (
                                    <Flex key={i} gap='gap.medium'>
                                        <FlexItem>
                                            <Box className={`reaction-icon`}>
                                                <img src={getPathEmojiCdn(key)} />
                                            </Box>
                                        </FlexItem>
                                        <FlexItem>
                                            <Text className={"semiBold"} content={formatNumber(val)} ></Text>
                                        </FlexItem>
                                    </Flex>
                                )
                            }                        
                        })
                    }
                </Flex>
            );
        }
        else {
            return (
                <Text content={this.localize("NoReactions")} ></Text>
            );
        }
    }
}

const reactionsPopUpWithTranslation = withTranslation()(ReactionsPopUp);
export default reactionsPopUpWithTranslation;