import { MediaType, NotificationsType } from "../utility/SharedEnum";

export function getNotificationTypeUrl(type: string, mediaType: string | null = null) {

    if (type == NotificationsType.Message) {
        if (mediaType == MediaType.Video) {
            return "/newMessageVideo/";
        }
        return "/newMessage/";
    }
    else if(type == NotificationsType.Poll){
        return "/newPoll/";
    }
    else if(type == NotificationsType.Quiz){
        return "/newQuiz/";
    }
    // else if(type == NotificationsType.Survey){
    //     return "/newSurvey/";
    // }
    else if(type == NotificationsType.Training){
        return "/newTraining/";
    }    
}

export function getNotificationTypeTaksModuleSize(type: string) { 

    if(type == NotificationsType.Message){
        return {
            height:600,
            width:1000,
        }
    }
    else if(type == NotificationsType.Poll){
        return {
            height:600,
            width:1000,
        }
    }
    else if(type == NotificationsType.Quiz){
        return {
            height:600,
            width:1000,
        }
    }
    // else if(type == NotificationsType.Survey){
    //     return {
    //         height:700,
    //         width:600,
    //     }
    // }
    if(type == NotificationsType.Training){
        return {
            height:700,
            width:600,
        }
    }
    else{
        return {
            height:600,
            width:1000,
        }
    }
}

export function getNotificationTypeIcon(notificationType: string, mediaType: string | null = null) {
    
    if (!notificationType || notificationType == NotificationsType.Message) { 
        if (mediaType == MediaType.Video) {
            return MediaType.Video.toLocaleLowerCase() + "-icon";
        }
        return NotificationsType.Message.toLowerCase() + "-icon";
    }
    else if(notificationType == NotificationsType.Poll){
        return NotificationsType.Poll.toLowerCase() + "-icon";
    }
    else if(notificationType == NotificationsType.Quiz){
        return NotificationsType.Quiz.toLowerCase() + "-icon";
    }
    // else if(notificationType == NotificationsType.Survey){
    //     return NotificationsType.Survey.toLowerCase() + "-icon";
    // }
    else if(notificationType == NotificationsType.Training){
        return NotificationsType.Training.toLowerCase() + "-icon";
    }    
}

export function getNotificationTypeImage(notificationType: string, mediaType: string | null = null) {
    
    if (!notificationType || notificationType == NotificationsType.Message) { 
        if (mediaType == MediaType.Video) {
            return MediaType.Video.toLocaleLowerCase() + "-icon";
        }
        return NotificationsType.Message.toLowerCase() + "-img";
    }
    else if(notificationType == NotificationsType.Poll){
        return NotificationsType.Poll.toLowerCase() + "-img";
    }
    else if(notificationType == NotificationsType.Quiz){
        return NotificationsType.Quiz.toLowerCase() + "-img";
    }
    // else if(notificationType == NotificationsType.Survey){
    //     return NotificationsType.Survey.toLowerCase() + "-img";
    // }
    else if(notificationType == NotificationsType.Training){
        return NotificationsType.Training.toLowerCase() + "-img";
    }    
}