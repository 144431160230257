import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { Flex, Text, ExclamationCircleIcon, FlexItem } from "@fluentui/react-northstar";
import { getBaseUrl } from '../../configVariables';


interface NoMessageReceivedProps extends WithTranslation {
}

export interface INoMessageReceivedState {
}

class NoMessageReceived extends React.Component<NoMessageReceivedProps, INoMessageReceivedState> {
    readonly localize: TFunction;

    constructor(props: NoMessageReceivedProps) {
        super(props);

        this.localize = this.props.t;
        this.state = {
        }
    }

    /**
    * Renders the component
    */
    public render(): JSX.Element {
        return (
            <div className="container-div">

                <Flex className={"bg-gallery"}>
                    <Flex className={"bg-gallery-gradient"}></Flex>
                    <img className={"bg-gallery-image"} src={getBaseUrl() + "/image/bg-gallery.png"}></img>
                </Flex>

                <div className="container-subdiv">
                    <Flex column gap="gap.medium" hAlign="center" padding="padding.medium">
                        <FlexItem>
                            <ExclamationCircleIcon className="icon-no-messages" size="largest" outline />
                        </FlexItem>
                        <FlexItem>
                            <Text size={"large"} weight={"semibold"} content={this.localize("NoReceivedMessages")} />
                        </FlexItem>
                    </Flex>
                </div>
            </div>    
        );
    }
}

const NoMessageReceivedWithTranslation = withTranslation()(NoMessageReceived);
export default NoMessageReceivedWithTranslation;