import * as React from 'react';
import { app } from "@microsoft/teams-js";
import ChartConstants from "../Constants/chart";
import Data from "../Constants/data";
import { Box, Divider, Card, Flex, Menu, Popup, Text, Dialog, tabListBehavior, ProviderConsumer as FluentUIThemeConsumer } from "@fluentui/react-northstar";
import { MoreIcon, ArrowRightIcon } from "@fluentui/react-icons-northstar";
import LineChartNoAxes from "../chart/Charts/LineNoAxes";
import '../MessagesOverview/messagesOverview.scss';
import { withTranslation, WithTranslation } from "react-i18next";
import { TFunction } from "i18next";

export interface IMessagesOverviewState {
    activeTabId: number,
    metrics: any,
    chartDataSentNotifications: any,
    chartDataSentMessages: any,
    chartDataUsersReached: any,
}


interface IMessagesOverviewProps extends WithTranslation {
    metrics: any;
    // totalMetrics: any;
    index: number;
    filter: any;
    customTitle?: string;
}

class MessagesOverview extends React.Component<IMessagesOverviewProps, IMessagesOverviewState> {
    private localize: TFunction;

    constructor(props: IMessagesOverviewProps) {
        super(props);
        this.localize = this.props.t;

        this.state = {
            activeTabId: 0,
            metrics: this.props.metrics,
            chartDataSentNotifications: {
                labels: this.parseMetrics(this.props.metrics).label,
                datasets: [
                    {
                        data: this.parseMetrics(this.props.metrics).sentNotifications,
                    },
                ],
            },
            chartDataSentMessages: {
                labels: this.parseMetrics(this.props.metrics).label,
                datasets: [
                    {
                        data: this.parseMetrics(this.props.metrics).sentMessages,
                    },
                ],
            },
            chartDataUsersReached: {
                labels: this.parseMetrics(this.props.metrics).label,
                datasets: [
                    {
                        data: this.parseMetrics(this.props.metrics).usersReached,
                    },
                ],
            }
        }
    }

    public componentDidMount() {
        app.initialize();
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if (prevProps.metrics !== this.props.metrics) {
            this.setState({
                activeTabId: this.props.index,
                chartDataSentNotifications: {
                    labels: this.parseMetrics(this.props.metrics).label,
                    datasets: [
                        {
                            data: this.parseMetrics(this.props.metrics).sentNotifications,
                        },
                    ],
                },
                chartDataSentMessages: {
                    labels: this.parseMetrics(this.props.metrics).label,
                    datasets: [
                        {
                            data: this.parseMetrics(this.props.metrics).sentMessages,
                        },
                    ],
                },
                chartDataUsersReached: {
                    labels: this.parseMetrics(this.props.metrics).label,
                    datasets: [
                        {
                            data: this.parseMetrics(this.props.metrics).usersReached,
                        },
                    ],
                }
            })
        }
    }

    public formatDataNumber(number: number) {
        if (number)
            return new Intl.NumberFormat('pt-BR').format(number)
        else
            return 0
    }

    public formatAbbreviatedNumber(number: number) {
        
        if (number) {            
            var numberFormat;
            if (number > 1000000) {
                numberFormat = `${(number / 1000000).toFixed(1)}M`;
            }
            else if (number > 1000) {
                numberFormat = `${(number / 1000).toFixed(1)}k`;
            }
            else {
                numberFormat = number;
            }
            return numberFormat;
        }
        else {
            return 0
        }

    }

    public parseMetrics = (metricsData?: any) => {
        let metricsLabel: string[] = [];
        let sentNotifications: number[] = [];
        let sentMessages: number[] = [];
        let usersReached: number[] = [];
        let totalSentNotifications: number[] = [];
        let totalSentMessages: number[] = [];
        let totalUsersReached: number[] = [];

        metricsData?.map((metric: any, index: number) => {
            let month = Data.monthNames[parseInt(metric.Day.substr(4, 2), 10) - 1];
            let day = metric.Day.substr(6, 2).replace(/^0+/, '');

            metricsLabel.push(month + " " + day);

            sentNotifications.push(metric.SentNotifications);
            sentMessages.push(metric.SentMessages);
            usersReached.push(metric.UsersReached);
            totalSentNotifications.push(metric.TotalSentNotifications);
            totalSentMessages.push(metric.TotalSentMessages);
            totalUsersReached.push(metric.TotalUsersReached);
        });

        return {
            label: metricsLabel,
            sentNotifications: sentNotifications,
            sentMessages: sentMessages,
            usersReached: usersReached,
            totalSentNotifications: totalSentNotifications,
            totalSentMessages: totalSentMessages,
            totalUsersReached: totalUsersReached,
        }
    }

    public setActiveTabDaysId(index: number, item: any) {
        
        // let selected = isNaN(item.content.split(" ")[0]) ? 0 : parseInt(item.content.split(" ")[0]);
        let selected = item.className;
        this.props.filter(selected, index)
        
        this.setState({
            activeTabId: index
        })
    }

    public render() {
        return (
            <Box
                className="messages-overview"
                styles={{
                    display: "grid",
                    gridGap: "1.2rem",
                    gridTemplate:
                        "repeat(auto-fill, 2rem) / repeat(auto-fill, minmax(28.75rem, 1fr))",
                    gridAutoFlow: "dense",
                    gridAutoRows: "17rem 2rem",
                    padding: "1rem 2rem",
                    minWidth: "20rem",
                    "@media (max-width: 986px)": {
                        gridTemplate:
                            "repeat(auto-fill, 2rem) / repeat(auto-fill, minmax(24rem, 1fr))",
                    },
                }}
            >
                <Card.Header>
                    <Flex gap="gap.small" space="between" style={{ minHeight: "2rem" }}>
                        <Flex gap="gap.small" column>
                            <Text content={this.props.customTitle ? this.props.customTitle : this.localize("MessagesOverview")} style={{ margin: 0 }} weight="bold" />
                        </Flex>
                    </Flex>
                </Card.Header>
                <Card.Body
                    style={{
                        marginBottom: "0.75rem",
                        height: "100%",
                        overflow: "hidden",
                    }}
                    fitted
                >
                    {this.props.metrics ?
                        <>
                            <Menu
                                className="tabList"
                                style={{
                                    border: "none",
                                    background: "none",
                                    marginBottom: "1.25rem", 
                                }}
                                items={[
                                    {
                                        key: "1",
                                        content: this.localize("TabHoje"),
                                        className: "0"
                                    },
                                    {
                                        key: "7",
                                        content: this.localize("7dias"),
                                        className: "7"
                                    },
                                    {
                                        key: "30",
                                        content: this.localize("30dias"),
                                        className: "30"
                                    },
                                    {
                                        key: "60",
                                        content: this.localize("AllPeriod"),
                                        className: "60",
                                    },
                                ]}
                                activeIndex={this.props.index}
                                onItemClick={(currentTarget, props) => {
                                    this.setActiveTabDaysId(props && props.index ? props.index : 0, props)
                                    }
                                }
                                accessibility={tabListBehavior}
                                underlined
                                primary
                            />

                            <Flex gap="gap.medium" column>
                                <Flex
                                    gap="gap.medium"
                                    styles={{
                                        width: "100%",
                                        height: "55px",
                                    }}
                                >
                                    <Flex className="chartFlex">
                                        <FluentUIThemeConsumer
                                            render={(globalTheme) => {
                                                return (
                                                    <LineChartNoAxes
                                                        {...{
                                                            title: this.localize("MessagesOverview"),
                                                            data: this.state.chartDataSentNotifications,
                                                            siteVariables: globalTheme.siteVariables,
                                                            customHeight: "75",
                                                        }}
                                                    />
                                                )
                                            }}
                                        />
                                    </Flex>

                                    <Flex styles={{ width: "8rem" }} column>
                                        <Text size="larger" content={this.formatAbbreviatedNumber(this.parseMetrics(this.props.metrics).totalSentNotifications[0])} />
                                        <Text size="smaller" content={this.localize("SentNotifications")} />
                                    </Flex>
                                </Flex>

                                <Flex
                                    gap="gap.medium"
                                    styles={{
                                        width: "100%",
                                        height: "55px",
                                    }}
                                >
                                    <Flex className="chartFlex">
                                        <FluentUIThemeConsumer
                                            render={(globalTheme) => {
                                                return (
                                                    <LineChartNoAxes
                                                        {...{
                                                            title: this.localize("MessagesOverview"),
                                                            data: this.state.chartDataSentMessages,
                                                            siteVariables: globalTheme.siteVariables,
                                                            customHeight: "75",
                                                        }}
                                                    />
                                                )
                                            }}
                                        />
                                    </Flex>

                                    <Flex styles={{ width: "8rem" }} column>
                                        <Text size="larger" content={this.formatAbbreviatedNumber(this.parseMetrics(this.props.metrics).totalSentMessages[0])} />
                                        <Text size="smaller" content={this.localize("MessagesSented")} />
                                    </Flex>
                                </Flex>

                                {/* <Flex
                                    gap="gap.medium"
                                    styles={{
                                        width: "100%",
                                        height: "55px",
                                    }}
                                >
                                    <Flex className="chartFlex">
                                        <FluentUIThemeConsumer
                                            render={(globalTheme) => {
                                                return (
                                                    <LineChartNoAxes
                                                        {...{
                                                            title: this.localize("MessagesOverview"),
                                                            data: this.state.chartDataUsersReached,
                                                            siteVariables: globalTheme.siteVariables,
                                                            customHeight: "75",
                                                        }}
                                                    />
                                                )
                                            }}
                                        />
                                    </Flex>

                                    <Flex styles={{ width: "8rem" }} column>
                                        <Text size="larger" content={this.formatAbbreviatedNumber(this.parseMetrics(this.props.metrics).totalUsersReached[0])} />
                                        <Text size="smaller" content={this.localize("UsersReached")} />
                                    </Flex>
                                </Flex> */}
                                
                            </Flex>
                        </>
                        :
                        <svg className="emptyChart" viewBox="0 0 550 550">
                            <rect y="37.7" width="88.7" height="414.6" />
                            <rect x="133.8" y="147.4" width="88.7" height="304.9" />
                            <rect x="267.6" y="235.7" width="88.7" height="216.6" />
                            <rect x="401.3" y="307.2" width="88.7" height="145.1" />
                        </svg>
                    }
                </Card.Body>
            </Box>
        );
    }

}

const MessagesOverviewWithTranslation = withTranslation()(MessagesOverview);
export default MessagesOverviewWithTranslation;