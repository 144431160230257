import * as React from "react";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { initializeIcons } from '@fluentui/react';
import { withTranslation, WithTranslation } from "react-i18next";
import { TFunction } from "i18next";
import moment, { locale } from "moment";
import { Utils } from "../../../utility/Utils";
import { UxUtils } from "../../../utility/UxUtils";
import { Flex, ChevronStartIcon, Text, Menu, ArrowLeftIcon } from "@fluentui/react-northstar";
import { ViewTypeSummary } from "../../../utility/SharedEnum";
import NonResponderView from "./nonResponderViewQuiz";
import ResponderViewQuiz from "./responderViewQuiz";


export interface TabViewQuizState {}

export interface TabViewQuizProps extends WithTranslation {
    notificationData: any;
    quizData: any;
    answerData: any;
    selectedAnswerUser: string;
    currentView: ViewTypeSummary;
    setSelectedUserAnswer: (userAnswer:any) => void;
    setCurrentView: (value:any) => void;
}

class TabViewQuiz extends React.Component<TabViewQuizProps, TabViewQuizState> {

    private localize: TFunction;
   
    constructor(props: TabViewQuizProps) {
        super(props);
        
        this.localize = this.props.t;
    }

    public render() {
        // Quantidade de pessoas que responderam
        let rowCount: number = this.props.answerData?.answers?.length;
        // Quantidade de pessoas que receberam o Poll
        let memberCount: number = this.props.notificationData ? this.props.notificationData?.totalMessageCount : 0;
        
        let participation: string =
            rowCount == 1
                ? Utils.getStringLocalizer(this.localize("ParticipationIndicatorSingular"),  rowCount, memberCount)
                : Utils.getStringLocalizer(this.localize("ParticipationIndicatorPlural"),  rowCount, memberCount);

        return (
            <Flex column className="tabview-container no-mobile-footer">
                <Text className="participation-title" size="small" weight="bold">
                    {participation}
                </Text>
                <Menu
                    role="tablist"
                    fluid
                    defaultActiveIndex={0}
                    items={this.getItemsMenu()}
                    underlined
                    primary
                />
                {this.props.currentView == ViewTypeSummary.ResponderView ? 
                    (<ResponderViewQuiz
                        notificationId={this.props.notificationData?.id}
                        quizData={this.props.quizData}
                        selectedAnswerUser={this.props.selectedAnswerUser}
                        currentView={this.props.currentView}
                        setSelectedUserAnswer={(userAnswer: number) => {
                            this.props.setSelectedUserAnswer(userAnswer)
                        }}
                        setCurrentView={(value: ViewTypeSummary) => {
                            this.props.setCurrentView(value)
                        }}
                    />) 
                : 
                    (<NonResponderView />)
                }
                {this.getFooterElement()}
            </Flex>
        );
    }

    private getItemsMenu() {
        return [
            {
                key: "responders",
                role: "tab",
                "aria-selected": this.props.currentView == ViewTypeSummary.ResponderView,
                "aria-label": this.localize("Responders"),
                content: this.localize("Responders"),
                onClick: () => {
                    this.props.setCurrentView(ViewTypeSummary.ResponderView);
                },
            },
            // {
            //     key: "nonResponders",
            //     role: "tab",
            //     "aria-selected":this.props.currentView == ViewTypeSummary.NonResponderView,
            //     "aria-label": this.localize("NonResponders"),
            //     content: this.localize("NonResponders"),
            //     onClick: () => {
            //         this.props.setCurrentView(ViewTypeSummary.NonResponderView);
            //     },
            // },
        ];
    }

    private getFooterElement() {
        
        return (
            <Flex className="footer-layout tab-view-footer" gap={"gap.smaller"}>
                <Flex
                    vAlign="center"
                    className="pointer-cursor"
                    {...UxUtils.getTabKeyProps()}
                    onClick={() => {
                        this.props.setCurrentView(ViewTypeSummary.Main);
                    }}
                >
                    <ChevronStartIcon xSpacing="after" size="small" />
                    <Text content={this.localize("Back")} />
                </Flex>
            </Flex>
        );
    }

}

const tabViewQuizWithTranslation = withTranslation()(TabViewQuiz);
export default tabViewQuizWithTranslation;