import * as React from "react";
import { Dropdown, Flex, FlexItem, Popup, Input, List, ListProps, FocusTrapZone, ChevronDownIcon } from "@fluentui/react-northstar";
import "./TimePickerView.scss";
import { UtilsConstants } from "../../Constants/utils";
import { UxUtils } from "./../../../utility/UxUtils";
import { isThisTypeNode } from "typescript";
import thunk from "redux-thunk";
const moment = require('moment');

export interface ITimePickerViewProps {
    placeholder?: string;
    minTimeInMinutes?: number;
    date: Date;
    defaultTimeInMinutes?: number;
    renderForMobile?: boolean;
    locale?: string;
    disabled?: boolean;
    intervalMinutes?: number;
    onTimeChange?: (dateWithTime: Date) => void;
}

export interface ITimePickerViewState {
    hour: string;
    minutes: string;
    listHours: string[];
    listMinutes: string[];
}

export interface TimePickerItem {
    hours: number;
    minutes: number;
    value: string;
    asString: any;
}

//hours to be chosen when scheduling messages
const hours = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11",
    "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23",
];

//minutes to be chosen when scheduling messages
const minutes = ["00", "05", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55",
];

/**
 * <TimePickerView> Component to provide time input
 */
export class TimePickerView extends React.Component<ITimePickerViewProps, ITimePickerViewState> {
    
    constructor(props: ITimePickerViewProps) {
        super(props);
                
        let listMinutes = this.generateListMinutes(this.props.intervalMinutes);
        
        this.state = {
            hour: this.getDateHour(this.props.date.toUTCString()),
            minutes: this.getDateMins(this.props.date.toUTCString()),
            listHours: hours,
            listMinutes: listMinutes
        };
    }

    public async componentWillReceiveProps(nextProps: any) {
        if(nextProps !== this.props) {
            this.restrictHours(nextProps.date);
            this.restrictMinutes(nextProps.date);
            
            this.setState({
                hour: this.getDateHour(nextProps.date.toUTCString()),
                minutes: this.getDateMins(nextProps.date.toUTCString())
            });
        }        
    }

    render() {
        return (
            <Flex gap="gap.small">
                <FlexItem>
                    <Dropdown
                        className="dd-time"
                        placeholder="hour"
                        disabled={this.props.disabled}
                        fluid={true}
                        items={this.state.listHours}
                        value={this.state.hour}
                        onChange={this.handleHourChange}
                    />
                </FlexItem>
                <FlexItem>
                    <Dropdown
                        className="dd-time"
                        placeholder="mins"
                        disabled={this.props.disabled}
                        fluid={true}
                        items={this.state.listMinutes}
                        value={this.state.minutes}
                        onChange={this.handleMinsChange}
                    />
                </FlexItem>
            </Flex>
        );        
    }

    //handles selection on the hour combo
    private handleHourChange = (e: any, v: any) => {        
        var TempDate = this.props.date; //get the tempdate from the state
        TempDate.setHours(parseInt(v.value)); //set hour with the value select on the hour drop down

        let isSameHour = moment(TempDate).isSame(moment(), 'hour');
        if(isSameHour) {
            TempDate.setMinutes(this.getRoundedDate(this.props.intervalMinutes).getMinutes());
        }

        this.setState({
            listMinutes: this.generateListMinutes(this.props.intervalMinutes)
        }, () => {
            if (this.props.onTimeChange){
                this.props.onTimeChange(TempDate);
            }
        });
    }

    //handles selection on the minutes combo
    private handleMinsChange = (e: any, v: any) => {
        var TempDate = this.props.date; //get the tempdate from the state
        TempDate.setMinutes(parseInt(v.value)); //set minutes with the value select on the minutes drop down
        
        if (this.props.onTimeChange){
            this.props.onTimeChange(TempDate);
        }
    }

    private generateListMinutes = (interval?: number) => {
        let intervalNum = interval ? interval : 30;
        let listMinutes = [];

        for(let i = 0; i < 60; i += intervalNum) {
            let min = i.toString();
            listMinutes.push(min.padStart(2, "0"));
        }
        return listMinutes;
    }

    private restrictHours = (date: any) => {
        let today = moment(date).isSame(moment(), 'day');
        let listHours = hours;

        if (today) {
            listHours = listHours.filter((hour: string) => {
                return !(moment(hour, "HH").isBefore(moment(), 'hour'));
            });
        }
        this.setState({
            listHours: listHours,
        });
    }

    private restrictMinutes = (date: any) => {
        let today = moment(date).isSame(moment(), 'hour');
        let listMinutes = this.state.listMinutes;

        if (today) {
            listMinutes = listMinutes.filter((minute: string) => {
                return !(moment(minute, "mm").isBefore(moment(moment().minutes(), "mm")));
            });
        }
        this.setState({
            listMinutes: listMinutes,
        });
    }

    //get the hour of the datestring
    private getDateHour = (datestring: string) => {
        if (!datestring) return "00";
        var thour = new Date(datestring).getHours().toString();
        return thour.padStart(2, "0");
    }

    //get the mins of the datestring
    private getDateMins = (datestring: string) => {
        if (!datestring) return "00";
        var tmins = new Date(datestring).getMinutes().toString();
        return tmins.padStart(2, "0");
    }

    private getRoundedDate = (minutes: number = 30, d = new Date()) => {

        let ms = 1000 * 60 * minutes; // convert minutes to ms
        let roundedDate = new Date(Math.ceil(d.getTime() / ms) * ms);

        return roundedDate;
    }
}