import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Calendar, DayOfWeek } from "office-ui-fabric-react/lib/Calendar";
import "./DatePickerView.scss";
import { registerIcons } from "@uifabric/styling";
import { Datepicker, Flex, Input, Popup, FocusTrapZone, CalendarIcon, ChevronStartIcon, ChevronEndIcon } from "@fluentui/react-northstar";
import { DateTimePickerConstants } from "../../Constants/dateTimePicker";
import { UxUtils } from "./../../../utility/UxUtils";
import { Utils } from "./../../../utility/Utils";

registerIcons({
    icons: {
        "chevronLeft": <ChevronStartIcon />,
        "chevronRight": <ChevronEndIcon />
    }
});

let dayPickerStrings = {
    months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    shortMonths: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    shortDays: ["S", "M", "T", "W", "T", "F", "S"],
    prevMonthAriaLabel: "Previous month",
    nextMonthAriaLabel: "Next month",
    goToToday: ""
};

export interface IDatePickerViewState {
    showCalendar: boolean;
    selectedDate: any;
}

export interface IDatePickerViewProps {
    date: Date;
    placeholder?: string;
    minDate?: Date;
    disabled?: boolean;
    locale?: string;
    onSelectDate?: (date: Date) => void;
    onDismiss?: () => void;
}

/**
 * <DatePickerView> Component to provide date input supported by all browsers
 */
class DatePickerView extends React.Component<IDatePickerViewProps, IDatePickerViewState> {
    private dateInputRef: HTMLElement | undefined;

    public constructor(props: IDatePickerViewProps) {
        super(props);
        
        this.state = {
            showCalendar: false,
            selectedDate: this.props.date
        };

        this.initializeDate();
    }

    public async componentWillReceiveProps(nextProps: any) {
        if(nextProps !== this.props) {
            this.setState({
                selectedDate: nextProps.date,
            });
        }        
    }

    public render(): JSX.Element {
        return this.renderDatePickerForWebOrDesktop();
    }

    renderDatePickerForWebOrDesktop() {
        return (
            <Flex>
                <Datepicker
                    disabled={this.props.disabled}
                    minDate={this.props.minDate}
                    days={dayPickerStrings.days}
                    shortDays={dayPickerStrings.shortDays}
                    months={dayPickerStrings.months}
                    shortMonths={dayPickerStrings.shortMonths}
                    firstDayOfWeek={this.getFirstDayOfWeek()}
                    selectedDate={this.state.selectedDate}
                    onDateChange={this.onDateSelected}
                />
            </Flex>            
        )
    }

    renderDatePickerPreviewView() {
        let wrapperClassName = "date-input-view date-picker-preview-container";
        
        let dateOptions: Intl.DateTimeFormatOptions = { month: "short", day: "2-digit", year: "numeric" };
        if (this.props.disabled) {
            wrapperClassName += " cursor-default";
        }

        let inputWrapperProps = {
            tabIndex: -1,
            "aria-label": (this.state.selectedDate)
                ? this.state.selectedDate.toLocaleDateString(this.props.locale, {
                    month: "short",
                    day: "numeric",
                    year: "numeric"
                }) + ". " + this.props.placeholder
                : null,
            onClick: () => {
                this.onDatePickerPreviewTap();
            },
            className: wrapperClassName,
            "aria-expanded": this.state.showCalendar,
            ...UxUtils.getTappableInputWrapperRole()
        };

        let inputProps = {
            disabled: this.props.disabled,
            type: "text",
            placeholder: this.props.placeholder,
            "aria-hidden": true,
            value: this.state.selectedDate ? UxUtils.formatDate(this.state.selectedDate, this.props.locale, dateOptions) : null,
            readOnly: true,
            "aria-readonly": false,
            className: "date-time-input"
        };

        return (
            <Input
                input={{ ...inputProps }}
                wrapper={{ ...inputWrapperProps }}
                icon={<CalendarIcon outline />}
                className={wrapperClassName}
            />
        );
    }

    public onDatePickerPreviewTap = () => {
        if (!this.props.disabled) {
            this.setState({
                showCalendar: !this.state.showCalendar
            });
        }
    }

    public onDateSelected = (event: any, date: any) => {
        // if (!this.isValidDate(date)) {
        //     return;
        // }
        if (this.props.onSelectDate) {
            this.props.onSelectDate(date.value);
        }
        this.setState({
            showCalendar: false,
            selectedDate: date.value
        });
    }

    public isValidDate = (date: Date): boolean => {
        if (this.props.minDate) {
            if (date.getFullYear() > this.props.minDate.getFullYear()) {
                return true;
            } else if (date.getFullYear() == this.props.minDate.getFullYear()) {
                if (date.getMonth() > this.props.minDate.getMonth()) {
                    return true;
                } else if (date.getMonth() == this.props.minDate.getMonth()) {
                    return (date.getDate() >= this.props.minDate.getDate());
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }
        return true;
    }

    public initializeDate = (): void => {
        // Date for Sunday in Jan month
        let date: Date = new Date("1970-01-04T00:00");
        let locale: any = this.props.locale;
        
        for (let i = 0; i < 7; i++) {
            dayPickerStrings.days[i] = Utils.capitalizeFirstLetter(date.toLocaleDateString(locale, { weekday: "long" }));
            dayPickerStrings.shortDays[i] = date.toLocaleDateString(locale, { weekday: "narrow" });
            date.setDate(date.getDate() + 1);
        }

        for (let i = 0; i < 12; i++) {
            dayPickerStrings.months[i] = Utils.capitalizeFirstLetter(date.toLocaleDateString(locale, { month: "long" }));
            dayPickerStrings.shortMonths[i] = date.toLocaleDateString(locale, { month: "short" });
            date.setMonth(date.getMonth() + 1);
        }
    }

    private getFirstDayOfWeek = (): DayOfWeek => {
        if (this.props.locale && DateTimePickerConstants.LOCALE_TO_FIRST_DAY_OF_WEEK_MAP.hasOwnProperty(this.props.locale.toLowerCase())) {
            return DateTimePickerConstants.LOCALE_TO_FIRST_DAY_OF_WEEK_MAP[this.props.locale.toLowerCase()];
        }
        return DayOfWeek.Sunday;
    }

}

const datePickerViewWithTranslation = withTranslation()(DatePickerView);
export default datePickerViewWithTranslation;