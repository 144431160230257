import * as React from "react";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { initializeIcons } from '@fluentui/react';
import { withTranslation, WithTranslation } from "react-i18next";
import { TFunction } from "i18next";
import moment, { locale } from "moment";
import { Utils } from "../../../utility/Utils";
import { UxUtils } from "../../../utility/UxUtils";
import { Flex, List, Loader, FocusZone, Text, Avatar } from "@fluentui/react-northstar";

export interface ResponderViewState {
    loader: boolean;
}

export interface ResponderViewProps extends WithTranslation {
    answerData: any;
    pollData: any;
}

class ResponderView extends React.Component<ResponderViewProps, ResponderViewState> {

    private localize: TFunction;
   
    constructor(props: ResponderViewProps) {
        super(props);
        
        this.localize = this.props.t;
        
        this.state = {
            loader: false
        }
    }

    public render() {
        
        if (this.state.loader){
            return <Loader />;
        }

        return (
            <FocusZone className="zero-padding" isCircularNavigation={true}>
                <Flex column className="list-container" gap="gap.small">
                    <List items={this.getItems()} />
                </Flex>
            </FocusZone>
        );
    }

    public getItems() {
      let items:any = [];

      this.props.answerData?.answers.forEach((answer: any, index: number) => {
        
        let answerUser = answer.answers[0];

        items.push(
          {
            key: index,
            media: (
              <Avatar
                image={`data:image/jpeg;base64,${answer.userPhoto}`}
              />
            ),
            header: (answer.userName),
            headerMedia: (moment(answer.timestamp).format('LLL')),
            content: this.props.pollData?.poll.question.options[answerUser.answer].text,
          }
        )
      });

      return items;
    }
}

const responderViewWithTranslation = withTranslation()(ResponderView);
export default responderViewWithTranslation;