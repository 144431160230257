import * as React from 'react';
import { app, dialog } from "@microsoft/teams-js";
import { RouteComponentProps } from 'react-router-dom';
import { initializeIcons, Icon } from '@fluentui/react';
import { Button, Checkbox, Input, Dropdown, Image, Text, Flex, FlexItem, List, Menu, Tooltip } from '@fluentui/react-northstar';
import { LockIcon, InfoIcon, ShieldKeyholeIcon } from '@fluentui/react-icons-northstar';
import { setAppSettings, getAppSettings, getGroupsById, getUsersById, getVersion, getTargetGroups, searchUsers, setTargetGroups } from '../../apis/settingsApi';
import { searchGroups } from '../../apis/messageListApi';
import { withTranslation, WithTranslation } from "react-i18next";
import { TFunction } from "i18next";
import LoaderSettingsApp from "../Loaders/loaderSettingsApp";
import { ImageUtil } from '../../utility/imageutility';
import ImagesBase64 from "../../components/Constants/images-base64";
import './settings.scss';

export interface SettingsState {
    loader?: boolean,
    loading: boolean,
    selectedMenu: number,
    selectedAuthorsUsers: dropdownItem[],
    selectedAuthorsGroups: dropdownItem[],
    noResultMessage: string,
    appSettings: any,
    users: any[], 
    groups: any[],
    version: string,
    enableTargetGroups: boolean;
    selectedTargetGroups: dropdownItem[],
    groupsTarget: any[],
    channelId?: string,
    channelName?: string,
    teamId?: string,
    teamName?: string,
}

export interface IGroupsTarget {
    channelId?: string,
    channelName?: string,
    teamId?: string,
    teamName?: string,
    targetGroupsId?: any[],
}

type dropdownItem = {
    key: string,
    header: string,
    content: string,
    image: string,
}

export type SettingsProps = {} & RouteComponentProps & WithTranslation;

class Settings extends React.Component<SettingsProps, SettingsState> {
    readonly localize: TFunction;

    constructor(props: SettingsProps) {
        super(props);
        this.localize = this.props.t;
        initializeIcons();

        this.state = {
            loader: true,
            loading: false,
            selectedMenu: 0,
            selectedAuthorsUsers: [],
            selectedAuthorsGroups: [],
            noResultMessage: "",
            appSettings: null,
            users: [],
            groups: [],
            version: "",
            enableTargetGroups: false,
            selectedTargetGroups: [],
            groupsTarget: [],
            channelId: "",
        }
    }

    public async componentDidMount() {
        void app.initialize().then(() => {
            void app.getContext().then((context: any) => {
                this.setState({
                    channelId: context.channel.id,
                    channelName: context.channel.displayName,
                    teamId: context.team.internalId,
                    teamName: context.team.displayName
                });

                this.init();
            });
        });

        //this.getAppSettings();
        //this.getVersion();
    }

    private init() {
        Promise.all([this.getAppSettings(), this.getTargetGroups(), this.getVersion()])
            .then(() => {
                this.setState({
                    loader: false
                });
            });
    }

    private async getTargetGroups() {
        let response = null;
        if (this.state.channelId) {
            response = await getTargetGroups(this.state.channelId);
        }

        if (response.status === 200 && response.data) {
            let targetGroups = response.data;

            var groups = await getGroupsById(targetGroups?.targetGroupsId)
            const selectedTargetGroups = this.makeDropdownItems(groups.data);

            this.setState({
                groupsTarget: groups.data,
                selectedTargetGroups: selectedTargetGroups,
                enableTargetGroups: selectedTargetGroups.length > 0 ? true : false
            })
        }

        //this.disableSaveButton();
    }

    private getTargetGroupsItems() {

        if (this.state.groupsTarget.length > 0) {
            return this.makeDropdownItems(this.state.groupsTarget);
        }
        const dropdownItems: dropdownItem[] = [];
        return dropdownItems;
    }

    /**
     * Get Version
     * */
    private async getVersion() {
        let response = await getVersion();

        if (response.status === 200 && response.data) {
            this.setState({
                version: response.data
            })
        }
    }

    /**
     * Get App Settings
     * */
    private async getAppSettings() {
        let response = await getAppSettings();

        if (response.status === 200 && response.data) {

            let appSettings = response.data;
            if (appSettings?.authorsId) {
                var users = await getUsersById(appSettings?.authorsId?.usersId);
                const selectedAuthorsUsers = this.makeDropdownItems(users.data);

                var groups = await getGroupsById(appSettings?.authorsId?.groupsId)
                const selectedAuthorsGroups = this.makeDropdownItems(groups.data);

                this.setState({
                    users: users,
                    selectedAuthorsUsers: selectedAuthorsUsers,
                    groups: groups,
                    selectedAuthorsGroups: selectedAuthorsGroups,
                    appSettings: response.data,
                    loader: false,
                })
            }
            else {
                this.setState({
                    appSettings: response.data,
                    loader: false,
                });
            }            
        }
    };

    private onAuthorsUsersSearchQueryChange = async (event: any, itemsData: any) => {
        if (!itemsData.searchQuery) {
            this.setState({
                loading: false,
                noResultMessage: "",
                users: [],
            });
        }
        else if (itemsData.searchQuery && itemsData.searchQuery.length <= 2) {
            this.setState({
                loading: false,
                noResultMessage: this.localize("NoMatchMessage"),
            });
        }
        else if (itemsData.searchQuery && itemsData.searchQuery.length > 2) {
            // handle event trigger on item select.
            const result = itemsData.items && itemsData.items.find(
                (item: { header: string; }) => item.header.toLowerCase() === itemsData.searchQuery.toLowerCase()
            )
            if (result) {
                return;
            }

            this.setState({
                loading: true,
                noResultMessage: "",
            });

            try {
                const query = encodeURIComponent(itemsData.searchQuery);
                const response = await searchUsers(query);
                this.setState({
                    users: response.data,
                    loading: false,
                    noResultMessage: this.localize("NoMatchMessage")
                });
            }
            catch (error) {
                return error;
            }
        }
    }

    private onAuthorsGroupsSearchQueryChange = async (event: any, itemsData: any) => {
        if (!itemsData.searchQuery) {
            this.setState({
                loading: false,
                noResultMessage: "",
                groups: [],
            });
        }
        else if (itemsData.searchQuery && itemsData.searchQuery.length <= 2) {
            this.setState({
                loading: false,
                noResultMessage: this.localize("NoMatchMessage"),
            });
        }
        else if (itemsData.searchQuery && itemsData.searchQuery.length > 2) {
            // handle event trigger on item select.
            const result = itemsData.items && itemsData.items.find(
                (item: { header: string; }) => item.header.toLowerCase() === itemsData.searchQuery.toLowerCase()
            )
            if (result) {
                return;
            }

            this.setState({
                loading: true,
                noResultMessage: "",
            });

            try {
                const query = encodeURIComponent(itemsData.searchQuery);
                const response = await searchGroups(query);
                this.setState({
                    groups: response.data,
                    loading: false,
                    noResultMessage: this.localize("NoMatchMessage")
                });
            }
            catch (error) {
                return error;
            }
        }
    }

    private onAuthorSearch = (itemList: any, searchQuery: string) => {
        const result = itemList.filter(
            (item: { header: string; content: string; }) => (item.header && item.header.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1) ||
                (item.content && item.content.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1),
        )
        return result;
    }

    private getAuthorUsersItems() {

        if (this.state.users.length > 0) {
            return this.makeDropdownItems(this.state.users);
        }
        const dropdownItems: dropdownItem[] = [];
        return dropdownItems;
    }

    private getAuthorGroupsItems() {

        if (this.state.groups.length > 0) {
            return this.makeDropdownItems(this.state.groups);
        }
        const dropdownItems: dropdownItem[] = [];
        return dropdownItems;
    }

    private makeDropdownItems = (items: any[] | undefined) => {
        const resultedUsers: dropdownItem[] = [];
        if (items) {
            items.forEach((element) => {
                resultedUsers.push({
                    key: element.id,
                    header: element.name,
                    content: element.mail,
                    image: ImageUtil.makeInitialImage(element.name)

                });
            });
        }
        return resultedUsers;
    }

    private onAuthorsUsersChange = (event: any, itemsData: any) => {
        this.setState({
            selectedAuthorsUsers: itemsData.value,
        })
    }

    private onAuthorsGroupsChange = (event: any, itemsData: any) => {
        this.setState({
            selectedAuthorsGroups: itemsData.value,
        })
    }

    private onTargetGroupsChange = (event: any, itemsData: any) => {
        this.setState({
            selectedTargetGroups: itemsData.value,
        }, () => {
            //this.disableSaveButton();
        });
    }

    private onGroupsTargetSearch = (itemList: any, searchQuery: string) => {
        const result = itemList.filter(
            (item: { header: string; content: string; }) => (item.header && item.header.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1) ||
                (item.content && item.content.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1),
        )
        return result;
    }

    private onTargetGroupsSearchQueryChange = async (event: any, itemsData: any) => {
        if (!itemsData.searchQuery) {
            this.setState({
                loading: false,
                noResultMessage: "",
                groupsTarget: [],
            });
        }
        else if (itemsData.searchQuery && itemsData.searchQuery.length <= 2) {
            this.setState({
                loading: false,
                noResultMessage: this.localize("NoMatchMessage"),
            });
        }
        else if (itemsData.searchQuery && itemsData.searchQuery.length > 2) {
            // handle event trigger on item select.
            const result = itemsData.items && itemsData.items.find(
                (item: { header: string; }) => item.header.toLowerCase() === itemsData.searchQuery.toLowerCase()
            )
            if (result) {
                return;
            }

            this.setState({
                loading: true,
                noResultMessage: "",
            });

            try {
                const query = encodeURIComponent(itemsData.searchQuery);
                const response = await searchGroups(query);
                this.setState({
                    groupsTarget: response.data,
                    loading: false,
                    noResultMessage: this.localize("NoMatchMessage")
                });
            }
            catch (error) {
                return error;
            }
        }
    }

    private onEnableTargetGroups = () => {

        console.log(!this.state.enableTargetGroups);
        this.setState({
            enableTargetGroups: !this.state.enableTargetGroups,
            groupsTarget: !this.state.enableTargetGroups ? this.state.groupsTarget : [],
            selectedTargetGroups: !this.state.enableTargetGroups ? this.state.selectedTargetGroups : [],
        });
    }

    private isSaveBtnDisabled = () => {
        if (this.state.enableTargetGroups && this.state.selectedTargetGroups.length <= 0) {
            return true;
        }
        else {
            return false;
        }
    }

    private async saveTargetGroups() {
        const selectedTargetGroups: string[] = [];
        this.state.selectedTargetGroups.forEach(x => selectedTargetGroups.push(x.key));

        let targetGroupsData: IGroupsTarget = {
            channelId: this.state.channelId,
            channelName: this.state.channelName,
            teamId: this.state.teamId,
            teamName: this.state.teamName,
            targetGroupsId: selectedTargetGroups
        }

        await setTargetGroups(targetGroupsData);
    }

    private async saveAppSettings() {
        const selectedAuthorsUsers: string[] = [];
        this.state.selectedAuthorsUsers.forEach(x => selectedAuthorsUsers.push(x.key));

        const selectedAuthorsGroups: string[] = [];
        this.state.selectedAuthorsGroups.forEach(x => selectedAuthorsGroups.push(x.key));
        let configData = {
            authorsId: {
                usersId: selectedAuthorsUsers,
                groupsId: selectedAuthorsGroups,
            },
        };

        await setAppSettings(configData);
    }

    private onSave = () => {
        Promise.all([this.saveAppSettings(), this.saveTargetGroups()])
            .then(() => {
                dialog.url.submit();
            });
    }

    private onCancel() {
        dialog.url.submit();
    }

    private setActiveTabId = (index: number, item: any) => {
        this.setState({
            selectedMenu: index
        })
    }

    public render(): JSX.Element {
        if (!this.state.loader) {
            return (
                <div className="config-task">
                    <div className="formContainer">
                        <Flex gap="gap.medium" padding="padding.medium">
                            <Flex.Item size="medium">
                                <Flex className="settings-menu" gap="gap.small" column>
                                    <Menu
                                        defaultActiveIndex={this.state.selectedMenu}
                                        onItemClick={(currentTarget: any, props: any) =>
                                            this.setActiveTabId(props && props.index ? props.index : 0, props)
                                        }
                                        items={[
                                            {
                                                icon: (<ShieldKeyholeIcon outline />),
                                                key: 'acesso',
                                                content: this.localize("AccessControl"),
                                            },
                                            {
                                                icon: (<LockIcon outline />),
                                                key: 'restrictedGroups',
                                                content: this.localize("RestrictedGroups"),
                                            },
                                            {
                                                icon: (<InfoIcon outline />),
                                                key: 'about',
                                                content: this.localize("About")
                                            }
                                        ]}
                                        vertical
                                    />
                                </Flex>
                            </Flex.Item>
                            <Flex.Item grow>
                                <div className="formContentContainer permissions">
                                    {this.state.selectedMenu == 0 &&
                                        <React.Fragment>
                                            <div className="inputField admin">
                                                <Flex gap="gap.medium" vAlign="center">
                                                    <Text className="label-field" content={this.localize("UsersAuthors")} />
                                                    <Tooltip
                                                        trigger={<Icon className="info-icon" iconName="Info" />}
                                                        content={
                                                            <Flex gap="gap.small" column>
                                                                <Text content={this.localize("InfoAuthors")} />
                                                            </Flex>
                                                        }
                                                        position="after"
                                                        align="center"
                                                    />
                                                </Flex>

                                                <Dropdown
                                                    multiple
                                                    fluid
                                                    className="hideToggle"
                                                    placeholder={this.localize("SelectUsers")}
                                                    search={this.onAuthorSearch}
                                                    loading={this.state.loading}
                                                    loadingMessage={this.localize("LoadingText")}
                                                    items={this.getAuthorUsersItems()}
                                                    value={this.state.selectedAuthorsUsers}
                                                    onSearchQueryChange={this.onAuthorsUsersSearchQueryChange}
                                                    onChange={this.onAuthorsUsersChange}
                                                    noResultsMessage={this.state.noResultMessage}
                                                    unstable_pinned={true}
                                                />
                                            </div>

                                            <div className="inputField admin">
                                                <Flex gap="gap.medium" vAlign="center">
                                                    <Text className="label-field" content={this.localize("GroupsAuthors")} />
                                                    <Tooltip
                                                        trigger={<Icon className="info-icon" iconName="Info" />}
                                                        content={
                                                            <Flex gap="gap.small" column>
                                                                <Text content={this.localize("InfoAuthors")} />
                                                            </Flex>
                                                        }
                                                        position="after"
                                                        align="center"
                                                    />
                                                </Flex>

                                                <Dropdown
                                                    multiple
                                                    fluid
                                                    className="hideToggle"
                                                    placeholder={this.localize("SendToGroupsPlaceHolder")}
                                                    search={this.onAuthorSearch}
                                                    loading={this.state.loading}
                                                    loadingMessage={this.localize("LoadingText")}
                                                    items={this.getAuthorGroupsItems()}
                                                    value={this.state.selectedAuthorsGroups}
                                                    onSearchQueryChange={this.onAuthorsGroupsSearchQueryChange}
                                                    onChange={this.onAuthorsGroupsChange}
                                                    noResultsMessage={this.state.noResultMessage}
                                                    unstable_pinned={true}
                                                />
                                            </div>
                                        </React.Fragment>
                                    }

                                    {this.state.selectedMenu == 1 &&
                                        <React.Fragment>
                                            <div className="inputField admin">
                                                <Flex>
                                                    <Text styles={{margin: "1rem 0rem 0.8rem 0rem" }} size="large" content={this.localize("EnableRestrictedSend")}></Text>
                                                    <Checkbox styles={{ margin: "1rem 0rem 0.8rem 0rem" }} checked={this.state.enableTargetGroups} onClick={this.onEnableTargetGroups} toggle></Checkbox>
                                                </Flex>
                                            </div>

                                            {this.state.enableTargetGroups &&
                                                <div className="inputField admin">
                                                    <Flex gap="gap.medium" vAlign="center">
                                                        <Text className="label-field" content={this.localize("SelectTargetGroups")}></Text>
                                                    </Flex>

                                                    <Dropdown
                                                        multiple
                                                        fluid
                                                        className="hideToggle"
                                                        placeholder={this.localize("SendToGroupsPlaceHolder")}
                                                        search={this.onGroupsTargetSearch}
                                                        loading={this.state.loading}
                                                        loadingMessage={this.localize("LoadingText")}
                                                        items={this.getTargetGroupsItems()}
                                                        value={this.state.selectedTargetGroups}
                                                        onSearchQueryChange={this.onTargetGroupsSearchQueryChange}
                                                        onChange={this.onTargetGroupsChange}
                                                        noResultsMessage={this.state.noResultMessage}
                                                        unstable_pinned={true}
                                                    />
                                                </div>
                                            }
                                        </React.Fragment>
                                    }

                                    {this.state.selectedMenu == 2 &&
                                        <Flex gap="gap.medium" column>
                                            <div className="inputField">
                                                <Flex gap="gap.medium" column>
                                                    <Flex gap="gap.medium" vAlign="center">
                                                        <Image className="logo-img" circular src={ImagesBase64.logo} />

                                                        <Flex gap="gap.small" column>
                                                            <Text size="large" weight="bold" content={"Company Hello!"} />

                                                            <Flex gap="gap.smaller" column>
                                                                <Text content={this.localize("DevelopedBy")} />
                                                                <Text temporary content={`Build: ${this.state.version}`} />
                                                            </Flex>
                                                        </Flex>
                                                    </Flex>
                                                </Flex>
                                            </div>
                                        </Flex>
                                    }
                                    
                                </div>
                            </Flex.Item>
                        </Flex>
                    </div>

                    <div className="footerContainer">
                        <div className="buttonContainer">
                            <Button content={this.localize("Cancel")} id="cancelBtn" onClick={this.onCancel} />
                            <Button content={this.localize("Save")} id="saveBtn" onClick={this.onSave.bind(this)} primary disabled={this.isSaveBtnDisabled()} />
                        </div>
                    </div>
                </div>
            );
        }
        else {
            return (<LoaderSettingsApp />)
        }
    }
}

const configAppWithTranslation = withTranslation()(Settings);
export default configAppWithTranslation;
