import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { withTranslation, WithTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { initializeIcons } from '@fluentui/react';
import { Button, Checkbox, Datepicker, Loader, Dropdown, Text, Flex, Input, TextArea, RadioGroup } from '@fluentui/react-northstar'
import { ImageUtil } from '../../../utility/imageutility';
import './AudienceNotifications.scss';
import { DateTimePickerView } from "../DateTime/DateTimePickerView";
import { locale } from 'moment';

const moment = require('moment');

type dropdownItem = {
    key: string,
    header: string,
    content: string,
    image: string,
    team: {
        id: string,
    },
}

const intervalMinutes: number = 5;

export interface AudienceNotificationsProps extends WithTranslation {
    loading: boolean;
    teams?: any[];
    groups?: any[];
    targetGroups?: any[];
    selectedRadioBtn: string;
    groupAccess: boolean;
    teamsOptionSelected: boolean;
    rostersOptionSelected: boolean;
    allUsersOptionSelected: boolean;
    groupsOptionSelected: boolean;
    selectedTeams: dropdownItem[];
    selectedRosters: dropdownItem[];
    selectedGroups: dropdownItem[];
    unstablePinned?: boolean;
    noResultMessage: string;
    selectedImportant: boolean;
    selectedSchedule: boolean;
    scheduledDate: string;
    futuredate: boolean;
    isAdmin: boolean,
    enableTargetGroups: boolean;
    onGroupSelected?: (event: any, data: any) => void;
    onTeamsChange?: (event: any, itemsData: any) => void;
    onRostersChange?: (event: any, itemsData: any) => void;
    onGroupSearchQueryChange?: (event: any, itemsData: any) => void;
    onGroupsChange?: (event: any, itemsData: any) => void;
    onImportantSelected?: (event: any, itemsData: any) => void;
    onScheduleSelected?: (event: any, itemsData: any) => void;
    getDateObject?: (itemsData: any) => void;
    getDateHour?: (itemsData: any) => void;
    getDateMins?: (itemsData: any) => void;
    handleDateChange?: (event: any, itemsData: any) => void;
    handleHourChange?: (event: any, itemsData: any) => void;
    handleMinsChange?: (event: any, itemsData: any) => void;
    onChangeScheduleDate?: (itemsData: any) => void;
}

class AudienceNotifications extends React.Component<AudienceNotificationsProps, any> {
    readonly localize: TFunction;

    constructor(props: AudienceNotificationsProps) {
        super(props);
        
        this.localize = this.props.t;
    }

    public render() {
        return(
            <Flex column>
                <h3>{this.localize("SendHeadingText")}</h3>
                <RadioGroup
                    className="radioBtns"
                    checkedValue={this.props.selectedRadioBtn}
                    onCheckedValueChange={this.props.onGroupSelected}
                    vertical={true}
                    items={[
                        {
                            name: "teams",
                            key: "teams",
                            disabled: (this.props.enableTargetGroups && !this.props.isAdmin),
                            value: "teams",
                            label: this.localize("SendToGeneralChannel"),
                            children: (Component, { name, ...props }) => {
                                return (
                                    <Flex key={name} column gap="gap.smaller">
                                        <Component {...props} />
                                        <Dropdown
                                            className={(this.props.enableTargetGroups && !this.props.isAdmin) ? "disableDropDownItem" : ""}
                                            hidden={!this.props.teamsOptionSelected}
                                            placeholder={this.localize("SendToGeneralChannelPlaceHolder")}
                                            search
                                            multiple
                                            items={this.getItems()}
                                            value={this.props.selectedTeams}
                                            onChange={this.props.onTeamsChange}
                                            noResultsMessage={this.localize("NoMatchMessage")}
                                            disabled={(this.props.enableTargetGroups && !this.props.isAdmin)}
                                        />
                                    </Flex>
                                )
                            },
                        },
                        {
                            name: "rosters",
                            key: "rosters",
                            disabled: (this.props.enableTargetGroups && !this.props.isAdmin),
                            value: "rosters",
                            label: this.localize("SendToRosters"),
                            children: (Component, { name, ...props }) => {
                                return (
                                    <Flex key={name} column gap="gap.smaller">
                                        <Component {...props} />
                                        <Dropdown
                                            className={(this.props.enableTargetGroups && !this.props.isAdmin) ? "disableDropDownItem" : "" }
                                            hidden={!this.props.rostersOptionSelected}
                                            placeholder={this.localize("SendToRostersPlaceHolder")}
                                            search
                                            multiple
                                            items={this.getItems()}
                                            value={this.props.selectedRosters}
                                            onChange={this.props.onRostersChange}
                                            unstable_pinned={this.props.unstablePinned}
                                            noResultsMessage={this.localize("NoMatchMessage")}
                                            disabled={(this.props.enableTargetGroups && !this.props.isAdmin)}
                                        />
                                    </Flex>
                                )
                            },
                        },
                        {
                            name: "allUsers",
                            key: "allUsers",
                            disabled: (this.props.enableTargetGroups && !this.props.isAdmin),
                            value: "allUsers",
                            label: this.localize("SendToAllUsers"),
                            children: (Component, { name, ...props }) => {
                                let qs = new URLSearchParams(window.location.search);
                                
                                if (qs.get("noSendAll") == 'false') {
                                    return (
                                        <Flex key={name} column gap="gap.smaller">
                                            <Component {...props} />
                                            <div className={this.props.selectedRadioBtn === "allUsers" ? "" : "hide"}>
                                                <div className="noteText">
                                                    <Text error content={this.localize("SendToAllUsersNote")} />
                                                </div>
                                            </div>
                                        </Flex>
                                    )
                                }

                                return (
                                    <Flex key={name}></Flex>
                                )
                            },
                        },
                        {
                            name: "groups",
                            key: "groups",
                            value: "groups",
                            label: this.localize("SendToGroups"),
                            children: (Component, { name, ...props }) => {
                                if (this.props.enableTargetGroups && !this.props.isAdmin) {
                                    return (
                                        <Flex key={name} column>
                                            <Component {...props} />
                                            <div className={this.props.groupsOptionSelected && !this.props.groupAccess ? "" : "hide"}>
                                                <div className="noteText">
                                                    <Text error content={this.localize("SendToGroupsPermissionNote")} />
                                                </div>
                                            </div>
                                            <Dropdown
                                                placeholder={this.localize("SelectTargetGroups")}
                                                hidden={!this.props.groupsOptionSelected || !this.props.groupAccess}
                                                multiple
                                                items={this.getGroupItems()}
                                                value={this.props.selectedGroups}
                                                onChange={this.props.onGroupsChange}
                                                noResultsMessage={this.localize("NoMatchMessage")}
                                                unstable_pinned={this.props.unstablePinned}
                                            />
                                            <div className={this.props.groupsOptionSelected && this.props.groupAccess ? "" : "hide"}>
                                                <div className="noteText">
                                                    <Text error content={this.localize("SendToGroupsNote")} />
                                                </div>
                                            </div>
                                        </Flex>
                                    )
                                }
                                else {
                                    return (
                                        <Flex key={name} column gap="gap.smaller">
                                            <Component {...props} />
                                            <div className={this.props.groupsOptionSelected && !this.props.groupAccess ? "" : "hide"}>
                                                <div className="noteText">
                                                    <Text error content={this.localize("SendToGroupsPermissionNote")} />
                                                </div>
                                            </div>
                                            <Dropdown
                                                //className="hideToggle"
                                                toggleIndicator={false}
                                                hidden={!this.props.groupsOptionSelected || !this.props.groupAccess}
                                                placeholder={this.localize("SendToGroupsPlaceHolder")}
                                                search={this.onGroupSearch}
                                                multiple
                                                loading={this.props.loading}
                                                loadingMessage={this.localize("LoadingText")}
                                                items={this.getGroupItems()}
                                                value={this.props.selectedGroups}
                                                onSearchQueryChange={this.props.onGroupSearchQueryChange}
                                                onChange={this.props.onGroupsChange}
                                                noResultsMessage={this.props.noResultMessage}
                                                unstable_pinned={this.props.unstablePinned}
                                            />
                                            <div className={this.props.groupsOptionSelected && this.props.groupAccess ? "" : "hide"}>
                                                <div className="noteText">
                                                    <Text error content={this.localize("SendToGroupsNote")} />
                                                </div>
                                            </div>
                                        </Flex>
                                    )
                                }
                            },
                        }
                    ]}
                >

                </RadioGroup>

                <Flex hAlign="start">
                    <h3><Checkbox
                        className="ScheduleCheckbox"
                        labelPosition="start"
                        onClick={this.props.onScheduleSelected}
                        label={this.localize("ScheduledSend")}
                        checked={this.props.selectedSchedule}
                        toggle
                    /></h3>
                </Flex>                
                <Flex className="settings-item-margin" role="group" aria-label={this.localize("dueBy")} column gap="gap.smaller">
                    <Text size="small" align="start" content={this.localize('ScheduledSendDescription')} />
                    <div className={"settings-indentation"}>
                        <DateTimePickerView
                            disabled={!this.props.selectedSchedule}
                            minDate={new Date()}
                            intervalMinutes={intervalMinutes}
                            locale={locale()}
                            value={new Date(this.props.scheduledDate)}
                            placeholderDate={this.localize("datePickerPlaceholder")}
                            placeholderTime={this.localize("timePickerPlaceholder")}
                            onSelect={(date: Date) => {
                                if(this.props.scheduledDate){
                                    if(this.props.onChangeScheduleDate){
                                            this.props.onChangeScheduleDate(moment(date).format());
                                    }                                
                                }                            
                            }} />
                    </div>
                </Flex>
                <div className={this.props.futuredate && this.props.selectedSchedule ? "ErrorMessage" : "hide"}>
                    <div className="noteText">
                        <Text error content={this.localize('FutureDateError')} />
                    </div>
                </div>

                <Flex hAlign="start">
                    <h3>
                        <Checkbox
                            className="Important"
                            labelPosition="start"
                            onClick={this.props.onImportantSelected}
                            label={this.localize("Important")}
                            checked={this.props.selectedImportant}
                            toggle
                        />
                    </h3>
                </Flex>
                <Text size="small" align="start" content={this.localize('ImportantDescription')} />

            </Flex>
        )
    }

    private getItems = () => {
        const resultedTeams: dropdownItem[] = [];
        if (this.props.teams) {
            let remainingUserTeams = this.props.teams;
            if (this.props.selectedRadioBtn !== "allUsers") {
                if (this.props.selectedRadioBtn === "teams") {
                    this.props.teams.filter(x => this.props.selectedTeams.findIndex(y => y.team.id === x.id) < 0);
                }
                else if (this.props.selectedRadioBtn === "rosters") {
                    this.props.teams.filter(x => this.props.selectedRosters.findIndex(y => y.team.id === x.id) < 0);
                }
            }
            remainingUserTeams.forEach((element) => {
                resultedTeams.push({
                    key: element.id,
                    header: element.name,
                    content: element.mail,
                    image: ImageUtil.makeInitialImage(element.name),
                    team: {
                        id: element.id
                    }
                });
            });
        }
        return resultedTeams;
    }

    private onGroupSearch = (itemList: any, searchQuery: string) => {
        const result = itemList.filter(
            (item: { header: string; content: string; }) => (item.header && item.header.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1) ||
                (item.content && item.content.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1),
        )
        return result;
    }
    
    private getGroupItems() {
        if (this.props.enableTargetGroups && !this.props.isAdmin) {
            if (this.props.targetGroups) {
                return this.makeDropdownItems(this.props.targetGroups);
            }
        }

        if (this.props.groups) {
            return this.makeDropdownItems(this.props.groups);
        }
        const dropdownItems: dropdownItem[] = [];
        return dropdownItems;
    }

    private makeDropdownItems = (items: any[] | undefined) => {
        const resultedTeams: dropdownItem[] = [];
        if (items) {
            items.forEach((element) => {
                resultedTeams.push({
                    key: element.id,
                    header: element.name,
                    content: element.mail,
                    image: ImageUtil.makeInitialImage(element.name),
                    team: {
                        id: element.id
                    },

                });
            });
        }
        return resultedTeams;
    }
}

const audienceNotificationsWithTranslation = withTranslation()(AudienceNotifications);
export default audienceNotificationsWithTranslation;