import * as React from "react";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { initializeIcons, Icon } from '@fluentui/react';
import { withTranslation, WithTranslation } from "react-i18next";
import { TFunction } from "i18next";
import moment, { locale } from "moment";
import { Flex, FlexItem, Image, Input, Text, TextArea, Video } from "@fluentui/react-northstar";
import {
    CallVideoIcon
} from '@fluentui/react-icons-northstar';

export interface IDocumentContent {
    title?: string;
    titleError?: boolean;
    description?: string;
    document?: IFile;
    documentError?: boolean;
    isVideoAdded?: boolean;
}

export interface IFile {
    fileName?: string;
    filePath?: string;
    fileBase64?: string;
}

export interface DocumentContentTrainingProps extends WithTranslation{
    content?: IDocumentContent;
    onUpdateDocumentContent?: (title?:string, document?:IFile, description?: string) => void;
}

class DocumentContentTraining extends React.Component<DocumentContentTrainingProps, any> {

    private localize: TFunction;
    constructor(props: DocumentContentTrainingProps) {
        super(props);
        
        initializeIcons();
        this.localize = this.props.t;
    }

    render(){
        
        return(
            <Flex column gap="gap.medium">
                <FlexItem>
                    <div className="inputField">
                        <Flex space="between">
                            <FlexItem>
                                <Text className="label-field" content={this.localize("titleContent")}></Text>
                            </FlexItem>
                            <FlexItem push>
                                <Text className={this.props.content?.titleError ? "text-alert label-field" : "text-alert label-field display-none"} content={this.localize("required")}></Text>
                            </FlexItem>
                        </Flex>

                        <Input
                            fluid
                            className={this.props.content?.titleError ? "inputField input-alert" : "inputField"}
                            placeholder={this.localize("addTitlePlaceholder")}
                            value={this.props.content?.title}
                            onChange={(e: any, data: any) => {
                                if(this.props.onUpdateDocumentContent){
                                    this.props.onUpdateDocumentContent(data.value);
                                }
                            }}
                        />
                    </div>
                </FlexItem>
                <FlexItem>
                    <div className="inputField" style={{marginBottom: "0px"}}>

                        <Flex space="between">
                            <FlexItem>
                                <Text className="label-field" content={this.localize("document")}></Text>
                            </FlexItem>
                            <FlexItem push>
                                <Text className={this.props.content?.documentError ? "text-alert label-field" : "text-alert label-field display-none"} content={this.localize("required")}></Text>
                            </FlexItem>
                        </Flex>

                        {this.props.content?.document ?                                  
                            <Flex column>
                                <Flex>
                                    <FlexItem push>
                                        <Text className="btn-text" content={this.localize("clear")} onClick={() => { this.clearDocumentPreview() }}></Text>
                                    </FlexItem>                                                                                                
                                </Flex> 
                                <Flex gap="gap.small">
                                    <FlexItem>
                                        <Icon iconName="TextDocument" color="black" />
                                    </FlexItem>
                                    <FlexItem>
                                        <Text 
                                            className="btn-text" 
                                            content={this.props.content.document.fileName} 
                                            // onClick={() => {
                                            //     window.location.href = this.props.content?.document?.fileBase64 ? this.props.content?.document?.fileBase64 : "";
                                            // }}
                                        />
                                    </FlexItem>
                                </Flex>  
                            </Flex>                                                                                                     
                        :
                            <FlexItem>
                                <div className="inputField" onClick={this.triggerHandleUpload.bind(this)}>
                                    <input type="file" name="input-file" id="input-file" className="inputFile" accept=".xlsx,.xls,.doc,.docx,.ppt,.pptx,.txt,.pdf" onChange={(event) => { this.handleUpload(event); }} />
                                    <Flex column hAlign="center" vAlign="center" className="photo-box">
                                        <Icon iconName="TextDocument" />
                                        
                                        <Text content={this.localize("tapUploadFile")}></Text>
                                    </Flex>
                                </div>                                
                            </FlexItem>
                        }                                    
                    </div>                                
                </FlexItem>
                <FlexItem>
                    <div className="inputField">
                        <Text className="label-field" content={this.localize("descriptionContent")}></Text>
                        <TextArea
                            fluid
                            styles={{
                                height: "200px"
                            }}
                            placeholder={this.localize("addDescriptionPlaceholder")}
                            value={this.props.content?.description}
                            onChange={(e: any, data: any) => {
                                if(this.props.onUpdateDocumentContent){
                                    this.props.onUpdateDocumentContent(null as any, null as any, data.value);
                                }
                            }}
                        >
                        </TextArea>
                    </div>
                </FlexItem>
            </Flex>            
        );
    }

    private handleUpload(event: any) {
        
        let file = event.target.files[0];

        if (file) {
            
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                
                // var document = reader.result as string;
                var document = {
                    fileName: file.name,
                    fileBase64: reader.result as string
                }

                if(this.props.onUpdateDocumentContent){
                    this.props.onUpdateDocumentContent(null as any, document);
                }
                
                // this.setState({
                //     document: reader.result as string,
                //     isImageAdded: true
                // })

                // if ((this.state.fileBase64 === "") || !(this.state.fileBase64.startsWith("data:video/png;base64,") || this.state.fileBase64.startsWith("data:video/jpeg;base64,") || this.state.fileBase64.startsWith("data:video/jpg;base64,") || this.state.fileBase64.startsWith("data:video/gif;base64,"))) {
                //     this.setState({
                //         errorImageUrlMessage: this.localize("ErrorUpload"),
                //         fileBase64: "",
                //         file: null,
                //         fileName: "",
                //         isImageAdded: false
                //     });
                //     (document.getElementById("input-file") as HTMLInputElement).value = "";
                //     return;

                // } else {
                //     this.setState({
                //         errorImageUrlMessage: "",
                //     });
                // }
            };
        }
    }

    private triggerHandleUpload(event: any) {

        document.getElementById("input-file")!.click();
    }

    private clearDocumentPreview(){
        if(this.props.onUpdateDocumentContent){
            var documentEmpty = "";
            this.props.onUpdateDocumentContent(null as any, documentEmpty as any);
        }
        
    }
}

const documentContentTrainingWithTranslation = withTranslation()(DocumentContentTraining);
export default documentContentTrainingWithTranslation;