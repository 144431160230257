import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { TFunction } from "i18next";
import moment, { locale } from "moment";
import "./summaryQuiz.scss";
import {
    AcceptIcon, Checkbox, Flex, FlexItem, Image, Menu, Dialog, Text, BanIcon, MoreIcon, SplitButton, Divider, RadioGroup
} from "@fluentui/react-northstar";
import { Utils } from "../../../utility/Utils";
import { UxUtils } from "../../../utility/UxUtils";
import { ActionStatus, ActionDataColumnValueType, ViewTypeSummary } from "../../../utility/SharedEnum";
import { IBarChartItem, BarChartComponent } from "../../Shared/BarChartComponent/barChartComponent";
import { ShimmerContainer } from "../../Shared/ShimmerLoader/shimmerContainer";
import { DateTimePickerView } from "../../Shared/DateTime/DateTimePickerView";
import { IQuiz } from "../../../interfaces/IQuiz";

export interface SummaryViewQuizState {    
    menuOpen: boolean;
    newDueDate: any;     
}

export interface SummaryViewQuizProps extends WithTranslation {
    notificationData: any;
    quizData: IQuiz;
    answerData: any;
    showShimmer: boolean;
    openDialogChangeDueDate: boolean;       
    openDialogCloseQuiz: boolean;
    openDialogDeleteQuiz: boolean;
    setCurrentView: (value:ViewTypeSummary) => void;
    updateDueDate: (value:any) => void;
    updateCloseQuiz: (value:any) => void;
    changeDialogUpdateDueDate: (value:boolean) => void;
    changeDialogUpdateCloseQuiz: (value:boolean) => void;
    changeDialogDeleteQuiz: (value:boolean) => void;
}

class SummaryViewQuiz extends React.Component<SummaryViewQuizProps, SummaryViewQuizState> {

    private localize: TFunction;    

    constructor(props: SummaryViewQuizProps) {
        super(props);
        
        this.localize = this.props.t;

        this.state = {
            menuOpen: false,
            newDueDate: "",
        }
    }    

    public render() {
        
        return (
            <>
                <Flex 
                    gap="gap.medium" 
                    column 
                    className="body-container"
                    id="bodyContainer"
                >
                    {this.renderHeaderContainer()}
                    {this.renderTopContainer()}
                    {this.getAggregrateResult()}
                    {this.getShortSummaryContainer()}
                </Flex>
                {this.renderFooterView()}
            </>
        );
    }

    public renderHeaderContainer() {
        let dueDate = this.getTextHeaderByStatus();
    
        return (
            <Flex>  
                {this.props.showShimmer && 
                    <FlexItem styles={{width: "100%"}}>
                        <ShimmerContainer lines={1} width={["50%"]} showShimmer={this.props.showShimmer}>
                            <Text weight="bold" className="primary-text">Due Date</Text>                        
                        </ShimmerContainer>
                    </FlexItem>
                }              

                {!this.props.showShimmer &&
                    <Flex column gap="gap.medium" styles={{width: "100%"}}>
                        {this.props.quizData?.quiz?.imageQuiz &&
                            <Flex hAlign="start">
                                <FlexItem>
                                    <Image className="quiz-img-preview" src={this.props.quizData?.quiz?.imageQuiz} />
                                </FlexItem>
                            </Flex>
                        }                        
                        <Flex space="between">
                            <Flex column gap="gap.small">
                                <FlexItem>
                                    <Text weight="bold" className="primary-text">{this.props.quizData?.quiz?.title}</Text>
                                </FlexItem>
                                <FlexItem>
                                    <Text size="small" content={this.props.quizData?.quiz?.description} />
                                </FlexItem>
                                <FlexItem>
                                    <Text size="small">{dueDate}</Text>
                                </FlexItem>
                            </Flex>                    
                            
                            <Flex vAlign="start" hAlign="end">
                                {this.isQuizActive() &&
                                    <FlexItem>                                
                                        <Menu 
                                            className="menuContainer" 
                                            iconOnly 
                                            items={
                                                [
                                                    {
                                                        key: 'more',
                                                        icon: <MoreIcon outline={true} />,
                                                        menuOpen: this.state.menuOpen,
                                                        active: this.state.menuOpen,
                                                        indicator: false,
                                                        menu: {
                                                            items: this.getHeaderMenuItems(),
                                                        },
                                                        onMenuOpenChange: (e: any, { menuOpen }: any) => {
                                                            this.setState({
                                                                menuOpen: menuOpen
                                                            });
                                                        }
                                                    }
                                                ]                            
                                            } 
                                        />                                            
                                    </FlexItem>
                                }
                            </Flex>
                        </Flex>
                    </Flex>
                    
                                        
                }                   
                
                <FlexItem>
                    <>
                        {this.setupDuedateDialog()}
                        {this.setupCloseDialog()}
                        {this.setupDeleteDialog()}
                    </>                    
                </FlexItem>          
            </Flex>
        )
    }

    public getHeaderMenuItems() {
        let menuItemList:any[] = [];
        
        // FUNCIONALIDADES OCULTAS PARA A PRIMEIRA ENTREGA POR CONTA DO UPDATECARD
        if (this.isQuizActive()){
            // let changeExpiry = {
            //     key: "changeDueDate",
            //     content: this.localize("ChangeDueBy"),
            //     icon: <CalendarIcon outline={true} />,
            //     onClick: () => {
            //         this.props.changeDialogUpdateDueDate(true);
            //     }
            // }

            // menuItemList.push(changeExpiry);

            let closeQuiz = {
                key: "close",
                content: this.localize("closeQuiz"),
                icon: <BanIcon outline={true} />,
                onClick: () => {
                    // ALTERAR DATA DE FECHAR VOTAÇÃO                
                    this.props.changeDialogUpdateCloseQuiz(true);
                }
            }

            menuItemList.push(closeQuiz);
        }

        // let deleteQuiz = {
        //     key: "delete",
        //     content: this.localize("DeleteQuiz"),
        //     icon: <TrashCanIcon outline={true} />,
        //     onClick: () => {
        //         // ALTERAR DATA DE FECHAR VOTAÇÃO            
        //         this.props.changeDialogDeleteQuiz(true);
        //     }
        // }

        // menuItemList.push(deleteQuiz);

        return menuItemList;
    }

    public getTextHeaderByStatus() {
        
        if(this.props.quizData){
            if(this.props.quizData.quiz.status == ActionStatus.Active) {
                var dueDate = moment(this.props.quizData.quiz.settings.dueDate).format('llll');
                return Utils.getStringLocalizer(this.localize("dueByDate"), [dueDate]);
            }
            else if(this.props.quizData.quiz.status == ActionStatus.Closed){
                // Validar com a data de FECHAMENTO MANUAL da Votação
                var dueDate = moment(this.props.quizData.quiz.settings.dueDate).format('llll');
                return Utils.getStringLocalizer(this.localize("ClosedOn"), [dueDate]);
            }
            else if(this.props.quizData.quiz.status == ActionStatus.Expired){
                var dueDate = moment(this.props.quizData.quiz.settings.dueDate).format('llll');
                return Utils.getStringLocalizer(this.localize("ExpiredOn"), [dueDate]);
            }
        }      
    }

    public renderTopContainer() {
        
        // Quantidade de pessoas que responderam
        let rowCount: number = this.props.answerData?.answers?.length;
        // Quantidade de pessoas que receberam o Quiz
        let memberCount: number = this.props.notificationData ? this.props.notificationData?.totalMessageCount : 0;
        let participationInfoItems: IBarChartItem[] = [];
        let participationPercentage = memberCount ? Math.round((rowCount / memberCount) * 100) : 0;

        participationInfoItems.push({
            id: "participation",
            title: Utils.getStringLocalizer(this.localize("Participation"), participationPercentage),
            titleClassName: "participation-title",
            quantity: rowCount,
            hideStatistics: true,
        });

        let participation: string = (rowCount == 1)
            ? Utils.getStringLocalizer(this.localize("ParticipationIndicatorSingular"), rowCount, memberCount)
            : Utils.getStringLocalizer(this.localize("ParticipationIndicatorPlural"), rowCount, memberCount);

        return (
            <>
                <div
                    role="group"
                    aria-label={Utils.getStringLocalizer(this.localize("Participation"), participationPercentage)}
                >
                    <BarChartComponent
                        items={participationInfoItems}
                        getBarPercentageString={(percentage: number) => {
                            return Utils.getStringLocalizer(this.localize("BarPercentage"), percentage);
                        }}
                        totalQuantity={memberCount}
                        showShimmer={this.props.showShimmer}
                    />

                    <Flex space="between" className="participation-container">
                        <Flex.Item aria-hidden="false">
                            <ShimmerContainer lines={1} showShimmer={this.props.showShimmer}>
                                <div>
                                    <Text
                                        {...UxUtils.getTabKeyProps()}
                                        tabIndex={0}
                                        role="button"
                                        className="underline"
                                        color="brand"
                                        size="small"
                                        content={participation}
                                        onClick={() => {
                                            if(this.props.setCurrentView){
                                                this.props.setCurrentView(ViewTypeSummary.ResponderView);
                                            }                                                   
                                        }}
                                    />
                                </div>
                            </ShimmerContainer>
                        </Flex.Item>
                    </Flex>
                </div>
                <Divider className="divider" />
            </>
        );
    }

    public renderFooterView(){
        let menuItems = [];
        menuItems.push(
            {
            key: 'download_image',
            content: this.localize("DownloadImage"),
            },
            {
            key: 'download_responses',
            content: this.localize("DownloadResponses"),
            }
        );

        return (
            <Flex className="footer-layout" gap={"gap.smaller"} hAlign="end">
                {/* <SplitButton
                    key="download_button"
                    id="download"
                    menu={menuItems}
                    button={{
                        content: this.localize("Download"),
                        className: "download-button",
                    }}
                    primary
                    toggleButton={{ "aria-label": "more-options" }}
                    // onMainButtonClick={() => this.downloadImage()}
                /> */}
            </Flex>
        )
    }

    private isQuizActive(): boolean {
        return (
            this.props.quizData?.quiz.status == ActionStatus.Active
        );
    }

    private getAggregrateResult = (): JSX.Element => {
        return ( 
            <Flex column gap="gap.small">
                <FlexItem>
                    <ShimmerContainer lines={1} showShimmer={this.props.showShimmer}>
                        <Text weight="bold" size="medium" content={this.localize("aggregrateResult")}></Text>
                    </ShimmerContainer>
                </FlexItem>

                <Divider className="divider" /> 

                <FlexItem>
                    {this.renderQuestions()}
                </FlexItem>               
            </Flex>
            
        );
    }

    private renderQuestions = (): JSX.Element => {

        let questions: any[] = [];

        this.props.quizData?.quiz?.questions.forEach((question: any, index: any) => { 

            questions.push(
                <Flex column gap="gap.medium">
                     <ShimmerContainer lines={5} showShimmer={this.props.showShimmer}>
                        <Flex space="between">
                            <FlexItem>
                                <Text weight="bold" size="medium" content={ `${this.localize("question")} # ${(index + 1)}` } ></Text>
                            </FlexItem>
                            <FlexItem>
                                <Text weight="semibold" size="small" content={`${Math.round(this.props.answerData?.aggregrateResults[index].score)}% ${this.localize("correct")}`} ></Text>
                            </FlexItem>                            
                        </Flex>
                        
                        <FlexItem>
                            <Text size="large" content={question.text} ></Text>
                        </FlexItem>
                        <FlexItem>
                            {this.renderOptions(index)}
                        </FlexItem>
                     </ShimmerContainer>                    
                </Flex>
            );
        });


        return (
            <>
                {questions}
            </>
        );
    }

    public getItemsRadioGroup = (indexQuestion: number) => {
        let items: any[] = [];

        let correctOption = this.props.quizData?.quiz?.questions[indexQuestion].options.find((opt: any) => opt.correctOption == true);
        
        this.props.quizData?.quiz?.questions[indexQuestion].options.forEach((opt: any, index: number) => {
            
            items.push(
                {
                    className: "item-option",
                    name: `${indexQuestion}`,
                    key: `${indexQuestion}-${opt.name}` ,
                    value: opt.name,
                    checked: opt.checked,
                    disabled: true,
                    label: (
                        <Flex column>
                            {opt.image &&
                                <Flex className="questions-img-box">
                                    <FlexItem>
                                        <Image className="questions-img-preview" src={opt.image}></Image>
                                    </FlexItem>  
                                </Flex>
                            }
                            <Flex>
                                <FlexItem>
                                    <Text content={opt.text} />
                                </FlexItem>
                                <FlexItem push styles={{ display: opt.correctOption ? "block" : "none" }}>
                                    <AcceptIcon className="correctAnswer"></AcceptIcon>
                                </FlexItem>
                            </Flex>                            
                        </Flex>     
                    ),
                    variables: {
                        indicatorBackgroundColorChecked: opt.correctOption ? "#6264a7" : "",
                    }
                }
            )
        })

        return (
            <RadioGroup
                checkedValue={correctOption ? correctOption.name : ""}
                className="quiz-options"
                vertical
                items={items}
            />
        );
    }

    public getItemsCheckBox = (indexQuestion: number) => {

        let items: any[] = [];
        this.props.quizData?.quiz?.questions[indexQuestion].options.forEach((opt: any, index: number) => {
            
            items.push(
                <Checkbox
                    id={opt.name}
                    checked={opt.correctOption}
                    disabled={true}
                    label={
                        <Flex column>
                            {opt.image &&
                                <Flex className="questions-img-box">
                                    <FlexItem>
                                        <Image className="questions-img-preview" src={opt.image}></Image>
                                    </FlexItem>  
                                </Flex>
                            } 
                            <Flex>
                                <FlexItem>
                                    <Text content={opt.text}></Text>
                                </FlexItem>
                                 <FlexItem push styles={{ display: opt.correctOption ? "block" : "none" }}>
                                    <AcceptIcon className="correctAnswer"></AcceptIcon>
                                </FlexItem>    
                            </Flex>
                        </Flex>
                    }                       
                />
            )
        });

        return (
            <React.Fragment>
                {items}
            </React.Fragment>
        );
    }

    private renderOptions = (indexQuestion: any): JSX.Element => {

        let valueTypeQuestion = this.props.quizData?.quiz?.questions[indexQuestion].valueType;

        if (valueTypeQuestion == ActionDataColumnValueType.SingleOption) {
            return (
                <Flex>
                    {this.getItemsRadioGroup(indexQuestion)}
                </Flex>                
            );            
        }
        else {
            return (
                <Flex column className="options-checkbox">
                    <FlexItem>
                        {this.getItemsCheckBox(indexQuestion)}    
                    </FlexItem>                    
                </Flex>
            );
        };


    }

    private getShortSummaryContainer(): JSX.Element {
        
        // let optionsWithResponseCount: IBarChartItem[] = [];
        // let rowCount: number = 0;
        
        // if(this.props.showShimmer){
            
        //     let item: IBarChartItem = {
        //         id: "id",
        //         title: "option",
        //         quantity: 0,
        //     };
        //     optionsWithResponseCount = [item, item, item];
        // } else {
        //     optionsWithResponseCount = this.getOptionsWithResponseCount();
        //     rowCount = this.props.answerData ? this.props.answerData?.totalUsersAnswered : 0;
        // }

        // let barChartComponent: JSX.Element = (
        //     <BarChartComponent
        //         accessibilityLabel={this.localize("QuizOptions")}
        //         items={optionsWithResponseCount}
        //         getBarPercentageString={(percentage: number) => {
        //             return Utils.getStringLocalizer(this.localize("BarPercentage"), percentage);
        //         }}
        //         showShimmer={this.props.showShimmer}
        //         totalQuantity={rowCount}
        //     />
        // );

        // if (this.props.showShimmer) {
        //     return (
        //         <>
        //             <ShimmerContainer lines={1} width={["50%"]} showShimmer={this.props.showShimmer}>
        //                 <Text weight="bold" className="primary-text">
        //                     Quiz Title
        //                 </Text>
        //             </ShimmerContainer>
        //             {barChartComponent}
        //         </>
        //     );
        // } else {
        //     return (
        //         <>
        //             <Text weight="bold" className="primary-text word-break">
        //                 {this.props.quizData?.quiz.question.title}
        //             </Text>
        //             {barChartComponent}
        //         </>
        //     );
        // }

        return (<></>);
    }

    // private getOptionsWithResponseCount(): IBarChartItem[] {
    //     let optionsWithResponseCount: IBarChartItem[] = [];

    //     if (this.props.quizData){
    //         for (let option of this.props.quizData?.quiz.questions.options) {
    //             optionsWithResponseCount.push({
    //                 id: option.name ? option.name : "",
    //                 title: option.title,
    //                 quantity: 0,
    //                 titleClassName: "word-break"
    //             });
    //         }
    //     }

    //     let answersObj = this.props.answerData?.answers.map((res: any) => {
    //         return res.answers;
    //     });

    //     const optionsCopy = optionsWithResponseCount;
    //     for (let i = 0; i < optionsWithResponseCount.length; i++) {
    //         let option = optionsWithResponseCount[i];
    //         let optionCount = this.getCountAnswerByOption(answersObj, i);
    //         let percentage: number = Math.round((optionCount / optionsWithResponseCount.length) * 100);
    //         let percentageString: string = percentage + "%";

    //         optionsCopy[i] = {
    //             id: option.id,
    //             title: option.title,
    //             quantity: optionCount,
    //             className: "loser",
    //             titleClassName: option.titleClassName,
    //             accessibilityLabel: Utils.getStringLocalizer(this.localize("OptionResponseAccessibility"),
    //                 option.title, optionCount, percentageString)
    //         };
    //     }

    //     optionsWithResponseCount = optionsCopy;        

    //     return optionsWithResponseCount;
    // }

    // private getCountAnswerByOption(answersObj: any, indexOption: any) {
    //     let totalAnswers = 0;

    //     if (answersObj){
    //         for (let i = 0; i < answersObj.length; i++){
    //             answersObj[i].map((obj: any) => {                
    //                  if(obj.answer == indexOption){
    //                      totalAnswers += 1;
    //                  } 
    //              })
    //          }
    //     }        
        
    //     return totalAnswers;
    // }

    private setupDuedateDialog() {       
        return (
            <Dialog
                className="due-date-dialog"
                overlay={{
                    className: "dialog-overlay",
                }}
                open={this.props.openDialogChangeDueDate}
                onOpen={() => this.props.changeDialogUpdateDueDate(!this.props.openDialogChangeDueDate)}
                cancelButton={
                    this.localize("Cancel")
                }
                confirmButton={
                    this.localize("Change")
                }
                content={
                    <Flex gap="gap.smaller" column>
                        <DateTimePickerView
                            locale={locale()}
                            minDate={new Date()}
                            value={new Date(this.props.quizData?.quiz.settings.dueDate)}
                            placeholderDate={this.localize("SelectADate")}
                            placeholderTime={this.localize("SelectATime")}
                            onSelect={(date: Date) => {                                
                                this.setState({
                                    newDueDate: date.toISOString()
                                }) 
                            }}
                        />
                    </Flex>
                }
                header={this.localize("ChangeDueDate")}
                onCancel={() => {
                    this.props.changeDialogUpdateDueDate(false);
                }}
                onConfirm={() => {                    
                    this.props.updateDueDate(this.state.newDueDate);        
                }}
            />
        );
    }

    private setupCloseDialog() {
        return (
            <Dialog
                className="dialog-base"
                overlay={{
                    className: "dialog-overlay",
                }}
                open={this.props.openDialogCloseQuiz}
                onOpen={() => this.props.changeDialogUpdateCloseQuiz(!this.props.openDialogCloseQuiz)}
                cancelButton={
                    this.localize("Cancel")
                }
                confirmButton={
                    this.localize("Confirm")
                }
                content={
                    <Flex gap="gap.smaller" column>
                        <Text content={this.localize("CloseQuizConfirm")} />                        
                    </Flex>
                }
                header={this.localize("closeQuiz")}
                onCancel={() => {
                    this.props.changeDialogUpdateCloseQuiz(false);
                }}
                onConfirm={() => {
                    this.props.updateCloseQuiz(ActionStatus.Closed);
                }}
            />
        );
    }

    private setupDeleteDialog() {
        return (
            <Dialog
                className="dialog-base"
                overlay={{
                    className: "dialog-overlay",
                }}
                open={this.props.openDialogDeleteQuiz}
                onOpen={() => this.props.changeDialogDeleteQuiz(!this.props.openDialogDeleteQuiz)}
                cancelButton={
                    this.localize("Cancel")
                }
                confirmButton={
                    this.localize("Confirm")
                }
                content={
                    <Flex gap="gap.smaller" column>
                        <Text content={this.localize("deleteQuizConfirm")} />
                    </Flex>
                }
                header={this.localize("deleteQuiz")}
                onCancel={() => {
                    this.props.changeDialogDeleteQuiz(false);
                }}
                onConfirm={() => {
                    // deleteQuiz();
                }}
            />
        );
    }
    
}

const summaryViewQuizWithTranslation = withTranslation()(SummaryViewQuiz);
export default summaryViewQuizWithTranslation;