import * as React from "react";
import { app, dialog } from "@microsoft/teams-js";
import { RouteComponentProps, Redirect } from 'react-router-dom';
import { withTranslation, WithTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { NewNotificationsTypePages, CardTemplates } from "../../utility/SharedEnum";
import { getCardTemplateUrl, getCardTemplateTaksModuleSize, getCardTemplateImage } from '../../utility/CardTemplates';
import { getFeaturesSettings, verifyTeamFilesAccess } from '../../apis/messageListApi';
import { Box, Flex, FlexItem, Grid, Loader, Segment, Text, Tooltip } from "@fluentui/react-northstar";
import "./newNotifications.scss";

const _ = require("lodash");

export type INewNotificationsProps = {} & RouteComponentProps & WithTranslation;

export interface INewNotifications {
    urlRedirect: string;
    page: NewNotificationsTypePages;
    cardTemplates: any[];
    teamFilesAccess: boolean;
    loader: boolean;
}

class NewNotifications extends React.Component<INewNotificationsProps, INewNotifications> {
    private localize: TFunction;

    constructor(props: INewNotificationsProps) {
        super(props);

        this.localize = this.props.t;    

        this.state = {
            urlRedirect: "",
            page: NewNotificationsTypePages.Main,
            cardTemplates: [],
            teamFilesAccess: false,
            loader: true,
        }
    }

    public componentDidMount = async () => {
        void app.initialize().then(() => {
            void app.getContext().then(() => {
                Promise.all([this.getFeaturesSettings(), this.verifyTeamFilesAccess()])
                    .then(() => {
                        this.setState({
                            loader: false
                        });
                    });
            });
        });
    }

    private getFeaturesSettings = async() => {
        let response = await getFeaturesSettings();

        if (response.status === 200 && response.data) {
            this.setState({
                cardTemplates: _.sortBy(response.data, ['name'])
            });
        }
    } 

    private verifyTeamFilesAccess = async () => {

        await verifyTeamFilesAccess().then(() => {
            this.setState({
                teamFilesAccess: true
            });
        }).catch((error) => {
            const errorStatus = error.response.status;
            if (errorStatus === 403) {
                this.setState({
                    teamFilesAccess: false
                });
            }
            else {
                throw error;
            }
        });
    }

    public renderCardTemplates = () : JSX.Element => {

        var items: any[] = [];
        
        this.state.cardTemplates?.forEach((cardTemplate) => {
            
            items.push(
                <Segment 
                    key={cardTemplate?.name}
                    disabled={cardTemplate?.comingSoon}
                    className={`box-notification-types ${cardTemplate?.comingSoon ? '' : 'cursor-pointer'}`}
                    onClick={() => {
                        if (!cardTemplate?.comingSoon) {
                            this.redirectForm(cardTemplate?.name);
                        }
                    }}   
                >
                    <Flex column>
                        <Flex>
                            <FlexItem>
                                <Tooltip 
                                    content={cardTemplate ? this.localize(cardTemplate?.name) : this.localize(CardTemplates.Message) } 
                                    trigger={                                
                                        <Box 
                                            className={`notification-type-img ${getCardTemplateImage(cardTemplate?.name)} ${cardTemplate?.comingSoon ? "coming-soon" : ""}`}
                                        ></Box>
                                    } 
                                />
                            </FlexItem>
                            <Flex styles={{marginLeft: "2rem"}} column>
                                <Text weight="bold" size="medium" content={this.localize(cardTemplate?.name)}></Text>
                                {cardTemplate?.name == CardTemplates.MessageVideo && !this.state.teamFilesAccess && 
                                    <Text size="small" error content={this.localize("RequiredSettings")}></Text>
                                }
                            </Flex>
                        </Flex>

                        <FlexItem styles={{marginTop: "2rem"}}>
                            <Text size="small" content={this.localize(`DescriptionApp${cardTemplate?.name}`)}></Text>
                        </FlexItem>
                          
                    </Flex> 
                    {cardTemplate?.comingSoon && 
                        <Flex 
                            styles={{
                                position: "absolute",
                                top: "0",
                                left: "70%",
                                width: "30%",
                                borderRadius: "0.2rem",
                                textAlign: "center",
                                backgroundColor: "green",
                                color: "white",
                                transformOrigin: "0 0",
                                // transform: "rotate(45deg)"
                            }}    
                        >
                            <Text styles={{width: "100%"}} size="small" content={this.localize("ComingSoon")}></Text>
                        </Flex>
                    }
                                                         
                </Segment>                
            )            
         });
        
        return (
            <React.Fragment>
                {items}
            </React.Fragment>
        );
    }

    public redirectForm = (cardTemplate: any) => {

        let url = `${getCardTemplateUrl(cardTemplate)}?locale={locale}`;
        let sizeCard = getCardTemplateTaksModuleSize(cardTemplate);

        this.setState({
            urlRedirect: url,
        }, () => {
            
            dialog.update.resize({ width: sizeCard.width, height: sizeCard.height})
            
            let qs = new URLSearchParams(window.location.search);
            let noSendAllParam = qs.get("noSendAll") ? `&noSendAll=${qs.get("noSendAll")}` : '';

            let accessTeamFileParam = cardTemplate == CardTemplates.MessageVideo ? `&accessTeamFile=${this.state.teamFilesAccess}` : '';

            this.setState({
                page: NewNotificationsTypePages.NewNotification,
                urlRedirect: `${this.state.urlRedirect}${noSendAllParam}${accessTeamFileParam}`,            
            })
        })
    }

    public render = () => {
        
        return (
            <div className="new-notifications">
                <div className="formContainer">
                    {this.state.loader ? 
                        <div className="Loader">
                            <Loader />
                        </div>
                        :
                        <Flex column gap={"gap.medium"} className="body-container">

                            <Text content={this.localize("ChooseNotificationType")} ></Text>

                            {this.state.page == NewNotificationsTypePages.Main &&
                                <Grid
                                    columns="2"
                                    content={this.renderCardTemplates()}
                                />
                            }

                            {this.state.page == NewNotificationsTypePages.NewNotification &&
                                <Redirect to={this.state.urlRedirect}></Redirect>
                            }
                        </Flex>
                    }                    
                </div>
            </div>
        );
    }
}

const newNotificationsWithTranslation = withTranslation()(NewNotifications);
export default newNotificationsWithTranslation;