import * as React from "react";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { initializeIcons } from '@fluentui/react';
import { withTranslation, WithTranslation } from "react-i18next";
import { TFunction } from "i18next";
import moment, { locale } from "moment";
import "./summary.scss";
import { updateContentJsonSenttNotification } from '../../../apis/messageListApi';
import {
    Flex, FlexItem, Menu, Dialog, Loader, Text, Avatar, ButtonProps, BanIcon, TrashCanIcon, CalendarIcon, MoreIcon, SplitButton, Divider
} from "@fluentui/react-northstar";
import { Utils } from "../../../utility/Utils";
import { UxUtils } from "../../../utility/UxUtils";
import { ActionStatus, ViewTypeSummary } from "../../../utility/SharedEnum";
import { IBarChartItem, BarChartComponent } from "../../Shared/BarChartComponent/barChartComponent";
import { ShimmerContainer } from "../../Shared/ShimmerLoader/shimmerContainer";
import { DateTimePickerView } from "../../Shared/DateTime/DateTimePickerView";
import { IPoll } from "../../../interfaces/IPoll";

export interface SummaryViewState {    
    menuOpen: boolean;
    newDueDate: any;     
}

export interface SummaryViewProps extends WithTranslation {
    notificationData: any;
    pollData: IPoll;
    answerData: any;
    showShimmer: boolean;
    openDialogChangeDueDate: boolean;       
    openDialogClosePoll: boolean;
    openDialogDeletePoll: boolean;
    setCurrentView: (value:ViewTypeSummary) => void;
    updateDueDate: (value:any) => void;
    updateClosePoll: (value:any) => void;
    changeDialogUpdateDueDate: (value:boolean) => void;
    changeDialogUpdateClosePoll: (value:boolean) => void;
    changeDialogDeletePoll: (value:boolean) => void;
}

class SummaryView extends React.Component<SummaryViewProps, SummaryViewState> {

    private localize: TFunction;    

    constructor(props: SummaryViewProps) {
        super(props);
        
        this.localize = this.props.t;

        this.state = {
            menuOpen: false,
            newDueDate: "",
        }
    }    

    public render() {
        
        return (
            <>
                <Flex 
                    gap="gap.medium" 
                    column 
                    className="body-container"
                    id="bodyContainer"
                >
                    {this.renderHeaderContainer()}
                    {this.renderTopContainer()}
                    {this.getShortSummaryContainer()}
                </Flex>
                {this.renderFooterView()}
            </>
        );
    }

    public renderHeaderContainer() {
        let dueDate = this.getTextHeaderByStatus();
    
        return (
            <Flex space="between">  
                {this.props.showShimmer && 
                    <FlexItem styles={{width: "100%"}}>
                        <ShimmerContainer lines={1} width={["50%"]} showShimmer={this.props.showShimmer}>
                            <Text weight="bold" className="primary-text">Due Date</Text>                        
                        </ShimmerContainer>
                    </FlexItem>
                }              

                {!this.props.showShimmer &&
                    <FlexItem>
                        <>
                            <Text weight="bold" className="primary-text">{dueDate}</Text>

                            {this.isPollActive() &&
                                <Menu 
                                    className="menuContainer" 
                                    iconOnly 
                                    items={
                                        [
                                            {
                                                key: 'more',
                                                icon: <MoreIcon outline={true} />,
                                                menuOpen: this.state.menuOpen,
                                                active: this.state.menuOpen,
                                                indicator: false,
                                                menu: {
                                                    items: this.getHeaderMenuItems(),
                                                },
                                                onMenuOpenChange: (e: any, { menuOpen }: any) => {
                                                    this.setState({
                                                        menuOpen: menuOpen
                                                    });
                                                }
                                            }
                                        ]                            
                                    } 
                                />
                            }
                            
                        </>                    
                    </FlexItem>
                }                   
                
                <FlexItem>
                    <>
                        {this.setupDuedateDialog()}
                        {this.setupCloseDialog()}
                        {this.setupDeleteDialog()}
                    </>                    
                </FlexItem>          
            </Flex>
        )
    }

    public getHeaderMenuItems() {
        let menuItemList:any[] = [];
        
        // FUNCIONALIDADES OCULTAS PARA A PRIMEIRA ENTREGA POR CONTA DO UPDATECARD
        if (this.isPollActive()){
            // let changeExpiry = {
            //     key: "changeDueDate",
            //     content: this.localize("ChangeDueBy"),
            //     icon: <CalendarIcon outline={true} />,
            //     onClick: () => {
            //         this.props.changeDialogUpdateDueDate(true);
            //     }
            // }

            // menuItemList.push(changeExpiry);

            let closePoll = {
                key: "close",
                content: this.localize("ClosePoll"),
                icon: <BanIcon outline={true} />,
                onClick: () => {
                    // ALTERAR DATA DE FECHAR VOTAÇÃO                
                    this.props.changeDialogUpdateClosePoll(true);
                }
            }

            menuItemList.push(closePoll);
        }

        // let deletePoll = {
        //     key: "delete",
        //     content: this.localize("DeletePoll"),
        //     icon: <TrashCanIcon outline={true} />,
        //     onClick: () => {
        //         // ALTERAR DATA DE FECHAR VOTAÇÃO            
        //         this.props.changeDialogDeletePoll(true);
        //     }
        // }

        // menuItemList.push(deletePoll);

        return menuItemList;
    }

    public getTextHeaderByStatus() {
        
        if(this.props.pollData){
            if(this.props.pollData.poll.status == ActionStatus.Active) {
                var dueDate = moment(this.props.pollData.poll.settings.dueDate).format('llll');
                return Utils.getStringLocalizer(this.localize("dueByDate"), [dueDate]);
            }
            else if(this.props.pollData.poll.status == ActionStatus.Closed){
                // Validar com a data de FECHAMENTO MANUAL da Votação
                var dueDate = moment(this.props.pollData.poll.settings.dueDate).format('llll');
                return Utils.getStringLocalizer(this.localize("ClosedOn"), [dueDate]);
            }
            else if(this.props.pollData.poll.status == ActionStatus.Expired){
                var dueDate = moment(this.props.pollData.poll.settings.dueDate).format('llll');
                return Utils.getStringLocalizer(this.localize("ExpiredOn"), [dueDate]);
            }
        }      
    }

    public renderTopContainer() {

        // Quantidade de pessoas que responderam
        let rowCount: number = this.props.answerData ? this.props.answerData?.totalUsersAnswered : 0;
        // Quantidade de pessoas que receberam o Poll
        let memberCount: number = this.props.notificationData ? this.props.notificationData?.totalMessageCount : 0;
        let participationInfoItems: IBarChartItem[] = [];
        let participationPercentage = memberCount ? Math.round((rowCount / memberCount) * 100) : 0;

        participationInfoItems.push({
            id: "participation",
            title: Utils.getStringLocalizer(this.localize("Participation"), participationPercentage),
            titleClassName: "participation-title",
            quantity: rowCount,
            hideStatistics: true,
        });

        let participation: string = (rowCount == 1)
            ? Utils.getStringLocalizer(this.localize("ParticipationIndicatorSingular"), rowCount, memberCount)
            : Utils.getStringLocalizer(this.localize("ParticipationIndicatorPlural"), rowCount, memberCount);

        return (
            <>
                <div
                    role="group"
                    aria-label={Utils.getStringLocalizer(this.localize("Participation"), participationPercentage)}
                >
                    <BarChartComponent
                        items={participationInfoItems}
                        getBarPercentageString={(percentage: number) => {
                            return Utils.getStringLocalizer(this.localize("BarPercentage"), percentage);
                        }}
                        totalQuantity={memberCount}
                        showShimmer={this.props.showShimmer}
                    />

                    <Flex space="between" className="participation-container">
                        <Flex.Item aria-hidden="false">
                            <ShimmerContainer lines={1} showShimmer={this.props.showShimmer}>
                                <div>
                                    <Text
                                        {...UxUtils.getTabKeyProps()}
                                        tabIndex={0}
                                        role="button"
                                        className="underline"
                                        color="brand"
                                        size="small"
                                        content={participation}
                                        onClick={() => {
                                            if(this.props.setCurrentView){
                                                this.props.setCurrentView(ViewTypeSummary.ResponderView);
                                            }                                                   
                                        }}
                                    />
                                </div>
                            </ShimmerContainer>
                        </Flex.Item>
                    </Flex>
                </div>
                <Divider className="divider" />
            </>
        );
    }

    public renderFooterView(){
        let menuItems = [];
        menuItems.push(
            {
            key: 'download_image',
            content: this.localize("DownloadImage"),
            },
            {
            key: 'download_responses',
            content: this.localize("DownloadResponses"),
            }
        );

        return (
            <Flex className="footer-layout" gap={"gap.smaller"} hAlign="end">
                {/* <SplitButton
                    key="download_button"
                    id="download"
                    menu={menuItems}
                    button={{
                        content: this.localize("Download"),
                        className: "download-button",
                    }}
                    primary
                    toggleButton={{ "aria-label": "more-options" }}
                    // onMainButtonClick={() => this.downloadImage()}
                /> */}
            </Flex>
        )
    }

    private isPollActive(): boolean {
        return (
            this.props.pollData?.poll.status == ActionStatus.Active
        );
    }

    private getShortSummaryContainer(): JSX.Element {
        
        let optionsWithResponseCount: IBarChartItem[] = [];
        let rowCount: number = 0;
        
        if(this.props.showShimmer){
            
            let item: IBarChartItem = {
                id: "id",
                title: "option",
                quantity: 0,
            };
            optionsWithResponseCount = [item, item, item];
        } else {
            optionsWithResponseCount = this.getOptionsWithResponseCount();
            rowCount = this.props.answerData ? this.props.answerData?.totalUsersAnswered : 0;
        }

        let barChartComponent: JSX.Element = (
            <BarChartComponent
                accessibilityLabel={this.localize("PollOptions")}
                items={optionsWithResponseCount}
                getBarPercentageString={(percentage: number) => {
                    return Utils.getStringLocalizer(this.localize("BarPercentage"), percentage);
                }}
                showShimmer={this.props.showShimmer}
                totalQuantity={rowCount}
            />
        );

        if (this.props.showShimmer) {
            return (
                <>
                    <ShimmerContainer lines={1} width={["50%"]} showShimmer={this.props.showShimmer}>
                        <Text weight="bold" className="primary-text">
                            Poll Title
                        </Text>
                    </ShimmerContainer>
                    {barChartComponent}
                </>
            );
        } else {
            return (
                <>
                    <Text weight="bold" className="primary-text word-break">
                        {this.props.pollData?.poll?.question?.text}
                    </Text>
                    {barChartComponent}
                </>
            );
        }
    }

    private getOptionsWithResponseCount(): IBarChartItem[] {
        let optionsWithResponseCount: IBarChartItem[] = [];

        if (this.props.pollData){
            for (let option of this.props.pollData?.poll.question.options) {
                optionsWithResponseCount.push({
                    id: option.name ? option.name : "",
                    title: option.text,
                    quantity: 0,
                    titleClassName: "word-break"
                });
            }
        }
        
        let answersObj = this.props.answerData?.answers.map((res: any) => {
            return res.answers;
        });

        const optionsCopy = optionsWithResponseCount;
        for (let i = 0; i < optionsWithResponseCount.length; i++) {
            let option = optionsWithResponseCount[i];
            let optionCount = this.getCountAnswerByOption(answersObj, i);
            let percentage: number = Math.round((optionCount / optionsWithResponseCount.length) * 100);
            let percentageString: string = percentage + "%";

            optionsCopy[i] = {
                id: option.id,
                title: option.title,
                quantity: optionCount,
                className: "loser",
                titleClassName: option.titleClassName,
                accessibilityLabel: Utils.getStringLocalizer(this.localize("OptionResponseAccessibility"),
                    option.title, optionCount, percentageString)
            };
        }

        optionsWithResponseCount = optionsCopy;        

        return optionsWithResponseCount;
    }

    private getCountAnswerByOption(answersObj: any, indexOption: any) {
        let totalAnswers = 0;

        if (answersObj){
            for (let i = 0; i < answersObj.length; i++){
                answersObj[i].map((obj: any) => {                
                     if(obj.answer == indexOption){
                         totalAnswers += 1;
                     } 
                 })
             }
        }        
        
        return totalAnswers;
    }

    private setupDuedateDialog() {       
        return (
            <Dialog
                className="due-date-dialog"
                overlay={{
                    className: "dialog-overlay",
                }}
                open={this.props.openDialogChangeDueDate}
                onOpen={() => this.props.changeDialogUpdateDueDate(!this.props.openDialogChangeDueDate)}
                cancelButton={
                    this.localize("Cancel")
                }
                confirmButton={
                    this.localize("Change")
                }
                content={
                    <Flex gap="gap.smaller" column>
                        <DateTimePickerView
                            locale={locale()}
                            minDate={new Date()}
                            value={new Date(this.props.pollData?.poll.settings.dueDate)}
                            placeholderDate={this.localize("SelectADate")}
                            placeholderTime={this.localize("SelectATime")}
                            onSelect={(date: Date) => {                                
                                this.setState({
                                    newDueDate: date.toISOString()
                                }) 
                            }}
                        />
                    </Flex>
                }
                header={this.localize("ChangeDueDate")}
                onCancel={() => {
                    this.props.changeDialogUpdateDueDate(false);
                }}
                onConfirm={() => {                    
                    this.props.updateDueDate(this.state.newDueDate);        
                }}
            />
        );
    }

    private setupCloseDialog() {
        return (
            <Dialog
                className="dialog-base"
                overlay={{
                    className: "dialog-overlay",
                }}
                open={this.props.openDialogClosePoll}
                onOpen={() => this.props.changeDialogUpdateClosePoll(!this.props.openDialogClosePoll)}
                cancelButton={
                    this.localize("Cancel")
                }
                confirmButton={
                    this.localize("Confirm")
                }
                content={
                    <Flex gap="gap.smaller" column>
                        <Text content={this.localize("ClosePollConfirmation")} />                        
                    </Flex>
                }
                header={this.localize("ClosePoll")}
                onCancel={() => {
                    this.props.changeDialogUpdateClosePoll(false);
                }}
                onConfirm={() => {
                    this.props.updateClosePoll(ActionStatus.Closed);
                }}
            />
        );
    }

    private setupDeleteDialog() {
        return (
            <Dialog
                className="dialog-base"
                overlay={{
                    className: "dialog-overlay",
                }}
                open={this.props.openDialogDeletePoll}
                onOpen={() => this.props.changeDialogDeletePoll(!this.props.openDialogDeletePoll)}
                cancelButton={
                    this.localize("Cancel")
                }
                confirmButton={
                    this.localize("Confirm")
                }
                content={
                    <Flex gap="gap.smaller" column>
                        <Text content={this.localize("DeletePollConfirmation")} />
                    </Flex>
                }
                header={this.localize("DeletePoll")}
                onCancel={() => {
                    this.props.changeDialogDeletePoll(false);
                }}
                onConfirm={() => {
                    // deletePoll();
                }}
            />
        );
    }
    
}

const summaryViewWithTranslation = withTranslation()(SummaryView);
export default summaryViewWithTranslation;