import i18n from '../i18n';

export const getPathEmojiCdn = (key: string): string => {
    return `https://statics.teams.cdn.office.net/evergreen-assets/personal-expressions/v2/assets/emoticons/${key}/default/20_f.png`;
}

export const getNameEmojiCdn = async (key: string) => {

    var lsEmojisJson = localStorage.getItem('emojisJson');
    var emojiName;
    if (!lsEmojisJson) {
        // Get Content JSON Url
        await fetch(`https://statics.teams.cdn.office.net/evergreen-assets/personal-expressions/v1/metadata/b6565ed6920446ab9485a32d3c3af89b/${i18n.language}.json`, {
            method: 'GET',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'content-type': 'application/json'
            }
        })
            .then(res => res.json())
            .then((data) => {
                localStorage.setItem('emojisJson', JSON.stringify(data));
                emojiName = getEmojiDescription(JSON.stringify(data), key) ?? key;
            })
            .catch(() => key);
    } else {
        emojiName = getEmojiDescription(lsEmojisJson, key) ?? key;
    }
    return emojiName;
}

const getEmojiDescription = (data: any, key: string) => {
    const categories = JSON.parse(data).categories;
    for (let categorie of categories) {
        const emoticon = categorie.emoticons.filter((em: any) => em.id === key)
        if (emoticon.length > 0) {
            return emoticon[0].description;
        }
    }
}