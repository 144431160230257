import * as React from "react";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { initializeIcons } from '@fluentui/react';
import { withTranslation, WithTranslation } from "react-i18next";
import { TFunction } from "i18next";
import "./summaryQuiz.scss";
import { getSentNotification, getAnswerDataQuizByNotificationId, updateContentJsonSenttNotification } from '../../../apis/messageListApi';
// import { ErrorView } from "./ErrorView/errorView";
import { NotificationsType, ViewTypeSummary } from "../../../utility/SharedEnum";
import SummaryView from "./summaryViewQuiz";
import TabViewQuiz from "./tabViewQuiz";
import UserResponseView from "./userResponseViewQuiz";
import { IQuiz } from "../../../interfaces/IQuiz";
import { Utils } from "../../../utility/Utils";


export interface SummaryPageQuizState {
    currentView: ViewTypeSummary,
    quizData: IQuiz,
    notificationData: any;
    loaderNotifications: boolean;
    answerData: any;
    selectedAnswerUser: string;
    loaderAnswers: boolean;
    openDialogChangeDueDate: boolean;
    openDialogCloseQuiz: boolean;
    openDialogDeleteQuiz: boolean;
}

export type SummaryPageQuizProps = {} & RouteComponentProps & WithTranslation;

class SummaryPageQuiz extends React.Component<SummaryPageQuizProps, SummaryPageQuizState> {

    private localize: TFunction;

    constructor(props: SummaryPageQuizProps) {
        super(props);
        
        this.localize = this.props.t;

        this.state = {
            currentView: ViewTypeSummary.Main,
            openDialogChangeDueDate: false,
            openDialogCloseQuiz: false,
            openDialogDeleteQuiz: false,
            notificationData: null,
            loaderNotifications: true,
            answerData: null,
            selectedAnswerUser: "",
            loaderAnswers: true,
            quizData: {
                quiz: {
                    type: NotificationsType.Quiz,
                    status: "",
                    title: "",
                    description: "",
                    imageQuiz: "",
                    questions: [
                        {
                            name: "0",
                            text: "",
                            image: "",
                            options: [
                                {
                                    text: "",
                                    image: "",
                                    correctOption: false,
                                },
                                {
                                    text: "",
                                    image: "",
                                    correctOption: false,
                                }
                            ]
                        }                        
                    ],
                    settings: {
                        showCorrectAnswer: true,
                        dueDate: Utils.getDefaultExpiry(7).toISOString(), // default due date for quiz is one week
                    },
                },
                adaptiveCardJson: "",
            }
        }
    }

    public async componentDidMount(){
        
        let id = (this.props.match.params as any).id;

        if (id) {
            this.getItem(id);
            this.getAnswerDataQuizByNotificationId(id);
        }
    }

    public render() {                
        return this.getView();       
    }

    /**
     * Method to return the view based on the user selection
     */
    private getView() {
        
        let currentView = this.state.currentView;
        
        if (currentView == ViewTypeSummary.Main) {
            return (
                <SummaryView                    
                    notificationData={this.state.notificationData}
                    quizData={this.state.quizData}
                    answerData={this.state.answerData}
                    showShimmer={this.state.loaderNotifications || this.state.loaderAnswers}
                    openDialogChangeDueDate={this.state.openDialogChangeDueDate}
                    openDialogCloseQuiz={this.state.openDialogCloseQuiz}
                    openDialogDeleteQuiz={this.state.openDialogDeleteQuiz}
                    setCurrentView={(value: ViewTypeSummary) => {
                        this.setCurrentView(value)
                    }}
                    updateDueDate={(value: any) => {
                        this.updateDueDate(value);
                    }}
                    changeDialogUpdateDueDate={(value: any) => {
                        this.changeDialogUpdateDueDate(value);     
                    }}
                    updateCloseQuiz={(value: any) => {
                        this.updateCloseQuiz(value);
                    }}
                    changeDialogUpdateCloseQuiz={(value: any) => {
                        this.changeDialogUpdateCloseQuiz(value);
                    }}
                    changeDialogDeleteQuiz={(value: any) => {
                        this.changeDialogDeleteQuiz(value);
                    }}
                />
            );
        
        } else if (currentView == ViewTypeSummary.ResponderView || currentView == ViewTypeSummary.NonResponderView) {
            return ( 
                <TabViewQuiz 
                    notificationData={this.state.notificationData}
                    quizData={this.state.quizData}
                    answerData={this.state.answerData}
                    selectedAnswerUser={this.state.selectedAnswerUser}
                    currentView={this.state.currentView}
                    setSelectedUserAnswer={(userAnswer: any) => {
                        this.setSelectedUserAnswer(userAnswer);
                    }}
                    setCurrentView={(value: ViewTypeSummary) => {
                        this.setCurrentView(value)
                    }}
                />
            )
        } else if (currentView == ViewTypeSummary.UserResponseView) {

            let answerUserData = this.state.answerData?.answers?.find((a: any) => a.userId == this.state.selectedAnswerUser);

            return (
                <UserResponseView
                    notificationId={this.state.notificationData?.id}     
                    currentView={this.state.currentView}
                    selectedAnswerUser={this.state.selectedAnswerUser}
                    setCurrentView={(value: ViewTypeSummary) => {
                        this.setCurrentView(value)
                    }}
                />
            );
        }

    }

    public setSelectedUserAnswer(userAnswer: string) {        
        this.setState({
           selectedAnswerUser: userAnswer
       });
   }

    public setCurrentView(currentView: ViewTypeSummary) {        
         this.setState({
            currentView: currentView
        });
    }

    public updateDueDate = async (newDueDate: any) => {
        this.setState({
            quizData: {    
                ...this.state.quizData,
                quiz: {
                    ...this.state.quizData.quiz,
                    settings: {
                        ...this.state.quizData.quiz.settings,
                        dueDate: newDueDate
                    }
                }            
            }
        }, async () => {
            this.updateContentJSON().then(() => {
                this.changeDialogUpdateDueDate(false);
            });
        });        
   }

   private changeDialogUpdateDueDate(open: boolean) {
        this.setState({
            openDialogChangeDueDate: open
        })
    }

    private changeDialogUpdateCloseQuiz(open: boolean) {         
        this.setState({
            openDialogCloseQuiz: open
        })
    }

    private changeDialogDeleteQuiz(open: boolean) {        
        this.setState({
            openDialogDeleteQuiz: open
        })
    }

    public updateCloseQuiz(status: any) {
        this.setState({
            quizData: {    
                ...this.state.quizData,
                quiz: {
                    ...this.state.quizData.quiz,
                    status: status
                }            
            }
        }, () => {
            this.updateContentJSON().then(() => {
                this.changeDialogUpdateCloseQuiz(false);
            });
        });        
    }

    private updateContentJSON = async () => {
        const formData = new FormData();                           
        formData.append("notificationData", JSON.stringify(this.state.notificationData));
        formData.append("notificationContent", JSON.stringify(this.state.quizData))

        try {
            await updateContentJsonSenttNotification(formData);
        } 
        catch (error) {
            throw error;
        }
    }

    private getItem = async (id: number) => {
        try {
            const response = await getSentNotification(id);
            const notificationData = response.data;
            
            // Get Content JSON Url
            await fetch(notificationData.contentJsonUrl, {
                method: 'GET', 
                headers: {
                    'Access-Control-Allow-Origin': '*', 
                    'content-type': 'application/json'
                }
            })
            .then(res => res.json())
                    .then((data: IQuiz) => {        
                        this.setState({                        
                           notificationData: notificationData,
                           quizData: data,
                           loaderNotifications: false  
                        });
                    })
            .catch(err => { throw err });

            
        } catch (error) {
            return error;
        }
    }

    private getAnswerDataQuizByNotificationId = async (id: number) => {
        try {
            const response = await getAnswerDataQuizByNotificationId(id);
            const answerData = response.data;
            this.setState({
                answerData: answerData,
                loaderAnswers: false
            })

        } catch (error) {
            return error;
        }
    }
}


const summaryPageQuizWithTranslation = withTranslation()(SummaryPageQuiz);
export default summaryPageQuizWithTranslation;