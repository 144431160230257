import * as React from "react";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { withTranslation, WithTranslation } from "react-i18next";
import { TFunction } from "i18next";
import {  Button, Flex, FlexItem, Image, Input, Segment, Text, Tooltip } from "@fluentui/react-northstar";
import { AcceptIcon, AddIcon, CircleIcon, FilesImageIcon, TrashCanIcon } from "@fluentui/react-northstar";
import "./QuestionContainer.scss";
import { ActionDataColumnValueType } from "../../../utility/SharedEnum";

export interface IQuestion {
    name?: string;
    text: string;
    valueError?: boolean;
    image: string;
    options: IQuestionOptions[];
    valueType?: ActionDataColumnValueType,
    questionPrefix?: JSX.Element;
    questionPlaceholder?: string;
    checkedCorrectAnswer?: boolean;
}

export interface IQuestionOptions {
    name?: string;
    text: string;
    image: string;    
    imageName?: string;
    checked?: boolean;
    optionError?: boolean;
    correctOption: boolean;
    choicePrefix?: JSX.Element;
    choicePlaceholder?: string;
}

export interface IQuestionContainerProps extends WithTranslation {
    questions: IQuestion[];
    optionsError?: string[];
    limit?: number;
    maxLength?: number;
    renderForMobile?: boolean;
    focusOnError?: boolean;
    inputClassName?: string;
    onAddQuestion?: () => void;
    onUpdateQuestion?: (i:any, value:any, imageQuestion?: any) => void;
    onDeleteQuestion?: (i:any) => void;
    onUpdateChoice?: (i:any, indexQuestion: any, value?:any, correctOption?: boolean, imageOption?: any) => void;
    onDeleteChoice?: (i:any, indexQuestion: any) => void;
    onAddOption?: (i: any) => void;
}

/**
 * <QuestionContainer> component to add question container in creation view
 */
 class QuestionContainer extends React.Component<IQuestionContainerProps, any> {

    readonly localize: TFunction;

    constructor(props: IQuestionContainerProps) {
        super(props);
        this.localize = this.props.t;
    }

    render() {
        let items: JSX.Element[] = [];
        
        for(let i = 0; i < this.props.questions.length; i++){
            let numberQuestion = i + 1; 
            items.push(            
                <div key={"question-" + numberQuestion} className="question-container">
                    <div className="card-box card-border card-bg">
                        <Segment 
                            styles={{ boxShadow: "rgba(0 0 0 / 10%) 0px 0.2rem 0.4rem -0.075rem" }}
                            className={!this.props.questions[i].checkedCorrectAnswer ? "card-box-alert" : ""}
                        >

                            <Flex column gap="gap.medium">
                                <Flex>
                                    <FlexItem>
                                        <Text 
                                            className={"question-number"} 
                                            content={ `${this.localize("question")} # ${(i + 1)}` }                                             
                                        />
                                    </FlexItem>

                                    {this.props.questions.length > 1 &&
                                        <FlexItem push>
                                            <TrashCanIcon                                                 
                                                outline
                                                styles={{cursor: "pointer"}}
                                                onClick={() => {
                                                    if(this.props.onDeleteQuestion){
                                                        this.props.onDeleteQuestion(i);
                                                    }
                                                }}   
                                            /> 
                                        </FlexItem>
                                    }
                                    
                                </Flex>

                                <Flex column>

                                    {this.props.questions[i].image && 
                                        <Flex className="questions-img-box">
                                            <FlexItem>
                                                <Image className="questions-img-preview" src={this.props.questions[i].image}></Image>
                                            </FlexItem>  
                                        </Flex>
                                    }  

                                    <FlexItem>
                                        <input 
                                            type="file" 
                                            name={`input-file-question-${i}`} 
                                            id={`input-file-question-${i}`} 
                                            className="inputFile" 
                                            accept="image/*" 
                                            onChange={(event) => {                                          
                                                this.handleUploadImageQuestion(event, i);
                                            }} 
                                        />    
                                    </FlexItem>

                                    <FlexItem>
                                        <>
                                            <Text className={this.props.questions[i].valueError ? "text-alert" : "text-alert display-none"} content={this.localize("questionLeftBlank")}></Text>
                                            <Input 
                                                className={this.props.questions[i].valueError ? "input-title-question input-alert" : "input-title-question"}
                                                icon={
                                                    <FilesImageIcon 
                                                        className="icons-click"
                                                        outline
                                                        onClick={() =>{                                                        
                                                            document.getElementById(`input-file-question-${i}`)?.click();
                                                        }}
                                                    />
                                                } 
                                                iconPosition="start" 
                                                fluid 
                                                placeholder={this.props.questions[i].questionPlaceholder} 
                                                value={this.props.questions[i].text}
                                                onChange={(e: any, data: any) => {
                                                    if(this.props.onUpdateQuestion){
                                                        this.props.onUpdateQuestion(i, data.value);
                                                    }
                                                }}
                                            />                                        
                                        </>
                                        
                                    </FlexItem>

                                    
                                </Flex>

                                {this.renderQuestionOptions(this.props.questions[i].options, i)}

                                {this.props.questions[i].options.length < 10 &&
                                    <Flex>
                                        <Button 
                                            className="btn-text"
                                            icon={<AddIcon />} 
                                            iconPosition="before" 
                                            text 
                                            content={this.localize("addMoreOptions")} 
                                            onClick={() => 
                                            {
                                                if(this.props.onAddOption){
                                                    this.props.onAddOption(i);
                                                }
                                            }}
                                        />
                                    </Flex>
                                }     

                                {!this.props.questions[i].checkedCorrectAnswer &&
                                    <Flex>
                                        <FlexItem>
                                            <Text className={"text-alert"} content={this.localize("selectCorrectChoice")} />
                                        </FlexItem>
                                    </Flex>
                                }                                                           
                                                                
                            </Flex> 
                            
                        </Segment>                        
                    </div>
                </div>
            );

        }

        return(
            <Flex column gap="gap.medium">
                {items}
                <Flex>
                    <FlexItem>
                        <Button 
                            primary 
                            icon={<AddIcon/>} 
                            content={this.localize("addQuestion")} 
                            onClick={() => {
                                if(this.props.onAddQuestion){
                                    this.props.onAddQuestion();
                                }
                            }}
                        />
                    </FlexItem>
                </Flex>
            </Flex>            
        );
    }

    renderQuestionOptions(options: any, indexQuestion: any){
        let itemsQuestionOptions = [];
        
        for(let i = 0; i < options.length; i++){
            itemsQuestionOptions.push(            
                <Flex gap="gap.smaller">
                    <FlexItem>
                        <CircleIcon outline size="small" className="choice-item-circle" disabled /> 
                    </FlexItem>
                    
                    <FlexItem grow>
                        <Flex column>
                            {options[i].image && 
                                <Flex className="questions-img-box">
                                    <FlexItem>
                                        <Image className="questions-img-preview" src={options[i].image}></Image>
                                    </FlexItem>  
                                </Flex>
                            }          

                            <FlexItem>
                                <input 
                                    type="file" 
                                    name={`input-file-question-${indexQuestion}-option-${i}`} 
                                    id={`input-file-question-${indexQuestion}-option-${i}`} 
                                    className="inputFile" 
                                    accept="image/*" 
                                    onChange={(event) => {                                          
                                        this.handleUploadImageOption(event, indexQuestion, i);
                                    }} 
                                />    
                            </FlexItem>                         
                            
                            <FlexItem>    
                                <>
                                    <Text className={options[i].optionError ? "text-alert" : "text-alert display-none"} content={this.localize("required")}></Text>

                                    <Flex styles={{position: "relative"}}>
                                        
                                        <Flex>
                                            <FilesImageIcon 
                                                className="icons-click icon-image-upload"
                                                outline
                                                onClick={() =>{                                                    
                                                    document.getElementById(`input-file-question-${indexQuestion}-option-${i}`)?.click();
                                                }}
                                            />
                                        </Flex>

                                        <Input 
                                            className={options[i].optionError ? "input-options input-alert" : "input-options"} 
                                            icon={
                                                <Flex>                                                                                    
                                                    <Flex gap="gap.small">
                                                        <FlexItem>
                                                            <TrashCanIcon 
                                                                className={options.length <= 2 ? "visibility-hidden icons-click" : "icons-click"}                                        
                                                                outline 
                                                                onClick={() => {
                                                                    if(this.props.onDeleteChoice){
                                                                        this.props.onDeleteChoice(i, indexQuestion);
                                                                    }
                                                                }}
                                                            />
                                                        </FlexItem>                                    
                                                        <FlexItem>
                                                            <AcceptIcon 
                                                                styles={options[i].correctOption ? {color: "rgb(146 195 83)"} : {}}
                                                                className="icons-click"
                                                                outline 
                                                                onClick={() => {
                                                                    if(this.props.onUpdateChoice){
                                                                        var checkedCorrect = options[i].correctOption;
                                                                        this.props.onUpdateChoice(i, indexQuestion, null, !checkedCorrect, null);
                                                                    }
                                                                }}
                                                            />
                                                        </FlexItem>
                                                    </Flex>
                                                </Flex>                            
                                            } 
                                            placeholder={options[i].choicePlaceholder}
                                            value={options[i].text}
                                            onChange={(e: any, data: any) => {
                                                if(this.props.onUpdateChoice){
                                                    this.props.onUpdateChoice(i, indexQuestion, data.value);
                                                }
                                            }}
                                            fluid 
                                        />
                                    </Flex>
                                    
                                </>                            
                                
                            </FlexItem>
                            
                        </Flex>
                    </FlexItem>         
                </Flex>
            );
        }

        return itemsQuestionOptions;
    }    

    handleUploadImageQuestion(event: any, indexQuestion: any) {
        
        let file = event.target.files[0];

        if (file) {

            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                let fileBase64 = reader.result as string;

                if ((fileBase64 === "") || !(fileBase64.startsWith("data:image/png;base64,") || fileBase64.startsWith("data:image/jpeg;base64,") || fileBase64.startsWith("data:image/jpg;base64,") || fileBase64.startsWith("data:image/gif;base64,"))) {
                    
                    // Exibir erro 

                    (document.getElementById("input-file") as HTMLInputElement).value = "";
                    return;

                } else {
                    if(this.props.onUpdateQuestion){
                        this.props.onUpdateQuestion(indexQuestion, null, fileBase64);
                    }
                }
                
            };
        }


    }

    handleUploadImageOption(event: any, indexQuestion: any, indexOption: any) {
        
        let file = event.target.files[0];

        if (file) {

            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                let fileBase64 = reader.result as string;

                if ((fileBase64 === "") || !(fileBase64.startsWith("data:image/png;base64,") || fileBase64.startsWith("data:image/jpeg;base64,") || fileBase64.startsWith("data:image/jpg;base64,") || fileBase64.startsWith("data:image/gif;base64,"))) {
                    
                    // Exibir erro 

                    (document.getElementById("input-file") as HTMLInputElement).value = "";
                    return;

                } else {
                    if(this.props.onUpdateChoice){
                        this.props.onUpdateChoice(indexOption, indexQuestion, null, undefined, fileBase64);
                    }
                }
                
            };
        }


    }
    
 }

const questionContainerWithTranslation = withTranslation()(QuestionContainer);
export default questionContainerWithTranslation;