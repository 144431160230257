import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { UxUtils } from "../../utility/UxUtils";
// import "./Settings.scss";
// import { DateTimePickerView } from "./DateTime/DateTimePickerView";
// import { RadioGroupMobile } from "../RadioGroupMobile";
import { ProgressState, PagesTraining, Visibility, VisibilityResultTraining, VisibilityTraining } from "../../utility/SharedEnum";
import { Flex, Text, ChevronStartIcon, Checkbox, RadioGroup, FlexItem } from "@fluentui/react-northstar";
import { DateTimePickerView } from "../Shared/DateTime/DateTimePickerView";

export interface ISettingsTrainingComponentProps {
    dueDate: number;
    locale?: string;
    showCorrectAnswer: boolean;
    allowMultipleAttempt: boolean;
    visibilityResult?: VisibilityResultTraining;
    itemsVisibility?: VisibilityTraining;
    rowsVisibility?: VisibilityTraining;
    itemsEditable?: boolean;
    canUserAddMultipleItems?: boolean;
    stringsLocalize?: any;
    onChange?: (props: ISettingsTrainingComponentProps) => void;
    onMount?: () => void;
    onBack?: () => void;    
}

/**
 * <Settings> Settings component of creation view of poll
*/
class SettingsTraining extends React.Component<ISettingsTrainingComponentProps, any> {

    private settingProps: any = null;
    constructor(props: ISettingsTrainingComponentProps) {
        super(props);
    }

    componentDidMount() {
        if (this.props.onMount) {
            this.props.onMount();
        }
    }

    render() {
        this.settingProps = {
            dueDate: this.props.dueDate,
            locale: this.props.locale,
            showCorrectAnswer: this.props.showCorrectAnswer,
            allowMultipleAttempt: this.props.allowMultipleAttempt
        };

        return (
            <Flex className="body-container" column gap="gap.medium">
                {this.renderSettings()}
                {this.props.onBack && this.getBackElement()}
            </Flex>
        );
    }

    /**
     * Common to render settings view for both mobile and web
     */
    private renderSettings() {
        return (
            <Flex column>
                {this.renderDueBySection()}
                
                <Flex column gap="gap.medium">
                    {this.renderShowCorrectAnswer()}
                    {this.renderAllowMultipleAttempt()}
                </Flex>
                
            </Flex>
        );
    }

    /**
     * Rendering due date section for settings view
     */
    private renderDueBySection() {
        // handling mobile view differently
        let className = "settings-indentation";
        return (
            <Flex className="settings-item-margin" role="group" aria-label={this.props.stringsLocalize("dueBy")} column gap="gap.smaller">
                <label className="settings-item-title">{this.props.stringsLocalize("dueBy")}</label>
                <div className={className}>
                    <DateTimePickerView
                        minDate={new Date()}
                        locale={this.props.locale}
                        value={new Date(this.props.dueDate)}
                        placeholderDate={this.props.stringsLocalize("datePickerPlaceholder")}
                        placeholderTime={this.props.stringsLocalize("timePickerPlaceholder")}
                        onSelect={(date: Date) => {
                            if(this.props.dueDate){
                               this.settingProps.dueDate = date.getTime();
                               if(this.props.onChange){
                                    this.props.onChange(this.settingProps);
                               }                                
                            }                            
                        }} />
                </div>
            </Flex>
        );
    }

     /**
     * Rendering result show correct answer radio button
     */
    private renderShowCorrectAnswer() {
       
        return (
            <Flex column>
                <FlexItem>
                    <Checkbox 
                        label={this.props.stringsLocalize("showCorrectAnswer")} 
                        checked={this.props?.showCorrectAnswer} 
                        onChange={(event: any, data: any) => {                        
                            this.settingProps.showCorrectAnswer = data.checked;
                            
                            if(this.props.onChange){
                                this.props.onChange(this.settingProps);
                            }
                        }}
                    />
                </FlexItem>
                <FlexItem>
                    <Text content={this.props.stringsLocalize("answerCannotChange")} />
                </FlexItem>                
            </Flex>
        );
    }

     /**
     * Rendering result show correct answer radio button
     */
      private renderAllowMultipleAttempt() {
       
        return (
            <Flex column>
                <FlexItem>
                    <Checkbox 
                        label={this.props.stringsLocalize("allowMultipleAttemptKey")} 
                        checked={this.props?.allowMultipleAttempt} 
                        onChange={(event: any, data: any) => {                        
                            this.settingProps.allowMultipleAttempt = data.checked;
                            
                            if(this.props.onChange){
                                this.props.onChange(this.settingProps);
                            }
                        }}
                    />
                </FlexItem>
                <FlexItem>
                    <Text content={this.props.stringsLocalize("assigneeTakeMultipleTraining")} />
                </FlexItem>                
            </Flex>
        );
    }

    /**
     * Footer for settings view
     */
    private getBackElement() {
        return (
            <Flex className="footer-layout" gap={"gap.smaller"}>
                <Flex vAlign="center" className="pointer-cursor" {...UxUtils.getTabKeyProps()}
                    onClick={() => {
                        if(this.props.onBack){
                            this.props.onBack();
                        }
                    }}
                >
                    <ChevronStartIcon xSpacing="after" size="small" />
                    <Text content={this.props.stringsLocalize("Back")} />
                </Flex>
            </Flex>
        );
    }

 }



const settingsTrainingWithTranslation = withTranslation()(SettingsTraining);
export default settingsTrainingWithTranslation;