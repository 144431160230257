export enum NotificationsType {
   Poll = "Poll",
   Quiz = "Quiz",
   Training = "Training",
   // Survey = "Survey",
   Message = "Message",
}

export enum CardTemplates {
    Poll = "Poll",
    Quiz = "Quiz",
    Training = "Training",
    // Survey = "Survey",
    Message = "Message",
    MessageVideo = "MessageVideo"
}

export enum NewNotificationsTypePages {
   Main = "Main",
   NewNotification = "NewNotification"
}

export enum MediaType {
    Image = "Image",
    Video = "Video",
}

export enum ProgressState {
    NotStarted,
    InProgress,
    Partial,
    Completed,
    Failed
}

/**
 * Enum for four main component of creation view (main page, questions page, preview page and settings page)
 */
 export enum PagesTraining {
   Main,
   Settings,
   ContentContainer,
   AddContentContainer,
   Preview
}

export enum TypesContentsTraining {
   Text,
   Image,
   Video,
   Document,
   Quiz
}

/**
 * This enum represents the different types of visibility result training
 */
 export enum VisibilityResultTraining {
   OnlyMe,
   Everyone
}

/**
   * This enum represents the different types of visibility setting.
   * @category Data Model
   */
 export enum VisibilityTraining {
   /**
    * Only visible to sender/creator of the action.
    */
   Sender = "Sender",
   /**
    * Visible to every member in every action subscriptions.
    */
   All = "All"
}

/**
 * Enum for four main component of creation view (main page, questions page, preview page and settings page)
 */
 export enum PagesQuiz {
    Audience,
    Main,
    Settings,
    QuestionContainer,
    Preview,
    Start,
}

/**
 * This enum represents the different types of visibility result quiz
 */
 export enum VisibilityResultQuiz {
    OnlyMe,
    Everyone
}

/**
   * This enum represents the different types of visibility setting.
   * @category Data Model
   */
 export enum VisibilityQuiz {
    /**
     * Only visible to sender/creator of the action.
     */
    Sender = "Sender",
    /**
     * Visible to every member in every action subscriptions.
     */
    All = "All"
}

/**
 * Enum for three main component of creation view (main page, preview page and settings page)
 */
 export enum PagesPoll {
    Audience,
    Main,
    Settings
}

/**
 * Enum to define three component of summary view (main page, responder and non-responder tab)
 */
 export enum ViewTypeSummary {
   Main,
   ResponderView,
   NonResponderView,
   UserResponseView
}

/**
   * This enum represents the different types of visibility setting.
   * @category Data Model
   */
 export enum Visibility {
    /**
     * Only visible to sender/creator of the action.
     */
    Sender = "Sender",
    /**
     * Visible to every member in every action subscriptions.
     */
    All = "All"
}

/**
   * This enum represents the different types of data contained by an action data-column.
   * @category Data Model
   */
 export enum ActionDataColumnValueType {
    /**
     * Multi-choice type, only one option can be selected.
     */
    SingleOption = "SingleOption",
    /**
     * Multi-choice type, any number of options can be selected.
     */
    MultiOption = "MultiOption",
    /**
     * Text type, upto 4000 characters.
     */
    Text = "Text",
    /**
     * Large text type, for more than 4000 characters.
     */
    LargeText = "LargeText",
    /**
     * Number type.
     */
    Numeric = "Numeric",
    /**
     * Location type, with latitude (lt), longitude (lg), name (n).
     */
    Location = "Location",
    /**
     * Epoch timestamp type.
     */
    DateTime = "DateTime",
    /**
     * Date type, with "YYYY-MM-DD" format.
     */
    Date = "Date",
    /**
     * User ID type.
     */
    UserId = "UserId",
    /**
     * AttachmentList type, with list of attachments.
     */
    AttachmentList = "AttachmentList"
}

 /**
   * This enum represents different types of action statuses.
   * @category Data Model
   */
  export enum ActionStatus {
   /**
    * Default action status.
    */
   Active = "Active",
   /**
    * Beyond expiry time an action will automatically be expired.
    * No further data-rows will be allowed after this.
    */
   Expired = "Expired",
   /**
    * An action can be closed manually.
    * No further data-rows will be allowed after this.
    */
   Closed = "Closed"
}