import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { UxUtils } from "../../utility/UxUtils";
import "./Settings.scss";
import { DateTimePickerView } from "../Shared/DateTime/DateTimePickerView";
// import { RadioGroupMobile } from "../RadioGroupMobile";
import { ProgressState, PagesPoll, Visibility } from "../../utility/SharedEnum";
import { Flex, Text, ChevronStartIcon, RadioGroup } from "@fluentui/react-northstar";


export interface ISettingsComponentProps {
    dueDate: string;
    locale?: string;
    stringsLocalize?: any;
    onChange?: (props: ISettingsComponentProps) => void;
    onMount?: () => void;  
}

export interface ISettings {
    dueDate: string;
    locale?: string;
}

const intervalMinutes: number = 30;

/**
 * <Settings> Settings component of creation view of poll
 */
 class Settings extends React.Component<ISettingsComponentProps, any> {
    private settingProps: ISettings;
    constructor(props: ISettingsComponentProps) {
        super(props);

        this.settingProps = {
            dueDate: this.props.dueDate,
            locale: this.props.locale
        };
    }

    componentDidMount() {
        if (this.props.onMount) {
            this.props.onMount();
        }
    }

    render() {
        return (
            <Flex column>
                {this.renderDueBySection()}
            </Flex>
        );
    }

    /**
     * Rendering due date section for settings view
     */
    private renderDueBySection() {
        // handling mobile view differently
        let className = "settings-indentation";
        return (
            <Flex className="settings-item-margin" role="group" aria-label={this.props.stringsLocalize("dueBy")} column gap="gap.smaller">
                <label className="settings-item-title">{this.props.stringsLocalize("dueBy")}</label>
                <div className={className}>
                    <DateTimePickerView
                        minDate={new Date()}
                        intervalMinutes={intervalMinutes}
                        locale={this.props.locale}
                        value={new Date(this.props.dueDate)}
                        placeholderDate={this.props.stringsLocalize("datePickerPlaceholder")}
                        placeholderTime={this.props.stringsLocalize("timePickerPlaceholder")}
                        onSelect={(date: Date) => {
                            if(this.props.dueDate){
                               this.settingProps.dueDate = date.toISOString();
                               if(this.props.onChange){
                                    this.props.onChange(this.settingProps);
                               }                                
                            }                            
                        }} />
                </div>
            </Flex>
        );
    }

    private getVisibilityItems(resultsVisibleToAllLabel: string, resultsVisibleToSenderLabel: string) {
        return [
            {
                key: "1",
                label: resultsVisibleToAllLabel,
                value: Visibility.All,
                className: "settings-radio-item"
            },
            {
                key: "2",
                label: resultsVisibleToSenderLabel,
                value: Visibility.Sender,
                className: "settings-radio-item-last"
            },
        ];
    }
}

const settingsWithTranslation = withTranslation()(Settings);
export default settingsWithTranslation;