import * as React from "react";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { initializeIcons } from '@fluentui/react';
import { withTranslation, WithTranslation } from "react-i18next";
import { TFunction } from "i18next";
import moment, { locale } from "moment";
import { Utils } from "../../../utility/Utils";
import { UxUtils } from "../../../utility/UxUtils";
import { getAnswerDataQuizAggregratedByUsers } from '../../../apis/messageListApi';
import { Flex, FlexItem, List, Loader, FocusZone, Text, Avatar } from "@fluentui/react-northstar";
import {
  ChevronEndIcon
} from '@fluentui/react-icons-northstar';
import { ActionStatus, PagesQuiz, ViewTypeSummary } from "../../../utility/SharedEnum";
import { Any } from "adaptive-expressions/lib/builtinFunctions";

export interface ResponderViewState {
    loader: boolean;
    selectedIndex: number;
    aggregrateResultsByUsers: any;
}

export interface ResponderViewProps extends WithTranslation {
    notificationId: number;
    quizData: any;
    selectedAnswerUser: string;
    currentView: ViewTypeSummary;
    setSelectedUserAnswer: (userAnswer: number) => void;
    setCurrentView: (value:ViewTypeSummary) => void;
}

class ResponderViewQuiz extends React.Component<ResponderViewProps, ResponderViewState> {

    private localize: TFunction;
   
    constructor(props: ResponderViewProps) {
        super(props);
        
        this.localize = this.props.t;
        
        this.state = {
            loader: true,
            selectedIndex: -1,
            aggregrateResultsByUsers: null,
        }
    }

    public componentDidMount() {
        this.getAnswerDataQuizAggregratedByUsers(this.props.notificationId);
    }

    public render() {
        
        if (this.state.loader){
            return <Loader />;
        }
        else if (this.props.currentView == ViewTypeSummary.ResponderView) {
            return (
              <FocusZone className="zero-padding" isCircularNavigation={true}>
                  <Flex column className="list-container" gap="gap.small">
                      <List 
                        selectable
                        selectedIndex={this.state.selectedIndex}
                        onSelectedIndexChange={(e, newProps: any) => {
                          
                          this.setState({
                            selectedIndex: newProps?.selectedIndex,
                          })

                          if(this.props.setSelectedUserAnswer){
                              this.props.setSelectedUserAnswer(newProps?.items[newProps.selectedIndex].key);
                          }

                          if(this.props.setCurrentView){
                              this.props.setCurrentView(ViewTypeSummary.UserResponseView);
                          }
                        }}
                        items={this.getItems()} 
                      />
                  </Flex>
              </FocusZone>
            );
        }
        else if (this.props.currentView == ViewTypeSummary.UserResponseView) {

            let dueDate = this.getTextHeaderByStatus();

            return (
              <Flex column>
                  <FlexItem>
                      <Text weight="bold" className="primary-text">{this.props.quizData?.quiz?.title}</Text>
                  </FlexItem>
                  <FlexItem>
                      <Text weight="semibold" className="primary-text">{dueDate}</Text>
                  </FlexItem>
              </Flex>
            );
        }
        
    }

    public getTextHeaderByStatus() {
        
      if(this.props.quizData){
          if(this.props.quizData.quiz.status == ActionStatus.Active) {
              var dueDate = moment(this.props.quizData.quiz.settings.dueDate).format('llll');
              return Utils.getStringLocalizer(this.localize("dueByDate"), [dueDate]);
          }
          else if(this.props.quizData.quiz.status == ActionStatus.Closed){
              // Validar com a data de FECHAMENTO MANUAL da Votação
              var dueDate = moment(this.props.quizData.quiz.settings.dueDate).format('llll');
              return Utils.getStringLocalizer(this.localize("ClosedOn"), [dueDate]);
          }
          else if(this.props.quizData.quiz.status == ActionStatus.Expired){
              var dueDate = moment(this.props.quizData.quiz.settings.dueDate).format('llll');
              return Utils.getStringLocalizer(this.localize("ExpiredOn"), [dueDate]);
          }
      }      
  }

    public getItems() {
      let items:any = [];
      
      this.state.aggregrateResultsByUsers.forEach((aggregrateResult: any, index: number) => {
        
        items.push(
          {
            key: aggregrateResult.userId,
            media: (
              <Avatar
                image={`data:image/jpeg;base64,${aggregrateResult.userPhoto}`}
              />
            ),
            header: (aggregrateResult.userName),
            headerMedia: (
              <Flex>
                <Flex column>
                  <FlexItem>
                    <Text content={moment(aggregrateResult.answerDate).format('lll')} ></Text>
                  </FlexItem>
                  <FlexItem>
                    <Text styles={{textAlign: "right"}} content={Utils.getStringLocalizer(this.localize("score"), ...[Math.round(aggregrateResult.score)])}></Text>
                  </FlexItem>
                </Flex>
                <Flex styles={{paddingLeft: ".5rem"}}>
                  <ChevronEndIcon size="medium" />
                </Flex>
              </Flex>
            ),
          }
        )
      });

      return items;
    }

    private getAnswerDataQuizAggregratedByUsers = async (id: number) => {
      try {
          const response = await getAnswerDataQuizAggregratedByUsers(id);
          const aggregrateResultsByUsers = response.data.aggregrateResultsByUsers;
          
          this.setState({
              aggregrateResultsByUsers: aggregrateResultsByUsers,
              loader: false
          })
          
      } catch (error) {
          return error;
      }
  }
}

const responderViewQuizWithTranslation = withTranslation()(ResponderViewQuiz);
export default responderViewQuizWithTranslation;