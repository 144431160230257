import * as React from "react";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { initializeIcons } from '@fluentui/react';
import { withTranslation, WithTranslation } from "react-i18next";
import { TFunction } from "i18next";
import moment, { locale } from "moment";
import {  Button, Flex, FlexItem, Image, Input, Segment, Text, TextArea, Tooltip } from "@fluentui/react-northstar";

export interface ITextContent {
    title?: string;
    titleError?: boolean;
    description?: string;
}

export interface TextContentTrainingProps extends WithTranslation{
    content?: ITextContent;
    onUpdateTextContent?: (title?:string, description?: string) => void;
}

class TextContentTraining extends React.Component<TextContentTrainingProps, any> {

    private localize: TFunction;
    constructor(props: TextContentTrainingProps) {
        super(props);
        
        initializeIcons();
        this.localize = this.props.t;
    }

    render(){
        return(
            <Flex column gap="gap.medium">
                <FlexItem>
                    <div className="inputField">
                        <Flex space="between">
                            <FlexItem>
                                <Text className="label-field" content={this.localize("titleContent")}></Text>
                            </FlexItem>
                            <FlexItem push>
                                <Text className={this.props.content?.titleError ? "text-alert label-field" : "text-alert label-field display-none"} content={this.localize("required")}></Text>
                            </FlexItem>
                        </Flex>

                        <Input
                            fluid
                            className={this.props.content?.titleError ? "inputField input-alert" : "inputField"}
                            placeholder={this.localize("addTitlePlaceholder")}
                            value={this.props.content?.title}
                            onChange={(e: any, data: any) => {
                                if(this.props.onUpdateTextContent){
                                    this.props.onUpdateTextContent(data.value);
                                }
                            }}
                        />
                    </div>
                </FlexItem>
                <FlexItem>
                    <div className="inputField">
                        <Text className="label-field" content={this.localize("descriptionContent")}></Text>
                        <TextArea
                            fluid
                            styles={{
                                height: "200px"
                            }}
                            placeholder={this.localize("addDescriptionPlaceholder")}
                            value={this.props.content?.description}
                            onChange={(e: any, data: any) => {
                                if(this.props.onUpdateTextContent){
                                    this.props.onUpdateTextContent(null as any, data.value);
                                }
                            }}
                        >
                        </TextArea>
                    </div>
                </FlexItem>
            </Flex>            
        );
    }
}

const textContentTrainingWithTranslation = withTranslation()(TextContentTraining);
export default textContentTrainingWithTranslation;