import { CardTemplates, MediaType } from "../utility/SharedEnum";

export function getCardTemplateUrl(cardTemplate: string) {

    if (cardTemplate == CardTemplates.Message) {
        return "/newMessage/";
    }
    else if (cardTemplate == CardTemplates.MessageVideo) {
        return "/newMessageVideo/";
    }
    else if (cardTemplate == CardTemplates.Poll) {
        return "/newPoll/";
    }
    else if (cardTemplate == CardTemplates.Quiz) {
        return "/newQuiz/";
    }
    // else if(cardTemplate == CardTemplates.Survey){
    //     return "/newSurvey/";
    // }
    else if (cardTemplate == CardTemplates.Training) {
        return "/newTraining/";
    }
}

export function getCardTemplateTaksModuleSize(cardTemplate: string) {

    if (cardTemplate == CardTemplates.Message) {
        return {
            height: 600,
            width: 1000,
        }
    }
    else if (cardTemplate == CardTemplates.MessageVideo) {
        return {
            height: 600,
            width: 1000,
        }
    }
    else if (cardTemplate == CardTemplates.Poll) {
        return {
            height: 600,
            width: 1000,
        }
    }
    else if (cardTemplate == CardTemplates.Quiz) {
        return {
            height: 600,
            width: 1000,
        }
    }
    // else if(cardTemplate == CardTemplates.Survey){
    //     return {
    //         height:700,
    //         width:600,
    //     }
    // }
    if (cardTemplate == CardTemplates.Training) {
        return {
            height: 700,
            width: 600,
        }
    }
    else {
        return {
            height: 600,
            width: 1000,
        }
    }
}

export function getCardTemplateIcon(cardTemplate: string) {

    if (!cardTemplate || cardTemplate == CardTemplates.Message) {
        return CardTemplates.Message.toLowerCase() + "-icon";
    }
    else if (cardTemplate == CardTemplates.MessageVideo) {
        return MediaType.Video.toLowerCase() + "-icon";
    }
    else if (cardTemplate == CardTemplates.Poll) {
        return CardTemplates.Poll.toLowerCase() + "-icon";
    }
    else if (cardTemplate == CardTemplates.Quiz) {
        return CardTemplates.Quiz.toLowerCase() + "-icon";
    }
    // else if(cardTemplate == CardTemplates.Survey){
    //     return CardTemplates.Survey.toLowerCase() + "-icon";
    // }
    else if (cardTemplate == CardTemplates.Training) {
        return CardTemplates.Training.toLowerCase() + "-icon";
    }
}

export function getCardTemplateImage(cardTemplate: string) {

    if (!cardTemplate || cardTemplate == CardTemplates.Message) {
        return CardTemplates.Message.toLowerCase() + "-img";
    }
    else if (cardTemplate == CardTemplates.MessageVideo) {
        return MediaType.Video.toLowerCase() + "-img";
    }
    else if (cardTemplate == CardTemplates.Poll) {
        return CardTemplates.Poll.toLowerCase() + "-img";
    }
    else if (cardTemplate == CardTemplates.Quiz) {
        return CardTemplates.Quiz.toLowerCase() + "-img";
    }
    // else if(cardTemplate == CardTemplates.Survey){
    //     return CardTemplates.Survey.toLowerCase() + "-img";
    // }
    else if (cardTemplate == CardTemplates.Training) {
        return CardTemplates.Training.toLowerCase() + "-img";
    }
}