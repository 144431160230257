import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { TFunction } from "i18next";
import moment, { locale } from "moment";
import "./summaryQuiz.scss";
import { ActionStatus, ActionDataColumnValueType, ViewTypeSummary } from "../../../utility/SharedEnum";
import {
    AcceptIcon, Checkbox, ChevronStartIcon, Flex, FlexItem, Image, Text, Divider, RadioGroup
} from "@fluentui/react-northstar";
import { getQuizAnswersDataByUser } from '../../../apis/messageListApi';
import { ShimmerContainer } from "../../Shared/ShimmerLoader/shimmerContainer";
import { IQuiz } from "../../../interfaces/IQuiz";
import { Utils } from "../../../utility/Utils";
import { UxUtils } from "../../../utility/UxUtils";


export interface UserResponseViewQuizState { 
    answerUserData: any;
    loaderAnswerUser: boolean;
}

export interface UserResponseViewQuizProps extends WithTranslation {
    notificationId: number;
    selectedAnswerUser: string;
    currentView: ViewTypeSummary;
    setCurrentView: (value:any) => void;
}

class UserResponseViewQuiz extends React.Component<UserResponseViewQuizProps, UserResponseViewQuizState> {

    private localize: TFunction;    

    constructor(props: UserResponseViewQuizProps) {
        super(props);
        
        this.localize = this.props.t;

        this.state = {
            answerUserData: null,
            loaderAnswerUser: true,
        }
    }
    
    public componentDidMount() {
        this.getQuizAnswersDataByUser(this.props.notificationId, this.props.selectedAnswerUser);
    }

    private getQuizAnswersDataByUser = async (id: number, aadId: string) => {
        try {
            const response = await getQuizAnswersDataByUser(id, aadId);
            const answerData = response.data;
            
            this.setState({
                answerUserData: answerData,
                loaderAnswerUser: false
            })

            return answerData;
            
        } catch (error) {
            return error;
        }
    }
    
    public renderHeaderContainer() {

        let dueDate = this.getTextHeaderByStatus();
    
        return (
            <Flex>  
                {this.state.loaderAnswerUser && 
                    <FlexItem styles={{width: "100%"}}>
                        <ShimmerContainer lines={2} width={["50%"]} showShimmer={this.state.loaderAnswerUser}>
                            <Text weight="bold" className="primary-text">Due Date</Text>                        
                        </ShimmerContainer>
                    </FlexItem>
                }              

                {!this.state.loaderAnswerUser &&
                    <Flex space="between" styles={{width: "100%"}}>
                        <Flex column>
                            <FlexItem>
                                <Text weight="bold" className="primary-text">{this.state.answerUserData?.quizData?.title}</Text>
                            </FlexItem>
                            <FlexItem>
                                <Text>{dueDate}</Text>
                            </FlexItem>
                        </Flex>     
                    </Flex>                    
                }          
            </Flex>
        )
    }

    public getTextHeaderByStatus() {
        
        if(this.state.answerUserData?.quizData){
            if(this.state.answerUserData?.quizData?.status == ActionStatus.Active) {
                var dueDate = moment(this.state.answerUserData?.quizData?.settings.dueDate).format('llll');
                return Utils.getStringLocalizer(this.localize("dueByDate"), [dueDate]);
            }
            else if(this.state.answerUserData?.quizData?.status == ActionStatus.Closed){
                // Validar com a data de FECHAMENTO MANUAL da Votação
                var dueDate = moment(this.state.answerUserData?.quizData?.settings.dueDate).format('llll');
                return Utils.getStringLocalizer(this.localize("ClosedOn"), [dueDate]);
            }
            else if(this.state.answerUserData?.quizData?.status == ActionStatus.Expired){
                var dueDate = moment(this.state.answerUserData?.quizData?.settings.dueDate).format('llll');
                return Utils.getStringLocalizer(this.localize("ExpiredOn"), [dueDate]);
            }
        }      
    }

    private renderUserResponse = (): JSX.Element => {
        
        let userScore = Math.round(this.state.answerUserData?.score);
        
        return ( 
            <Flex column gap="gap.small">

                <Divider className="divider" /> 

                <FlexItem>
                    <ShimmerContainer lines={1} showShimmer={this.state.loaderAnswerUser}>
                        <Text weight="bold" size="medium" content={Utils.getStringLocalizer(this.localize("score"), ...[userScore])}></Text>
                    </ShimmerContainer>
                </FlexItem>

                <Divider className="divider" /> 

                <FlexItem>
                    {this.renderQuestions()}
                </FlexItem>               
            </Flex>
            
        );
    }

    private renderQuestions = (): JSX.Element => {

        let questions: any[] = [];

        this.state.answerUserData?.quizData?.questions.forEach((question: any, index: any) => { 
            
            let aggregrateResultQuestion = this.state.answerUserData?.aggregrateResults[index];

            questions.push(
                <Flex column gap="gap.medium">
                     <ShimmerContainer lines={5} showShimmer={this.state.loaderAnswerUser}>
                        <Flex space="between">
                            <FlexItem>
                                <Text weight="bold" size="medium" content={ `${this.localize("question")} # ${(index + 1)}` } ></Text>
                            </FlexItem>
                            <FlexItem>
                                <Text 
                                    weight="semibold" 
                                    size="medium" 
                                    content={aggregrateResultQuestion.isCorrectAnswer ?  this.localize("correct") :this.localize("incorrect")}
                                    className={aggregrateResultQuestion.isCorrectAnswer ?  "correctAnswer" : "incorrectAnswer"}
                                />
                            </FlexItem>
                        </Flex>                        
                        <FlexItem>
                            <Text size="large" content={question.text} ></Text>
                        </FlexItem>
                        <FlexItem>
                            {this.renderOptions(index, aggregrateResultQuestion.isCorrectAnswer)}
                        </FlexItem>
                     </ShimmerContainer>                    
                </Flex>
            );
        });

        return (
            <React.Fragment>
                {questions}
            </React.Fragment>
        );
    }

    public getItemsRadioGroup = (indexQuestion: number, isCorrectUserOption: any) => {
        let items: any[] = [];

        this.state.answerUserData?.quizData?.questions[indexQuestion].options.forEach((opt: any, index: number) => {
            
            let isUserResponse = this.state.answerUserData?.answerData?.answers[indexQuestion].answer == opt.name;
            
            items.push(
                {
                    className: `item-option ${isUserResponse ? (isCorrectUserOption ? "optionCorrect" : "optionIncorrect") : ""}`,
                    name: `${indexQuestion}`,
                    key: `${indexQuestion}-${opt.name}`,
                    value: opt.name,
                    disabled: true,
                    label: (
                        <Flex column>
                            {opt.image &&
                                <Flex className="questions-img-box">
                                    <FlexItem>
                                        <Image className="questions-img-preview" src={opt.image}></Image>
                                    </FlexItem>  
                                </Flex>
                            }
                            <Flex>
                                <FlexItem>
                                    <Text content={opt.text} />
                                </FlexItem>
                                <FlexItem push styles={{ display: opt.correctOption ? "block" : "none" }}>
                                    <AcceptIcon className="correctAnswer"></AcceptIcon>
                                </FlexItem>
                            </Flex>                            
                        </Flex>     
                    ),
                    variables: {
                        indicatorBackgroundColorChecked: isUserResponse ? "#6264a7" : "",
                    }
                }
            )
        });

        return (
            <RadioGroup
                className="quiz-options"
                vertical
                checkedValue={this.state.answerUserData?.answerData?.answers[indexQuestion].answer}
                items={items}
            />
        );
    }

    public getItemsCheckBox = (indexQuestion: number, isCorrectUserOption: any) => {

        let items: any[] = [];
        this.state.answerUserData?.quizData?.questions[indexQuestion].options.forEach((opt: any, index: number) => {

            let isUserResponse = this.state.answerUserData?.answerData?.answers[indexQuestion].answer.includes(opt.name);
            
            items.push(
                <Checkbox
                    className={isUserResponse ? (isCorrectUserOption ? "optionCorrect" : "optionIncorrect") : ""}
                    id={opt.name}
                    checked={isUserResponse}
                    disabled={true}
                    label={
                        <Flex column>
                            {opt.image &&
                                <Flex className="questions-img-box">
                                    <FlexItem>
                                        <Image className="questions-img-preview" src={opt.image}></Image>
                                    </FlexItem>  
                                </Flex>
                            } 
                            <Flex>
                                <FlexItem>
                                    <Text content={opt.text}></Text>
                                </FlexItem>
                                 <FlexItem push styles={{ display: opt.correctOption ? "block" : "none" }}>
                                    <AcceptIcon className="correctAnswer"></AcceptIcon>
                                </FlexItem>    
                            </Flex>
                        </Flex>
                    }                       
                />
            )
        });

        return (
            <React.Fragment>
                {items}
            </React.Fragment>
        );
    }

    private renderOptions = (indexQuestion: any, isCorrectUserOption: any): JSX.Element => {

        let valueTypeQuestion = this.state.answerUserData?.quizData?.questions[indexQuestion].valueType;

        if (valueTypeQuestion == ActionDataColumnValueType.SingleOption) {
            return (
                <Flex>
                    {this.getItemsRadioGroup(indexQuestion, isCorrectUserOption)}
                </Flex>                
            );            
        }
        else {
            return (
                <Flex column className="options-checkbox">
                    <FlexItem>
                        {this.getItemsCheckBox(indexQuestion, isCorrectUserOption)}    
                    </FlexItem>                    
                </Flex>
            );
        };
    }

    public renderFooterView(){
        return (
            <Flex className="footer-layout tab-view-footer" gap={"gap.smaller"}>
                <Flex
                    vAlign="center"
                    className="pointer-cursor"
                    {...UxUtils.getTabKeyProps()}
                    onClick={() => {
                        this.props.setCurrentView(ViewTypeSummary.ResponderView);
                    }}
                >
                    <ChevronStartIcon xSpacing="after" size="small" />
                    <Text content={this.localize("Back")} />
                </Flex>
            </Flex>
        );
    }


    public render = () : JSX.Element => {
        
        return (
            <React.Fragment>
                <Flex 
                    gap="gap.medium" 
                    column 
                    className="body-container"
                    id="bodyContainer"
                >             
                    {this.renderHeaderContainer()}   
                    {this.renderUserResponse()}
                </Flex>
                {this.renderFooterView()}
            </React.Fragment>           
        )
    }

}

const userResponseViewQuizWithTranslation = withTranslation()(UserResponseViewQuiz);
export default userResponseViewQuizWithTranslation;