import * as React from "react";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { initializeIcons } from '@fluentui/react';
import { withTranslation, WithTranslation } from "react-i18next";
import { TFunction } from "i18next";
import moment, { locale } from "moment";
import { Text } from "@fluentui/react-northstar";
import "./summary.scss";
import { getSentNotification, getAnswerDataPoll, updateContentJsonSenttNotification } from '../../../apis/messageListApi';
import { ErrorView } from "./ErrorView/errorView";
import { ActionStatus, ViewTypeSummary } from "../../../utility/SharedEnum";
import SummaryView from "./summaryView";
import TabView from "./tabView";
import { IPoll, IPollData, IOptions } from "../../../interfaces/IPoll";
import { Utils } from "../../../utility/Utils";


export interface SummaryPagePollState {
    currentView: ViewTypeSummary,
    pollData: IPoll,
    notificationData: any;
    loaderNotifications: boolean;
    answerData: any;
    loaderAnswers: boolean;
    openDialogChangeDueDate: boolean;
    openDialogClosePoll: boolean;
    openDialogDeletePoll: boolean;
}

export type SummaryPagePollProps = {} & RouteComponentProps & WithTranslation;

class SummaryPagePoll extends React.Component<SummaryPagePollProps, SummaryPagePollState> {

    private localize: TFunction;

    constructor(props: SummaryPagePollProps) {
        super(props);
        
        this.localize = this.props.t;

        this.state = {
            currentView: ViewTypeSummary.Main,
            openDialogChangeDueDate: false,
            openDialogClosePoll: false,
            openDialogDeletePoll: false,
            notificationData: null,
            loaderNotifications: true,
            answerData: null,
            loaderAnswers: true,
            pollData: {
                poll: {
                    status: "",
                    imagePoll: "",
                    question: {
                        name: "0",
                        text: "",
                        options: [
                            {
                                text: ""
                            },
                            {
                                text: ""
                            }
                        ]
                    },
                    rowCreatorCount: 0,
                    settings: {
                        dueDate: Utils.getDefaultExpiry(7).toISOString(), // default due date for poll is one week
                    },
                },
                adaptiveCardJson: "",
            }
        }
    }

    public async componentDidMount(){
        
        let id = (this.props.match.params as any).id;

        if (id) {
            this.getItem(id);
            this.getAnswerDataPoll(id);
        }
    }

    public render() {                
        return this.getView();       
    }

    /**
     * Method to return the view based on the user selection
     */
    private getView() {
        
        let currentView = this.state.currentView;
        
        if (currentView == ViewTypeSummary.Main) {
            return (
                <SummaryView                    
                    notificationData={this.state.notificationData}
                    pollData={this.state.pollData}
                    answerData={this.state.answerData}
                    showShimmer={this.state.loaderNotifications || this.state.loaderAnswers}
                    openDialogChangeDueDate={this.state.openDialogChangeDueDate}
                    openDialogClosePoll={this.state.openDialogClosePoll}
                    openDialogDeletePoll={this.state.openDialogDeletePoll}
                    setCurrentView={(value: ViewTypeSummary) => {
                        this.setCurrentView(value)
                    }}
                    updateDueDate={(value: any) => {
                        this.updateDueDate(value);
                    }}
                    changeDialogUpdateDueDate={(value: any) => {
                        this.changeDialogUpdateDueDate(value);     
                    }}
                    updateClosePoll={(value: any) => {
                        this.updateClosePoll(value);
                    }}
                    changeDialogUpdateClosePoll={(value: any) => {
                        this.changeDialogUpdateClosePoll(value);
                    }}
                    changeDialogDeletePoll={(value: any) => {
                        this.changeDialogDeletePoll(value);
                    }}
                />
            );
        
        } else if (currentView == ViewTypeSummary.ResponderView || currentView == ViewTypeSummary.NonResponderView) {
            return ( 
                <TabView 
                    notificationData={this.state.notificationData}
                    pollData={this.state.pollData}
                    answerData={this.state.answerData}
                    currentView={this.state.currentView}
                    setCurrentView={(value: ViewTypeSummary) => {
                        this.setCurrentView(value)
                    }}
                />
            )
        }
    }

    public setCurrentView(currentView: ViewTypeSummary) {
         this.setState({
            currentView: currentView
        });
    }

    public updateDueDate = async (newDueDate: any) => {
        this.setState({
            pollData: {    
                ...this.state.pollData,
                poll: {
                    ...this.state.pollData.poll,
                    settings: {
                        dueDate: newDueDate
                    }
                }            
            }
        }, async () => {
            this.updateContentJSON().then(() => {
                this.changeDialogUpdateDueDate(false);
            });
        });        
   }

   private changeDialogUpdateDueDate(open: boolean) {
        this.setState({
            openDialogChangeDueDate: open
        })
    }

    private changeDialogUpdateClosePoll(open: boolean) {         
        this.setState({
            openDialogClosePoll: open
        })
    }

    private changeDialogDeletePoll(open: boolean) {        
        this.setState({
            openDialogDeletePoll: open
        })
    }

    public updateClosePoll(status: any) {
        this.setState({
            pollData: {    
                ...this.state.pollData,
                poll: {
                    ...this.state.pollData.poll,
                    status: status
                }            
            }
        }, () => {
            this.updateContentJSON().then(() => {
                this.changeDialogUpdateClosePoll(false);
            });
        });        
    }

    private updateContentJSON = async () => {
        const formData = new FormData();                           
        formData.append("notificationData", JSON.stringify(this.state.notificationData));
        formData.append("notificationContent", JSON.stringify(this.state.pollData))

        try {
            await updateContentJsonSenttNotification(formData);
        } 
        catch (error) {
            throw error;
        }
    }

    private getItem = async (id: number) => {
        try {
            const response = await getSentNotification(id);
            const notificationData = response.data;
            
            // Get Content JSON Url
            await fetch(notificationData.contentJsonUrl, {
                method: 'GET', 
                headers: {
                    'Access-Control-Allow-Origin': '*', 
                    'content-type': 'application/json'
                }
            })
            .then(res => res.json())
                    .then((data: IPoll) => {                 
                        this.setState({                        
                           notificationData: notificationData,
                           pollData: data,
                           loaderNotifications: false  
                        });
                    })
            .catch(err => { throw err });

            
        } catch (error) {
            return error;
        }
    }

    private getAnswerDataPoll = async (id: number) => {
        try {
            const response = await getAnswerDataPoll(id);
            const answerData = response.data;

            this.setState({
                answerData: answerData,
                loaderAnswers: false
            })

            return answerData;
            
        } catch (error) {
            return error;
        }
    }
}


const summaryPagePollWithTranslation = withTranslation()(SummaryPagePoll);
export default summaryPagePollWithTranslation;