import axios from './axiosJWTDecorator';
import { getBaseUrl } from '../configVariables';

var qs = require('qs');

let baseAxiosUrl = getBaseUrl() + '/api';

export const setAppSettings = async (appConfigData: any): Promise<any> => {
    let url = baseAxiosUrl + `/settings/SetAppSettings`;
    return await axios.post(url, appConfigData);
}

export const getAppSettings = async (): Promise<any> => {
    let url = baseAxiosUrl + `/settings/GetAppSettings`;
    return await axios.get(url);
}

export const getAdmins = async (): Promise<any> => {
    let url = baseAxiosUrl + `/settings/GetAdmins`;
    return await axios.get(url);
}

export const isAdmin = async (): Promise<any> => {
    let url = `${baseAxiosUrl}/settings/IsAdmin`;
    return await axios.get(url);
}

export const getUsersById = async (usersId: any): Promise<any> => {
    let url = baseAxiosUrl + `/settings/GetUsersById`;
    return await axios.get(url, false, false, {
        params: {
            usersId: usersId
        },
        paramsSerializer: params => {
            return qs.stringify(params)
        }
    });
}

export const getGroupsById = async (groupsId: any): Promise<any> => {
    let url = baseAxiosUrl + `/settings/GetGroupsById`;
    return await axios.get(url, false, false, {
        params: {
            groupsId: groupsId
        },
        paramsSerializer: params => {
            return qs.stringify(params)
        }
    });
}

export const searchUsers = async (query: string): Promise<any> => {
    let url = baseAxiosUrl + "/settings/searchUsers/" + query;
    return await axios.get(url);
}

export const getVersion = async (): Promise<any> => {
    let url = baseAxiosUrl + `/version`;
    return await axios.get(url);
}

export const setTargetGroups = async (targetGroupsData: any): Promise<any> => {
    let url = baseAxiosUrl + `/settings/SetTargetGroups`;
    return await axios.post(url, targetGroupsData);
}

export const getTargetGroups = async (channelId: string): Promise<any> => {
    let url = baseAxiosUrl + "/settings/getTargetGroups/" + channelId;
    return await axios.get(url);
}