import * as React from "react";
import { app, dialog } from "@microsoft/teams-js";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { initializeIcons } from '@fluentui/react';
import { withTranslation, WithTranslation } from "react-i18next";
import { TFunction } from "i18next";
import {
    getInitAdaptiveCardQuiz
} from '../AdaptiveCard/adaptiveCardQuiz';
import * as ACData from "adaptivecards-templating";
import * as AdaptiveExpressions from "adaptive-expressions";
import * as AdaptiveCards from "adaptivecards";
import moment, { locale } from "moment";
import { Input, Image, Flex, FlexItem, CircleIcon, Button, Loader, ArrowLeftIcon, SettingsIcon, Text, TextArea } from "@fluentui/react-northstar";
import {
    AddIcon, CameraIcon, ChevronStartIcon
} from '@fluentui/react-icons-northstar';
import { Utils } from "../../utility/Utils";
import { UxUtils } from "./../../utility/UxUtils";
import { ImageUtil } from '../../utility/imageutility';
import SettingsQuiz, {ISettingsQuizComponentProps} from "./SettingsQuiz";
import QuestionContainer, { IQuestion, IQuestionOptions } from "../Shared/QuestionContainer/QuestionContainer";
import { NewQuizConstants } from "../Constants/newQuiz";
import { ActionDataColumnValueType, ActionStatus, NotificationsType, PagesQuiz, VisibilityQuiz, VisibilityResultQuiz } from "../../utility/SharedEnum";
import { IQuiz } from "../../interfaces/IQuiz";
import IDraftNotification from "../../interfaces/IDraftNotification";
import IAudience from "../../interfaces/IAudience";
import dropdownItem from "../../interfaces/IDropDownItem";
import MessagesTabs from "../Constants/messagesTabs";
import AudienceNotifications, {AudienceNotificationsProps} from "./../Shared/AudienceNotifications/AudienceNotifications";
import './newQuiz.scss';
import { createDraftNotification, getDraftNotification, getTeams, updateDraftNotification, searchGroups, getGroups, verifyGroupAccess } from '../../apis/messageListApi';
import { isAdmin, getTargetGroups, getGroupsById } from "../../apis/settingsApi";
import { getBaseUrl } from '../../configVariables';

export interface NewQuizState {
    draftNotification: IDraftNotification,
    data: IQuiz,
    audience: IAudience,
    saveDraftButtonDisabled: boolean,
    isImageAdded: boolean;    
    errorImageUrlMessage: string;
    currentPage: PagesQuiz;
    card?: any; 
    exists?: boolean,
    loader: boolean,
    selectedImportant: boolean, //status of the importance selection on the interface
    imageFile: any,
    imageFileName: string,
    imageFileBase64: string,
    imagesFileNameDeleted: any[],
    selectedSchedule: boolean, //status of the scheduler checkbox,
    scheduledDate: string, //stores the scheduled date in string format
    futuredate: boolean, //if the date is in the future (valid schedule)
    createdDate?: Date,
    teamId?: string,
    isAdmin: boolean,
    channelId?: string,
    enableTargetGroups: boolean
}

export type NewQuizProps = {} & RouteComponentProps & WithTranslation;

class NewQuiz extends React.Component<NewQuizProps, NewQuizState> {
    private localize: TFunction;
    private settingsFooterComponentRef?: any = null;
    private card: any;

    constructor(props: NewQuizProps) {
        super(props);
        
        initializeIcons();
        this.localize = this.props.t;
        this.card = getInitAdaptiveCardQuiz(this.localize);
        var TempDate = this.getRoundedDate(5, this.getDateObject()); //get the current date

        this.state = {
            draftNotification: {
                id: "",
                type: NotificationsType.Quiz,
                title: "",
                imageLink: "",
                summary: "",
                author: "",
                buttonTitle: "",
                buttonLink: "",
                teams: [],
                rosters: [],
                groups: [],
                allUsers: false,
                isImportant: false,
                imagesFileNameDeleted: [],
                isScheduled: false,
                scheduledDate: TempDate,
            },
            data:
            {
                quiz: {
                    type: NotificationsType.Quiz,
                    status: ActionStatus.Active,
                    title: "",
                    description: "",
                    imageQuiz: "",
                    questions: [
                        {
                            text: "",
                            valueError: false,
                            image: "",
                            questionPlaceholder: this.localize("enterTheQuestion"),
                            checkedCorrectAnswer: true,
                            options: [
                                    NewQuizConstants.newOption(this.localize("option"))
                                ,
                                    NewQuizConstants.newOption(this.localize("option"))
                            ]
                        }
                    ],
                    settings: {
                        showCorrectAnswer: false,   // result of poll will be visible to everyone
                        dueDate: Utils.getDefaultExpiry(7).toISOString(), // default due date for poll is one week
                    }
                },
                adaptiveCardJson: "",                
            },
            audience: {
                loading: false,
                groupAccess: false,
                selectedRadioBtn: "teams",                
                teamsOptionSelected: true,
                rostersOptionSelected: false,
                allUsersOptionSelected: false,
                groupsOptionSelected: false,
                selectedTeamsNum: 0,
                selectedRostersNum: 0,
                selectedGroupsNum: 0,
                selectedTeams: [],
                selectedRosters: [],
                selectedGroups: [],
                unstablePinned: true,
                noResultMessage: "",
            },        
            card: this.card,
            isImageAdded: false,
            errorImageUrlMessage: "",
            currentPage: PagesQuiz.Main,
            saveDraftButtonDisabled: false,
            loader: true,
            selectedImportant: false, //important flag for the msg is false by default
            imageFile: null,
            imageFileName: "",
            imageFileBase64: "",
            imagesFileNameDeleted: [],
            selectedSchedule: false, //scheduler option is disabled by default
            scheduledDate: TempDate.toUTCString(), //current date in UTC string format
            futuredate: false, //by default the date is not in the future
            channelId: "", //channel id is empty by default,
            teamId: "",
            isAdmin: false,
            enableTargetGroups: false,
        }
    }

    public async componentDidMount() {    
        app.getContext().then((context: any) => {
            this.setState({
                teamId: context.team.groupId ?? context.channel.ownerGroupId,
                channelId: context.channel.id
            }, () => {
                let id = (this.props.match.params as any).id;
                var TempDate = this.getRoundedDate(5, this.getDateObject()); //get the current date
                this.setGroupAccess();

                Promise.all([this.isAdmin(), this.getTargetGroups()])
                    .then(() => {
                        this.radioControl();

                        this.getTeamList().then(() => {
                            if (id) {
                                this.getItem(id).then(() => {
                                    const selectedTeams = this.makeDropdownItemList(this.state.audience.selectedTeams, this.state.audience.teams);
                                    const selectedRosters = this.makeDropdownItemList(this.state.audience.selectedRosters, this.state.audience.teams);

                                    this.setState({
                                        loader: false,
                                        exists: true,
                                        selectedImportant: this.state.selectedImportant,
                                        selectedSchedule: this.state.selectedSchedule,
                                        scheduledDate: this.state.scheduledDate ? this.state.scheduledDate : TempDate.toUTCString(),
                                        audience: {
                                            ...this.state.audience,
                                            selectedTeams: selectedTeams,
                                            selectedRosters: selectedRosters,
                                        }
                                    });

                                    this.getGroupData(id).then(() => {
                                        const selectedGroups = this.makeDropdownItems(this.state.audience.groups);
                                        this.setState({
                                            audience: {
                                                ...this.state.audience,
                                                selectedGroups: selectedGroups
                                            }
                                        })
                                    });

                                    this.updateCard();
                                });
                            } else {
                                this.setState({
                                    loader: false,
                                    exists: false
                                }, () => {
                                    this.setDefaultCard(this.card);
                                })
                            }
                        });
                    });
            });
        });
    }

    /**
     * Get App Configs
     * */
    private async isAdmin() {
        try {
            let response = await isAdmin();

            if (response.status === 200 && response.data) {
                this.setState({
                    isAdmin: response.data
                });
            } else {
                this.setState({
                    isAdmin: false
                });
            }
        } catch (err) {
            this.setState({
                isAdmin: false
            });
        }
    };

    private async getTargetGroups() {
        let response = null;
        if (this.state.channelId) {
            response = await getTargetGroups(this.state.channelId);
        }

        if (response.status === 200 && response.data) {
            let targetGroups = response.data;

            var groups = await getGroupsById(targetGroups?.targetGroupsId)

            this.setState({
                audience: {
                    ...this.state.audience,
                    targetGroups: groups.data,
                },
                enableTargetGroups: groups.data?.length > 0 ? true : false
            })
        }
    }

    //function to set the radio control item to the right option depending on the status for
    //the targetingmode and if the user is a master admin or not
    private radioControl() {

        var opName = "teams";

        if (this.state.enableTargetGroups && !this.state.isAdmin) {
            opName = "groups";
        }

        this.setState({
            audience: {
                ...this.state.audience,
                selectedRadioBtn: opName,
                teamsOptionSelected: opName === 'teams',
                rostersOptionSelected: opName === 'rosters',
                groupsOptionSelected: opName === 'groups',
                allUsersOptionSelected: opName === 'allUsers',
            }
        });
    }

    public render(): JSX.Element {

        if (this.state.loader){
            return ( 
                <div className="Loader">
                    <Loader />
                </div>
             );
        }       
        else if (this.state.currentPage == PagesQuiz.Main){
            return(
                <div className="quiz">
                    <div className="taskModuleQuiz">
                        <Flex column className="formContainer" vAlign="stretch" gap="gap.small">
                            <Flex className="scrollableContent">
                                <Flex column className="formContentContainer" styles={{ width: '50%' }}>
                                    {this.renderCreatorQuiz()}
                                </Flex>
                                <Flex styles={{ width: '50%' }}>
                                    {this.renderPreviewSection()}
                                </Flex>
                            </Flex>
                            {this.renderFooterSection()} 
                        </Flex>
                    </div>
                </div>                
            )
        }
        else if(this.state.currentPage == PagesQuiz.Settings){
            return (
                <div className="quiz">
                    <div className="taskModuleQuiz">
                        <Flex column className="formContainer" vAlign="stretch" gap="gap.small">
                            <Flex className="scrollableContent">
                                <Flex column className="formContentContainer" styles={{ width: '50%' }}>
                                    <SettingsQuiz {...this.getPropsSettings()} />
                                </Flex>
                                <Flex styles={{ width: '50%' }}>
                                    {this.renderPreviewSection()}
                                </Flex>                            
                            </Flex> 
                            {this.renderFooterSettingsSection()}       
                        </Flex>
                    </div>
                </div>
            );
        }
        else if(this.state.currentPage == PagesQuiz.QuestionContainer){
            return(
                <div className="quiz">
                    <div className="taskModuleQuiz">
                        <Flex column className="formContainer" vAlign="stretch" gap="gap.small">
                            <Flex className="scrollableContent">
                                <Flex column className="formContentContainer" styles={{ width: '50%' }}>
                                    <Flex column gap="gap.medium">
                                        {this.state.data?.quiz?.imageQuiz &&
                                            <Flex hAlign="start">
                                                <FlexItem>
                                                    <Image className="quiz-img-preview" src={this.state.data?.quiz?.imageQuiz} />
                                                </FlexItem>
                                            </Flex>
                                        }
                                        <FlexItem>
                                            <Text weight="bold" size="large" content={this.state.data?.quiz?.title} />
                                        </FlexItem>
                                        <FlexItem>
                                            <Text content={this.state.data?.quiz?.description} />
                                        </FlexItem>
                                    </Flex>

                                    {this.renderQuestionContainer()}
                                </Flex>
                                <Flex styles={{ width: '50%' }}>
                                    {this.renderPreviewSection()}
                                </Flex>                            
                            </Flex>
                            {this.renderFooterQuestionSection()}                        
                        </Flex>
                    </div>
                </div>
            );
        }
        else if (this.state.currentPage == PagesQuiz.Audience) {
            return (
                <div className="quiz">
                    <div className="taskModuleQuiz">
                        <Flex column className="formContainer" vAlign="stretch" gap="gap.small">
                            <Flex className="scrollableContent">
                                <Flex column className="formContentContainer" styles={{ width: '50%' }}>
                                    {this.renderAudienceSection()}
                                </Flex> 
                                <Flex styles={{ width: '50%' }}>
                                    {this.renderPreviewSection()}
                                </Flex>                            
                            </Flex>   
                            {this.renderFooterAudienceSection()}          
                        </Flex>
                    </div>    
                </div>
            );
        }
        else {
            return (<div>Error</div>);
        }
    }

    private getPropsSettings = () => {
        let settingsProps: ISettingsQuizComponentProps = {
            ...this.getCommonSettingsProps(),
            onBack: () => {
                this.goToPage(PagesQuiz.Main);
                // setTimeout(() => {
                //     if (this.settingsFooterComponentRef) {
                //         this.settingsFooterComponentRef.focus();
                //     }
                // }, 0);
            }
        };

        return settingsProps;
    }

    /**
     * Helper method to provide common settings props for both mobile and web view
     */
     private getCommonSettingsProps = () => {
        return {
            showCorrectAnswer: this.state.data?.quiz?.settings.showCorrectAnswer,
            dueDate: this.state.data?.quiz?.settings.dueDate,
            locale: locale(),
            stringsLocalize: this.localize,
            // renderForMobile: UxUtils.renderingForMobile(),
            onChange: (props: ISettingsQuizComponentProps) => {
                this.updateSettings(props);
            },
            onMount: () => {
                
            },
        };
    }

    private updateSettings = (settingProps: ISettingsQuizComponentProps) => {
        this.setState({
            data: {
                quiz: {
                    ...this.state.data.quiz,
                    settings: settingProps
                }
            }
        }, () => {
            this.updateCard();
        });
    }

    private updateQuizTitle = (title: string) => {
                        
        this.setState({
            draftNotification: {
                ...this.state.draftNotification,
                title: title
            },
            data: {
                quiz: {
                    ...this.state.data.quiz,
                    title: title,                    
                }                
            }           
        }, () => {
            this.updateCard();
        });
    }

    private updateDescription = (description: string) => {
        this.setState({
            data: {
                quiz: {
                    ...this.state.data.quiz,
                    description: description
                }
            }           
        }, () => {
            this.updateCard();
        });
    }

    private renderPreviewSection = () => {
        return (
            <div className="adaptiveCardContainerQuiz">
            </div>
        );
    }

    private renderQuestionContainer = () => {
        return (
            <Flex column>
                <QuestionContainer 
                    questions={this.state.data?.quiz?.questions}
                    onAddQuestion={() => {
                        this.addQuestion();
                    }}
                    onUpdateQuestion={(i, value, imageQuestion) => {
                        this.updateQuestion(i, value, imageQuestion);
                    }}
                    onDeleteQuestion={(i) => {
                        this.deleteQuestion(i);
                    }}
                    onAddOption={(indexQuestion: any) => {
                        this.addOption(indexQuestion);
                    }} 
                    onUpdateChoice={(i, indexChoice, value, correctOption, imageOption) => {                        
                        this.updateChoice(i, indexChoice, value, correctOption, imageOption);
                    }}
                    onDeleteChoice={(i, indexChoice) => {
                        this.deleteChoice(i, indexChoice);
                    }}
                />              
            </Flex>
        );
    }

    private renderCreatorQuiz = () => {
        return (
            <Flex column gap="gap.medium">  
                <FlexItem>
                    <Input
                        className="inputField"
                        fluid 
                        label={this.localize("quizTitle")}
                        placeholder={this.localize("quizTitle")}
                        value={this.state.data?.quiz?.title}
                        onChange={(e) => {
                            this.updateQuizTitle((e.target as HTMLInputElement).value);
                        }}
                    />
                </FlexItem>

                <FlexItem>
                    <div className="inputField">
                        <Text className="label-field" content={this.localize("quizDescription")}></Text>
                        <TextArea
                            fluid
                            variables={{
                                height: '90px'
                            }}
                            placeholder={this.localize("quizDescription")}
                            value={this.state.data?.quiz?.description}
                            onChange={(e) => {
                                this.updateDescription((e.target as HTMLInputElement).value);
                            }}
                        />
                    </div> 
                </FlexItem>                                             

                <FlexItem className="cover-image">
                    <div className="inputField">
                        <Text className="label-field" content={this.localize("coverImage")}></Text>

                        {this.state.isImageAdded ?                                     
                            <Flex column>
                                <Flex>
                                    <FlexItem push>
                                        <Text className="btn-text" content={this.localize("clear")} onClick={() => { this.clearImagePreview() }}></Text>
                                    </FlexItem>                                                                                                
                                </Flex> 
                                <Flex hAlign="center">
                                    <FlexItem>
                                        <Image className="quiz-img-preview" src={this.state.data?.quiz?.imageQuiz} />
                                    </FlexItem>
                                </Flex>  
                            </Flex>                                                                                                     
                        :
                            <Flex column>
                                <div className="inputField" onClick={this.triggerHandleUpload.bind(this)}>
                                    <input type="file" name="input-file" id="input-file" className="inputFile" accept="image/*" onChange={(event) => { this.handleUpload(event); }} />
                                    <Flex column hAlign="center" vAlign="center" className="photo-box">
                                        <CameraIcon
                                            outline
                                        />
                                        
                                        <Text content={this.localize("uploadCoverImage")}></Text>
                                    </Flex>
                                </div>
                                <Text className={(this.state.errorImageUrlMessage === "") ? "hide" : "show"} error size="small" content={this.state.errorImageUrlMessage} />                                
                            </Flex>
                        }                                    
                    </div>                                
                </FlexItem>
            </Flex>
        )
    }

    /**
     * Helper function to provide footer for main page
     * @param isMobileView true or false based of whether its for mobile view or not
     */
    private renderFooterSection = (isMobileView?: boolean) => {
        return (
            <Flex className="footer-layout" gap={"gap.smaller"}>
                <FlexItem>
                    <div className="settings-summary-footer" {...UxUtils.getTabKeyProps()}
                        ref={(element) => {
                            this.settingsFooterComponentRef = element;
                        }}
                        onClick={() => {
                            this.goToPage(PagesQuiz.Settings);
                        }}>
                        <SettingsIcon className="settings-icon" outline={true} styles={({ theme: { siteVariables } }) => ({
                            color: siteVariables.colorScheme.brand.foreground,
                        })} />
                        <Text content={this.getSettingsSummary()} size="small" color="brand" />
                    </div>    
                </FlexItem>
                <FlexItem push>
                    <Button
                        primary
                        content={this.localize("Next")}
                        disabled={!this.state.data?.quiz?.title}
                        onClick={() => {
                            this.goToPage(PagesQuiz.QuestionContainer);
                        }}>
                    </Button>
                </FlexItem>
            </Flex>
        );
    }

    /**
     * Setting summary and button to switch from main view to settings view
     */
    private renderFooterSettingsSection = () => {
        return (
            <Flex className="footer-layout" gap={"gap.smaller"}>
                <Flex vAlign="center" className="pointer-cursor" {...UxUtils.getTabKeyProps()}
                    onClick={() => {                        
                        this.goToPage(PagesQuiz.Main);
                        setTimeout(() => {
                            if (this.settingsFooterComponentRef) {
                                this.settingsFooterComponentRef.focus();
                            }
                        }, 0);
                        
                    }}
                >
                    <ChevronStartIcon xSpacing="after" size="small" />
                    <Text content={this.localize("Back")} />
                </Flex>
            </Flex>
        );
    }

    /**
     * Setting summary and button to switch from main view to settings view
     */
     private renderFooterQuestionSection = () => {
        return (
            <Flex className={"footer-layout"} gap={"gap.smaller"} hAlign="end">                
                <FlexItem>
                    <Button
                        primary
                        content={this.localize("Back")}
                        onClick={() => {
                            this.goToPage(PagesQuiz.Main);
                        }}>
                    </Button>
                </FlexItem>
                <FlexItem>
                    <Button
                        primary
                        content={this.localize("Next")}
                        onClick={() => {
                            var validQuizToSend = this.validQuestions();

                            if (validQuizToSend.validQuestion && validQuizToSend.checkedCorrectAnswer) {
                                this.goToPage(PagesQuiz.Audience);
                            }                                        
                        }}>
                    </Button>
                </FlexItem>
            </Flex>
        );
    }

    private renderAudienceSection = () => {
        return (
            <AudienceNotifications
                loading={this.state.audience.loading}
                teams={this.state.audience.teams}
                groups={this.state.audience.groups}
                targetGroups={this.state.audience.targetGroups}
                selectedRadioBtn={this.state.audience.selectedRadioBtn}
                groupAccess={this.state.audience.groupAccess}
                teamsOptionSelected={this.state.audience.teamsOptionSelected}
                rostersOptionSelected={this.state.audience.rostersOptionSelected}
                allUsersOptionSelected={this.state.audience.allUsersOptionSelected}
                groupsOptionSelected={this.state.audience.groupsOptionSelected}
                selectedTeams={this.state.audience.selectedTeams}
                selectedRosters={this.state.audience.selectedRosters}
                selectedGroups={this.state.audience.selectedGroups}
                unstablePinned={this.state.audience.unstablePinned}
                noResultMessage={this.state.audience.noResultMessage}
                selectedImportant={this.state.selectedImportant}
                selectedSchedule={this.state.selectedSchedule}
                scheduledDate={this.state.scheduledDate}
                futuredate={this.state.futuredate}
                isAdmin={this.state.isAdmin}
                enableTargetGroups={this.state.enableTargetGroups}
                onGroupSearchQueryChange={this.onGroupSearchQueryChange}
                onGroupSelected={this.onGroupSelected}
                onTeamsChange={this.onTeamsChange}
                onRostersChange={this.onRostersChange}
                onGroupsChange={this.onGroupsChange}
                onImportantSelected={this.onImportantSelected}
                onScheduleSelected={this.onScheduleSelected}
                getDateObject={this.getDateObject}
                onChangeScheduleDate={this.onChangeScheduleDate}
            />
        );
    }

    private renderFooterAudienceSection = (isMobileView?: boolean) => {
        return (
            <Flex className={"footer-layout"} gap={"gap.smaller"} hAlign="end">                
                <FlexItem>
                    <Button
                        primary
                        content={this.localize("Back")}
                        onClick={() => {
                            this.goToPage(PagesQuiz.QuestionContainer);
                        }}>
                    </Button>
                </FlexItem>
                <FlexItem push>
                    <Button
                        content={this.localize("Schedule")}
                        disabled={this.isNextBtnAudienceDisabled() || !this.state.selectedSchedule}
                        loading={this.state.saveDraftButtonDisabled}
                        onClick={() => {
                            this.setState({
                                saveDraftButtonDisabled: true
                            }, () => {
                                this.onSchedule();
                            });
                        }}
                        primary={this.state.selectedSchedule || this.state.saveDraftButtonDisabled} 
                    />
                </FlexItem>
                <FlexItem>
                    <Button
                        primary
                        content={this.localize("SaveAsDraft")}
                        disabled={this.isNextBtnAudienceDisabled() || this.state.selectedSchedule}
                        loading={this.state.saveDraftButtonDisabled}
                        onClick={() => {
                            this.setState({
                                saveDraftButtonDisabled: true
                            }, () => {
                                this.createQuiz();
                            });
                        }}>
                    </Button>
                </FlexItem>
            </Flex>
        );
    }

    private isNextBtnAudienceDisabled = () => {
        const teamsSelectionIsValid = (this.state.audience.teamsOptionSelected && (this.state.audience.selectedTeamsNum !== 0)) || (!this.state.audience.teamsOptionSelected);
        const rostersSelectionIsValid = (this.state.audience.rostersOptionSelected && (this.state.audience.selectedRostersNum !== 0)) || (!this.state.audience.rostersOptionSelected);
        const groupsSelectionIsValid = (this.state.audience.groupsOptionSelected && (this.state.audience.selectedGroupsNum !== 0)) || (!this.state.audience.groupsOptionSelected);
        const nothingSelected = (!this.state.audience.teamsOptionSelected) && (!this.state.audience.rostersOptionSelected) && (!this.state.audience.groupsOptionSelected) && (!this.state.audience.allUsersOptionSelected);
        return (!teamsSelectionIsValid || !rostersSelectionIsValid || !groupsSelectionIsValid || nothingSelected)
    }

    private onGroupSearchQueryChange = async (event: any, itemsData: any) => {

        if (!itemsData.searchQuery) {
            this.setState({                
                audience: {
                    ...this.state.audience,
                    loading: false,
                    noResultMessage: "",
                    groups: [],
                }
                
            });
        }
        else if (itemsData.searchQuery && itemsData.searchQuery.length <= 2) {
            this.setState({
                audience: {
                    ...this.state.audience,
                    loading: false,
                    noResultMessage: this.localize("NoMatchMessage"),
                }
            });
        }
        else if (itemsData.searchQuery && itemsData.searchQuery.length > 2) {
            // handle event trigger on item select.
            const result = itemsData.items && itemsData.items.find(
                (item: { header: string; }) => item.header.toLowerCase() === itemsData.searchQuery.toLowerCase()
            )
            if (result) {
                return;
            }

            this.setState({ audience: {
                ...this.state.audience,        
                    loading: true,
                    noResultMessage: "",
                }
            });

            try {
                const query = encodeURIComponent(itemsData.searchQuery);
                const response = await searchGroups(query);
                this.setState({                    
                    audience: {
                        ...this.state.audience,
                        groups: response.data,
                        loading: false,
                        noResultMessage: this.localize("NoMatchMessage")
                    }
                });
            }
            catch (error) {
                return error;
            }
        }
    }

    private onGroupSelected = (event: any, data: any) => {
        this.setState({
            audience: {
                ...this.state.audience,
                selectedRadioBtn: data.value,
                teamsOptionSelected: data.value === 'teams',
                rostersOptionSelected: data.value === 'rosters',
                groupsOptionSelected: data.value === 'groups',
                allUsersOptionSelected: data.value === 'allUsers',
                selectedTeams: data.value === 'teams' ? this.state.audience.selectedTeams : [],
                selectedTeamsNum: data.value === 'teams' ? this.state.audience.selectedTeamsNum : 0,
                selectedRosters: data.value === 'rosters' ? this.state.audience.selectedRosters : [],
                selectedRostersNum: data.value === 'rosters' ? this.state.audience.selectedRostersNum : 0,
                selectedGroups: data.value === 'groups' ? this.state.audience.selectedGroups : [],
                selectedGroupsNum: data.value === 'groups' ? this.state.audience.selectedGroupsNum : 0,
            }
            
        });
    }

    private static MAX_SELECTED_TEAMS_NUM: number = 20;

    private onTeamsChange = (event: any, itemsData: any) => {
        if (itemsData.value.length > NewQuiz.MAX_SELECTED_TEAMS_NUM) return;
        this.setState({
            audience: {
                ...this.state.audience,
                selectedTeams: itemsData.value,
                selectedTeamsNum: itemsData.value.length,
                selectedRosters: [],
                selectedRostersNum: 0,
                selectedGroups: [],
                selectedGroupsNum: 0
            }            
        })
    }

    private onRostersChange = (event: any, itemsData: any) => {
        if (itemsData.value.length > NewQuiz.MAX_SELECTED_TEAMS_NUM) return;
        this.setState({
            audience: {
                ...this.state.audience,
                selectedRosters: itemsData.value,
                selectedRostersNum: itemsData.value.length,
                selectedTeams: [],
                selectedTeamsNum: 0,
                selectedGroups: [],
                selectedGroupsNum: 0
            }            
        })
    }

    private onGroupsChange = (event: any, itemsData: any) => {
        this.setState({
            audience: {
                ...this.state.audience,
                selectedGroups: itemsData.value,
                selectedGroupsNum: itemsData.value.length,
                groups: this.state.enableTargetGroups ? this.state.audience.groups : [],
                selectedTeams: [],
                selectedTeamsNum: 0,
                selectedRosters: [],
                selectedRostersNum: 0
            }            
        })
    }

    private getItem = async (id: number) => {
        try {
            const response = await getDraftNotification(id);
            const draftNotificationDetail = response.data;

            let selectedRadioButton = "teams";
            if (draftNotificationDetail.rosters.length > 0) {
                selectedRadioButton = "rosters";
            }
            else if (draftNotificationDetail.groups.length > 0) {
                selectedRadioButton = "groups";
            }
            else if (draftNotificationDetail.allUsers) {
                selectedRadioButton = "allUsers";
            }

            // Get Content JSON Url
            await fetch(draftNotificationDetail.contentJsonUrl, {
                method: 'GET', 
                headers: {
                    'Access-Control-Allow-Origin': '*', 
                    'content-type': 'application/json'
                }
            })
            .then(res => res.json())
                    .then((data) => {
                        this.setState({
                            data: data,
                            draftNotification: draftNotificationDetail,
                            selectedImportant: draftNotificationDetail.isImportant,
                            selectedSchedule: draftNotificationDetail.isScheduled,
                            scheduledDate: draftNotificationDetail.scheduledDate,
                            audience: {
                                ...this.state.audience,
                                teamsOptionSelected: draftNotificationDetail.teams.length > 0,
                                selectedTeamsNum: draftNotificationDetail.teams.length,
                                rostersOptionSelected: draftNotificationDetail.rosters.length > 0,
                                selectedRostersNum: draftNotificationDetail.rosters.length,
                                groupsOptionSelected: draftNotificationDetail.groups.length > 0,
                                selectedGroupsNum: draftNotificationDetail.groups.length,
                                selectedRadioBtn: selectedRadioButton,
                                selectedTeams: draftNotificationDetail.teams,
                                selectedRosters: draftNotificationDetail.rosters,
                                selectedGroups: draftNotificationDetail.groups,
                                allUsersOptionSelected: draftNotificationDetail.allUsers
                            },
                            isImageAdded: data?.quiz?.imageQuiz ? true : false,             
                        }, () => {
                            this.validQuestions();
                        });
                    })
            .catch(err => { throw err });

            
        } catch (error) {
            return error;
        }
    }

    private getGroupData = async (id: number) => {
        try {
            const response = await getGroups(id);
            this.setState({
                audience: {
                    ...this.state.audience,
                    groups: response.data
                }                
            });
        }
        catch (error) {
            return error;
        }
    }

    private getTeamList = async () => {
        try {
            const response = await getTeams();
            this.setState({                
                audience: {
                    ...this.state.audience,
                    teams: response.data
                }
            });
        } catch (error) {
            return error;
        }
    }

    private setGroupAccess = async () => {
        await verifyGroupAccess().then(() => {
            this.setState({
                audience: {
                    ...this.state.audience,
                    groupAccess: true
                }
            });
        }).catch((error) => {
            const errorStatus = error.response.status;
            if (errorStatus === 403) {
                this.setState({
                    audience: {
                        ...this.state.audience,
                        groupAccess: false
                    }
                });
            }
            else {
                throw error;
            }
        });
    }

    private makeDropdownItems = (items: any[] | undefined) => {
        const resultedTeams: dropdownItem[] = [];
        if (items) {
            items.forEach((element) => {
                resultedTeams.push({
                    key: element.id,
                    header: element.name,
                    content: element.mail,
                    image: ImageUtil.makeInitialImage(element.name),
                    team: {
                        id: element.id
                    },

                });
            });
        }
        return resultedTeams;
    }

    private makeDropdownItemList = (items: any[], fromItems: any[] | undefined) => {
        const dropdownItemList: dropdownItem[] = [];
        items.forEach(element =>
            dropdownItemList.push(
                typeof element !== "string" ? element : {
                    key: fromItems!.find(x => x.id === element).id,
                    header: fromItems!.find(x => x.id === element).name,
                    image: ImageUtil.makeInitialImage(fromItems!.find(x => x.id === element).name),
                    team: {
                        id: element
                    }
                })
        );
        return dropdownItemList;
    }

     /**
     * method to get the setting summary from selected due date and result visibility
     */
     private getSettingsSummary = (): string => {
        let settingsStrings: string[] = [];
        let dueDate = new Date(this.state.data?.quiz?.settings.dueDate);
        let showCorrectAnswer = this.state.data?.quiz?.settings.showCorrectAnswer;
        if (dueDate) {
            let dueDateString: string;
            let dueDateValues: number[];
            let dueIn: any = Utils.getTimeRemaining(dueDate);
            if (dueIn["Utils.YEARS"] > 0) {
                dueDateString = dueIn[Utils.YEARS] == 1 ? "DueInYear" : "DueInYears";
                dueDateValues = [dueIn[Utils.YEARS]];
            } else if (dueIn[Utils.MONTHS] > 0) {
                dueDateString = dueIn[Utils.MONTHS] == 1 ? "DueInMonth" : "DueInMonths";
                dueDateValues = [dueIn[Utils.MONTHS]];
            } else if (dueIn[Utils.WEEKS] > 0) {
                dueDateString = dueIn[Utils.WEEKS] == 1 ? "DueInWeek" : "DueInWeeks";
                dueDateValues = [dueIn[Utils.WEEKS]];
            } else if (dueIn[Utils.DAYS] > 0) {
                dueDateString = dueIn[Utils.DAYS] == 1 ? "DueInDay" : "DueInDays";
                dueDateValues = [dueIn[Utils.DAYS]];
            } else if (dueIn[Utils.HOURS] > 0 && dueIn[Utils.MINUTES] > 0) {
                if (dueIn[Utils.HOURS] == 1 && dueIn[Utils.MINUTES] == 1) {
                    dueDateString = "DueInHourAndMinute";
                } else if (dueIn[Utils.HOURS] == 1) {
                    dueDateString = "DueInHourAndMinutes";
                } else if (dueIn[Utils.MINUTES] == 1) {
                    dueDateString = "DueInHoursAndMinute";
                } else {
                    dueDateString = "DueInHoursAndMinutes";
                }
                dueDateValues = [dueIn[Utils.HOURS], dueIn[Utils.MINUTES]];
            } else if (dueIn[Utils.HOURS] > 0) {
                dueDateString = dueIn[Utils.HOURS] == 1 ? "DueInHour" : "DueInHours";
                dueDateValues = [dueIn[Utils.HOURS]];
            } else {
                dueDateString = dueIn[Utils.MINUTES] == 1 ? "DueInMinute" : "DueInMinutes";
                dueDateValues = [dueIn[Utils.MINUTES]];
            }
            settingsStrings.push(Utils.getStringLocalizer(this.localize(dueDateString), ...dueDateValues));
        }

        if (showCorrectAnswer) {           
            settingsStrings.push(this.localize("correctAnswer"));
        }

        return settingsStrings.join(". ");
    }

    private goToPage = (page: any) => {
        this.setState({
            currentPage: page
        }, () => {
            this.updateCard();           
        });
    }

    private setDefaultCard = (card: any) => {   
        
        let questions: IQuestion[] = [];
        for (let index = 0; index < this.state.data?.quiz?.questions?.length; index++) {
            
            let countCorrectOptions = this.state.data?.quiz?.questions[index].options.filter(opt => opt.correctOption === true).length;
            
            let question: IQuestion = {
                name: `${index}`,
                text: this.state.data?.quiz?.questions[index].text,
                image: this.state.data?.quiz?.questions[index].image,
                checkedCorrectAnswer: this.state.data?.quiz?.questions[index].checkedCorrectAnswer,
                options: this.generateOptionsQuiz(index),
                valueType: countCorrectOptions > 1 ? ActionDataColumnValueType.MultiOption : ActionDataColumnValueType.SingleOption,
            }

            questions.push(question);
        }

        const data: IQuiz = {
            quiz: {
                type: NotificationsType.Quiz,
                status: ActionStatus.Active,
                title: this.state.data?.quiz?.title,
                description: this.state.data?.quiz?.description,
                imageQuiz: getBaseUrl() + "/image/imagePlaceholder.png",
                questions: questions,
                strings: this.getStringsForPreview(),
                settings: {
                    dueDate: this.state.data?.quiz?.settings.dueDate,
                    showCorrectAnswer: this.state.data?.quiz?.settings.showCorrectAnswer,
                    visibilityResult: VisibilityResultQuiz.OnlyMe,
                    itemsVisibility: VisibilityQuiz.All,
                    rowsVisibility: VisibilityQuiz.All,
                    itemsEditable: false,
                    canUserAddMultipleItems: false,
                },
            },
            adaptiveCardJson: "",    
        }

        var templateAC = new ACData.Template(card);
        var adaptiveCardData = templateAC.expand({
            $root: data
        })
        
        const adaptiveCard = new AdaptiveCards.AdaptiveCard();
        adaptiveCard.parse(adaptiveCardData);
        const renderedCard = adaptiveCard.render();
        const container = document.getElementsByClassName('adaptiveCardContainerQuiz')[0].firstChild;
        if (container != null) {
            container.replaceWith(renderedCard!);
        } else {
            document.getElementsByClassName('adaptiveCardContainerQuiz')[0].appendChild(renderedCard!);
        }
    }

    private updateCard = () => {       
        
        let questions: IQuestion[] = [];
        for (let index = 0; index < this.state.data?.quiz?.questions?.length; index++) {
            
            let countCorrectOptions = this.state.data?.quiz?.questions[index].options.filter(opt => opt.correctOption === true).length;
            
            let question: IQuestion = {
                name: `${index}`,
                text: this.state.data?.quiz?.questions[index].text,
                image: this.state.data?.quiz?.questions[index].image,
                checkedCorrectAnswer: this.state.data?.quiz?.questions[index].checkedCorrectAnswer,
                options: this.generateOptionsQuiz(index),
                valueType: countCorrectOptions > 1 ? ActionDataColumnValueType.MultiOption : ActionDataColumnValueType.SingleOption,
            }

            questions.push(question);
        }

        const data: IQuiz = {
            quiz: {
                type: NotificationsType.Quiz,
                status: ActionStatus.Active,
                title: this.state.data?.quiz?.title,
                description: this.state.data?.quiz?.description,
                imageQuiz: this.state.data?.quiz?.imageQuiz,
                questions: questions,
                strings: this.getStringsForPreview(),
                settings: {
                    dueDate: this.state.data?.quiz?.settings.dueDate,
                    showCorrectAnswer: this.state.data?.quiz?.settings.showCorrectAnswer,
                    visibilityResult: VisibilityResultQuiz.OnlyMe,
                    itemsVisibility: VisibilityQuiz.All,
                    rowsVisibility: VisibilityQuiz.All,
                    itemsEditable: false,
                    canUserAddMultipleItems: false,
                },
            },
            adaptiveCardJson: "",    
        }

        var templateAC = new ACData.Template(this.card);
        var adaptiveCardData = templateAC.expand({
            $root: data
        })
        
        // data.adaptiveCardJson = JSON.stringify(getInitAdaptiveCardPoll(this.localize));

        const adaptiveCard = new AdaptiveCards.AdaptiveCard();
        adaptiveCard.parse(adaptiveCardData);
        const renderedCard = adaptiveCard.render();
        const container = document.getElementsByClassName('adaptiveCardContainerQuiz')[0].firstChild;
        if (container != null) {
            container.replaceWith(renderedCard!);
        } else {
            document.getElementsByClassName('adaptiveCardContainerQuiz')[0].appendChild(renderedCard!);
        }

    }

    private handleUpload(event: any) {
        
        let file = event.target.files[0];

        if (file) {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {                
                // Get Metadata image for validate width and height
                ImageUtil.getMetadataImage(reader.result as string, (img: any) => {
                    if (!ImageUtil.validImage(img, file?.size) || !ImageUtil.validTypeImage(reader.result as string)) {                        
                        this.setState({
                            errorImageUrlMessage: this.localize("ErrorUpload"),                        
                            isImageAdded: false,
                            imageFileBase64: "",
                            imageFile: null,
                            imageFileName: "",
                            data: {
                                ...this.state.data,
                                quiz: {
                                    ...this.state.data?.quiz,
                                    imageQuiz: "",
                                }
                            }
                        }, () => {
                            this.updateCard();
                        });
                        (document.getElementById("input-file") as HTMLInputElement).value = "";
                        return;
                    }
                    else {
                        this.setState({
                            errorImageUrlMessage: ""
                        });
                    }

                    this.setState({
                        imageFileBase64: reader.result as string,
                        imageFile: file,
                        imageFileName: file.name,
                        data: {
                            ...this.state.data,
                            quiz: {
                                ...this.state.data?.quiz,
                                imageQuiz: reader.result as string,
                            }
                        },
                        isImageAdded: true
                    }, () => {
                        this.updateCard();
                    });                    
                 });
            };
        }
    }

    private triggerHandleUpload(event: any) {
        document.getElementById("input-file")!.click();
    }

    private clearImagePreview() {
        var imageToDelete = this.state.draftNotification.imageLink ? this.state.draftNotification.imageLink.substring(this.state.draftNotification.imageLink.lastIndexOf('/') + 1, this.state.draftNotification.imageLink.lastIndexOf("?")) : null;
        this.setState({
            data: {
                ...this.state.data,
                quiz: {
                    ...this.state.data?.quiz,
                    imageQuiz: "",
                }
            },
            isImageAdded: false,
            imagesFileNameDeleted: imageToDelete ? [...this.state.imagesFileNameDeleted, imageToDelete] : this.state.imagesFileNameDeleted,
        }, () => {
            this.updateCard();
        });
    }

    private addQuestion = () => {
        const questionsCopy = [...this.state.data?.quiz?.questions];
        questionsCopy.push(
            {
                text: "",
                valueError: false,
                image: "",
                checkedCorrectAnswer: true,
                questionPlaceholder: this.localize("enterTheQuestion"),
                options: [
                        NewQuizConstants.newOption(this.localize("option"))
                    ,
                        NewQuizConstants.newOption(this.localize("option"))
                ]
            }
        )
        this.setState({
            data: {
                quiz: {
                    ...this.state.data?.quiz,
                    questions: questionsCopy
                }
            }
        }, () => {
            this.updateCard();
        });
    }

    private updateQuestion = (indexQuestion: any, value: any, imageQuestion: any) => {
        const questionsCopy = [...this.state.data?.quiz?.questions];

        if(value != null && value != undefined){
            questionsCopy[indexQuestion].text = value; 
            questionsCopy[indexQuestion].valueError = false;           
        }

        if(imageQuestion != null && imageQuestion != undefined){
            questionsCopy[indexQuestion].image = imageQuestion;
            questionsCopy[indexQuestion].valueError = false;            
        }

        this.setState({
            data: {
                quiz: {
                    ...this.state.data?.quiz,
                    questions: questionsCopy
                }
            }
        }, () => {
            this.updateCard();
        });
    }

    private deleteQuestion = (indexQuestion: any) => {
        const questionsCopy = [...this.state.data?.quiz?.questions];
        questionsCopy.splice(indexQuestion, 1);
        this.setState({
            data: {
                quiz: {
                    ...this.state.data?.quiz,
                    questions: questionsCopy
                }
            }
        }, () => {
            this.updateCard();
        });
    }

    private addOption = (indexQuestion: any) => {
        const questionsCopy = [...this.state.data?.quiz?.questions];
        questionsCopy[indexQuestion].options.push(
            NewQuizConstants.newOption(this.localize("option"))
        );
       this.setState({
            data: {
                quiz: {
                    ...this.state.data?.quiz,
                    questions: questionsCopy
                }
            }
       });
    }

    private updateChoice = (index: any, indexQuestion: any, value?: any, correctOption?: boolean, imageOption?: any) => {
        const questionsCopy = [...this.state.data?.quiz?.questions];
        
        if(value != null && value != undefined){
            questionsCopy[indexQuestion].options[index].text = value;
            questionsCopy[indexQuestion].options[index].optionError = false;
        }

        if(correctOption != null && correctOption != undefined){
            questionsCopy[indexQuestion].options[index].correctOption = correctOption;            
        }

        if(imageOption != null && imageOption != undefined){
            questionsCopy[indexQuestion].options[index].image = imageOption;
            questionsCopy[indexQuestion].options[index].optionError = false;
        }

        this.setState({
            data: {
                quiz: {
                    ...this.state.data?.quiz,
                    questions: questionsCopy
                }
            }
        });
    }

    private deleteChoice = (index: any, indexQuestion: any) => {
        const questionsCopy = [...this.state.data?.quiz?.questions];
        questionsCopy[indexQuestion].options.splice(index, 1);
        this.setState({
            data: {
                quiz: {
                    ...this.state.data?.quiz,
                    questions: questionsCopy
                }
            }
        });
    }

    private validQuestions = () => {
        
        var validQuestion = true;
        var checkedCorrectAnswer = true;

        const questionsCopy = [...this.state.data?.quiz?.questions];
        
        this.state.data?.quiz?.questions.forEach((question, indexQuestion) => {

            if(!question.text && !question.image){
                validQuestion = false;                
                
                questionsCopy[indexQuestion].valueError = true;    
            }

            // Check if question contains option checked correct answer
            var questionCheckedCorrectAnswer = question.options.some(opt => opt.correctOption === true);
            questionsCopy[indexQuestion].checkedCorrectAnswer = questionCheckedCorrectAnswer;

            if (!questionCheckedCorrectAnswer) {
                checkedCorrectAnswer = false;
            }

            question.options.forEach((option, indexOption) => {
                
                if(!option.text && !option.image){
                    validQuestion = false;

                    questionsCopy[indexQuestion].options[indexOption].optionError = true; 
                }
            })
        })

        this.setState({
            data: {
                quiz: {
                    ...this.state.data?.quiz,
                    questions: questionsCopy
                }
            }
        })

        return { validQuestion, checkedCorrectAnswer };
    }

    private generateOptionsQuiz = (indexQuestion: number) => {
        // Create Quiz options
        let options: IQuestionOptions[] = [];
        for (let index = 0; index < this.state.data?.quiz?.questions[indexQuestion].options.length; index++) {
            
            let quizChoice: IQuestionOptions = {
                name: `${index}`,
                text: this.state.data?.quiz?.questions[indexQuestion].options[index].text,
                image: this.state.data?.quiz?.questions[indexQuestion].options[index].image,
                correctOption: this.state.data?.quiz?.questions[indexQuestion].options[index].correctOption,
                checked: false,
            };
            options.push(quizChoice);
        }

        return options;
    }

     //called when the user clicks to schedule the message
     private onSchedule = () => {
        var Today = new Date(); //today date
        var Scheduled = new Date(this.state.scheduledDate); //scheduled date
        
        //only allow the save when the scheduled date is 30 mins in the future, if that is the case calls the onSave function
        if (Scheduled.getTime() > Today.getTime() + 1800000) { this.createQuiz() }
        else {
            //set the state to indicate future date error
            //if futuredate is true, an error message is shown right below the date selector
            this.setState({
                futuredate: true
            })
        }
    }

    private createQuiz = () => {       

        const selectedTeams: string[] = [];
        const selctedRosters: string[] = [];
        const selectedGroups: string[] = [];

        this.state.audience.selectedTeams.forEach(x => selectedTeams.push(x.team.id));
        this.state.audience.selectedRosters.forEach(x => selctedRosters.push(x.team.id));
        this.state.audience.selectedGroups.forEach(x => selectedGroups.push(x.team.id));

        let questions: IQuestion[] = [];
        for (let index = 0; index < this.state.data?.quiz?.questions?.length; index++) {
            
            let countCorrectOptions = this.state.data?.quiz?.questions[index].options.filter(opt => opt.correctOption === true).length;
            
            let question: IQuestion = {
                name: `${index}`,
                text: this.state.data?.quiz?.questions[index].text,
                image: this.state.data?.quiz?.questions[index].image,
                checkedCorrectAnswer: this.state.data?.quiz?.questions[index].checkedCorrectAnswer,
                options: this.generateOptionsQuiz(index),
                valueType: countCorrectOptions > 1 ? ActionDataColumnValueType.MultiOption : ActionDataColumnValueType.SingleOption,
            }

            questions.push(question);
        }

        const data: IQuiz = {
            quiz: {
                type: NotificationsType.Quiz,
                status: ActionStatus.Active,
                title: this.state.data?.quiz?.title,
                description: this.state.data?.quiz?.description,
                imageQuiz: this.state.data?.quiz?.imageQuiz,
                questions: questions,
                strings: this.getStringsForPreview(),
                settings: {
                    dueDate: this.state.data?.quiz?.settings.dueDate,
                    showCorrectAnswer: this.state.data?.quiz?.settings.showCorrectAnswer,
                    visibilityResult: VisibilityResultQuiz.OnlyMe,
                    itemsVisibility: VisibilityQuiz.All,
                    rowsVisibility: VisibilityQuiz.All,
                    itemsEditable: false,
                    canUserAddMultipleItems: false,
                },
            },
            adaptiveCardJson: "",    
        }

        const draftNotification: IDraftNotification = {
            id: this.state.draftNotification.id,
            type: this.state.draftNotification.type,
            title: this.state.draftNotification.title,
            imageLink: this.state.draftNotification.imageLink,
            summary: this.state.draftNotification.summary,
            author: this.state.draftNotification.author, // Criar campo adicional para preencher autor
            buttonTitle: this.state.draftNotification.buttonLink,
            buttonLink: this.state.draftNotification.buttonLink,
            teams: selectedTeams,
            rosters: selctedRosters,
            groups: selectedGroups,
            allUsers: this.state.audience?.allUsersOptionSelected,
            teamId: this.state.teamId,
            isImportant: this.state.selectedImportant,
            imagesFileNameDeleted: this.state.imagesFileNameDeleted,
            buttons: this.state.draftNotification.buttons,
            isScheduled: this.state.selectedSchedule,
            scheduledDate: this.state.selectedSchedule ? new Date(this.state.scheduledDate) : null,
        };

        var templateAC = new ACData.Template(this.card);
        var adaptiveCardData = templateAC.expand({
            $root: data
        })

        data.adaptiveCardJson = JSON.stringify(getInitAdaptiveCardQuiz(this.localize));

        this.setState({
            card: adaptiveCardData,
            data: data,
            draftNotification: draftNotification
        }, () => {
            var activeTab = {
                activeTab: this.state.selectedSchedule ? MessagesTabs.scheduleds : MessagesTabs.drafts
            };
    
            localStorage.setItem('activeTab', JSON.stringify(activeTab));

            const formData = new FormData();                           
            formData.append("notificationData", JSON.stringify(this.state.draftNotification));
            formData.append("notificationContent", JSON.stringify(this.state.data))

            if (this.state.imageFile != null) {
                formData.append("filesImage", this.state.imageFile, this.state.imageFileName);
            }

            if (this.state.exists) {
                this.editDraftNotification(formData).then(() => {
                    dialog.url.submit();
                });
            } else {
                this.postDraftNotification(formData).then(() => {
                    dialog.url.submit();
                });
            }
        });
        
    }

    private getStringsForPreview = () => {
        
        var dueDate = moment(this.state.data?.quiz?.settings.dueDate).format('llll');
        
        var totalQuestions = [
            this.state.data?.quiz?.questions.length,
            this.state.data?.quiz?.questions.length > 1 ? this.localize("questions") : this.localize("question")
        ]

        let previewComponentStrings: any = {
            dueByDate: Utils.getStringLocalizer(this.localize("due_by_date"), [dueDate]),
            QuizClosed: this.localize("quizClosed"),
            QuizExpired: this.localize("quiz_expired"),
            QuizSent: this.localize("QuizSent"),
            TotalQuestionQuiz: Utils.getStringLocalizer(this.localize("totalQuestionQuiz"), ...totalQuestions),
            BeTheFirstOneToRespond: this.localize("be_the_first_one_to_respond"),
            Respond: this.localize("respond"),
            ViewResults: this.localize("view_result"), 
            OneResponded: this.localize("OneResponded"),
            MoreResponded: this.localize("MoreResponded"),
        };
        return previewComponentStrings;
    }

    private postDraftNotification = async (draftMessage: any) => {
        try {
            await createDraftNotification(draftMessage);
        } catch (error) {
            throw error;
        }
    }

    private editDraftNotification = async (draftMessage: any) => {
        try {
            await updateDraftNotification(draftMessage);
        } catch (error) {
            return error;
        }
    }

    // handler for the important message checkbox
    private onImportantSelected = () => {
        this.setState({
            selectedImportant: !this.state.selectedImportant
        });
    }

    //get the next rounded up (ceil) date in minutes
    private getRoundedDate = (minutes: number, d = new Date()) => {

        let ms = 1000 * 60 * minutes; // convert minutes to ms
        let roundedDate = new Date(Math.ceil(d.getTime() / ms) * ms);

        return roundedDate
    }

    //get date object based on the string parameter
    private onChangeScheduleDate = (datestring?: string) => {
        
        if (!datestring) {
            var TempDate = new Date(); //get current date
            TempDate.setTime(TempDate.getTime() + 86400000);
            datestring = TempDate.toISOString(); //if date string is not provided, then return tomorrow rounded up next 5 minutes
        }
        this.setState({
            scheduledDate: datestring,
            futuredate: false
        })
    }

    //get date object based on the string parameter
    private getDateObject = (datestring?: string) => {
        if (!datestring) {
            var TempDate = new Date(); //get current date
            TempDate.setTime(TempDate.getTime() + 86400000);
            return TempDate; //if date string is not provided, then return tomorrow rounded up next 5 minutes
        }
        return new Date(datestring); //if date string is provided, return current date object
    }

    //handler for the Schedule Send checkbox
    private onScheduleSelected = () => {
        var TempDate = this.getRoundedDate(5, this.getDateObject()); //get the next day date rounded to the nearest hour/minute
        
        //set the state
        this.setState({
            selectedSchedule: !this.state.selectedSchedule,
            scheduledDate: TempDate.toUTCString(),
        });
    }
}


const newQuizWithTranslation = withTranslation()(NewQuiz);
export default newQuizWithTranslation;