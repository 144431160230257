import * as React from 'react';
import ChartConstants from "../Constants/chart";
import Data from "../Constants/data";
import LineChart from "../chart/Charts/Line";
import { Box, Button, Card, Flex, Loader, Menu, Popup, tabListBehavior, Text, ProviderConsumer as FluentUIThemeConsumer } from "@fluentui/react-northstar";
import { withTranslation, WithTranslation } from "react-i18next";
import { TFunction } from "i18next";
import '../ReportDetailed/reportDetailed.scss';

export interface IReportDetailedState {
    activeTabId: number,
    metrics: any,
    chartData: any,
    chartLabel?: any,
}

interface IReportDetailedProps extends WithTranslation {
    metrics: any;
    filter: any;
    index: number;
}

class ReportDetailed extends React.Component<IReportDetailedProps, IReportDetailedState> {
    private localize: TFunction;

    constructor(props: IReportDetailedProps) {
        super(props);
        this.localize = this.props.t;
        
        this.state = {
            activeTabId: 0,
            metrics: this.props.metrics,
            chartData: {
                labels: this.parseMetrics(this.props.metrics).label,
                datasets: [
                    {
                        label: this.getMetricsLabel(),
                        data: this.getMetricsData(),
                    }
                ],
            }
        }
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if (prevProps.metrics !== this.props.metrics) {
            this.setState({
                activeTabId: this.props.index,
                chartData: {
                    labels: this.parseMetrics(this.props.metrics).label,
                    datasets: [
                        {
                            label: this.getMetricsLabel(),
                            data: this.getMetricsData(),
                        }
                    ],
                }
            })
        }
    }

    public parseMetrics = (metricsData?: any) => {
        let metricsLabel: string[] = [];        
        //let mensagensEnviadas: number[] = [];
        let mensagensVisualizadas: number[] = [];
        let mensagensClicadas: number[] = [];
        let mensagensReagidas: number[] = [];
        
        metricsData?.map((metric: any, index: number) => {
            let month = Data.monthNames[parseInt(metric.Day.substr(4, 2), 10) - 1];
            let day = metric.Day.substr(6, 2).replace(/^0+/, '');

            metricsLabel.push(month + " " + day);

            mensagensVisualizadas.push(metric.MensagensVisualizadas);
            //mensagensEnviadas.push(metric.MensagensEnviadas);
            mensagensClicadas.push(metric.MensagensClicadas);
            mensagensReagidas.push(metric.Reactions);
        });

        return {
            label: metricsLabel,
            totalMessagesViewed: mensagensVisualizadas,
            //totalMessagesSented: mensagensEnviadas,
            totalMessagesClicked: mensagensClicadas,
            totalReactions: mensagensReagidas,
        }
    }

    public setActiveTabDaysId(index: number, item: any) {
        this.props.filter(14, index)
        
        this.setState({
            activeTabId: index
        })
    }

    public getMetricsData() {

        if (this.props.index == 0) {
            return this.parseMetrics(this.props.metrics).totalMessagesViewed;
        }
        else if (this.props.index == 1) {
            return this.parseMetrics(this.props.metrics).totalMessagesClicked;
        }
        else if (this.props.index == 2) {
            return this.parseMetrics(this.props.metrics).totalReactions;
        }
    }

    public getMetricsLabel() {
        
        if (this.props.index == 0) {
            return this.localize("TotalMessagesViewed");
        }
        else if (this.props.index == 1) {
            return this.localize("TotalMessagesClicked");
        }
        else if (this.props.index == 2) {
            return this.localize("TotaMessagesReacted");
        }
    }

    public render() {
        return (
            <Box
                className="report-detailed"
                styles={{
                    display: "grid",
                    gridGap: "1.2rem",                    
                    gridAutoFlow: "dense",
                    gridAutoRows: "17rem 2rem",
                    padding: "1rem 2rem",
                    minWidth: "20rem"                    
                }}
            >
                <Card.Header>
                    <Flex gap="gap.small" space="between" style={{ minHeight: "2rem" }}>
                        <Flex gap="gap.small" column>
                            <Text content={this.localize("ReportDetailed")} style={{ margin: 0 }} weight="bold" />
                        </Flex>
                        <Popup
                            align="end"
                            position="below"
                            open={false}
                            onOpenChange={() => null}
                            content={{
                                styles: { width: "12.5rem" },
                                content: (
                                    <Menu
                                        items={undefined}
                                        vertical
                                    />
                                ),
                            }}
                            trapFocus={{
                                firstFocusableSelector:
                                    ".extended-toolbar__filters-menu__tree [data-is-focusable=true]",
                            }}
                        />
                    </Flex>
                </Card.Header>
                <Card.Body
                    style={{
                        height: "100%",
                        overflow: "hidden",
                    }}
                    fitted
                >
                    {this.props.metrics ?
                        <>
                            
                            <Menu
                                style={{
                                    border: "none",
                                    background: "none",
                                    marginBottom: "1.25rem",
                                }}
                                items={[
                                    {
                                        key: "Views",
                                        content: this.localize("Views")
                                    },
                                    {
                                        key: "Clicks",
                                        content: this.localize("Clicks")
                                    },
                                    {
                                        key: "Reactions",
                                        content: this.localize("Reactions")
                                    }
                                ]}
                                activeIndex={this.props.index}
                                onItemClick={(currentTarget, props) =>
                                    this.setActiveTabDaysId(props && props.index ? props.index : 0, props)
                                }
                                accessibility={tabListBehavior}
                                underlined
                                primary
                            />
                           
                            <Flex
                                className="chartReportDetailed"
                                styles={{
                                    height: "100%",
                                    width: "99%",
                                    display: "flex",
                                }}
                                column
                            >
                                <FluentUIThemeConsumer
                                    render={(globalTheme) => {
                                        return (
                                            <LineChart
                                                {...{
                                                    title: this.localize("ReportDetailed"),
                                                    data: this.state.chartData,
                                                    siteVariables: globalTheme.siteVariables
                                                }}
                                            />
                                        )
                                    }}
                                />
                            </Flex>
                        </>
                        :
                        <svg className="emptyChart" viewBox="0 0 18.777 18.777">
                            <path style={{ fill: "#030104" }} d="M2.245,10.496H0.272C0.122,10.496,0,10.62,0,10.77v5.717c0,0.15,0.122,0.272,0.272,0.272h1.973
			                    c0.15,0,0.272-0.122,0.272-0.272V10.77C2.518,10.62,2.396,10.496,2.245,10.496z"/>
                            <path style={{ fill: "#030104" }} d="M18.504,10.496h-1.973c-0.15,0-0.271,0.124-0.271,0.274v5.717c0,0.15,0.121,0.272,0.271,0.272
			                    h1.973c0.152,0,0.273-0.122,0.273-0.272V10.77C18.777,10.62,18.656,10.496,18.504,10.496z"/>
                            <path style={{ fill: "#030104" }} d="M5.907,7.228H3.934c-0.15,0-0.273,0.122-0.273,0.273v8.984c0,0.15,0.123,0.272,0.273,0.272h1.973
			                    c0.151,0,0.273-0.122,0.273-0.272V7.501C6.18,7.349,6.058,7.228,5.907,7.228z"/>
                            <path style={{ fill: "#030104" }} d="M14.271,7.228h-1.973c-0.15,0-0.271,0.122-0.271,0.273v8.984c0,0.15,0.121,0.272,0.271,0.272h1.973
			                    c0.152,0,0.273-0.122,0.273-0.272V7.501C14.545,7.349,14.424,7.228,14.271,7.228z"/>
                            <path style={{ fill: "#030104" }} d="M10.01,9.218H8.036c-0.15,0-0.272,0.123-0.272,0.272v6.994c0,0.15,0.122,0.272,0.272,0.272h1.974
			                    c0.152,0,0.273-0.122,0.273-0.272V9.49C10.283,9.341,10.162,9.218,10.01,9.218z"/>
                            <path style={{ fill: "#030104" }} d="M1.259,6.947c0.581,0,1.051-0.47,1.051-1.051c0-0.101-0.019-0.196-0.046-0.288l2.211-1.591
			                    c0.136,0.064,0.286,0.102,0.446,0.102c0.309,0,0.583-0.135,0.776-0.347L7.784,4.98c-0.012,0.062-0.02,0.126-0.02,0.19
			                    c0,0.58,0.471,1.051,1.051,1.051c0.559,0,1.012-0.438,1.044-0.989l2.814-0.823c0.191,0.262,0.498,0.435,0.848,0.435
			                    c0.232,0,0.443-0.077,0.617-0.205l2.365,1.604c-0.02,0.083-0.037,0.17-0.037,0.26c0,0.581,0.471,1.052,1.051,1.052
			                    s1.053-0.471,1.053-1.052s-0.473-1.051-1.053-1.051c-0.232,0-0.443,0.077-0.617,0.204l-2.363-1.601
			                    c0.02-0.084,0.035-0.17,0.035-0.26c0-0.581-0.469-1.051-1.051-1.051c-0.559,0-1.012,0.438-1.045,0.989L9.663,4.555
			                    C9.472,4.292,9.164,4.12,8.815,4.12c-0.259,0-0.492,0.096-0.675,0.251L5.968,3.112c0-0.015,0.004-0.028,0.004-0.042
			                    c0-0.581-0.47-1.052-1.051-1.052S3.87,2.488,3.87,3.069c0,0.158,0.038,0.306,0.1,0.441L1.855,5.032
			                    C1.686,4.914,1.481,4.845,1.259,4.845c-0.581,0-1.051,0.471-1.051,1.051C0.208,6.477,0.678,6.947,1.259,6.947z"/>
                        </svg>
                    }

                </Card.Body>
            </Box>
        );
    }
}

const reportDetailedWithTranslation = withTranslation()(ReportDetailed);
export default reportDetailedWithTranslation;