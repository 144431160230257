import * as React from "react";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { withTranslation, WithTranslation } from "react-i18next";
import { TFunction } from "i18next";
import i18n from '../../i18n';
import { app } from "@microsoft/teams-js";
import { Button, Flex, FlexItem, Image, Input, Segment, Text, Tooltip } from "@fluentui/react-northstar";
import { BoldIcon, EmojiIcon } from "@fluentui/react-northstar";
import MDEditor, {
    commands,
    ICommand,
    TextState,
    TextAreaTextApi,
    selectWord,
    getBreaksNeededForEmptyLineBefore,
    getBreaksNeededForEmptyLineAfter,
} from "@uiw/react-md-editor";
import { bold, italic, link, orderedListCommand, unorderedListCommand } from '../MarkdownEditor/customCommands'
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';
import './markdownEditor.scss';

export interface MarkdownEditorProps extends WithTranslation {
    placeholder?: string;
    value?: string;
    onChange: (value: any) => void;
}

export interface MarkdownEditorState {
    theme?: string,
    emojiSelected?: any;
}

//const bold = {
//    name: 'bold',
//    keyCommand: 'bold',
//    shortcuts: 'ctrlcmd+b',
//    buttonProps: { 'aria-label': i18n.t("AddBold") },
//    icon: (
//        <Tooltip
//            trigger={
//                <BoldIcon outline />
//            }
//            content={i18n.t("AddBold")}
//        />
//    ),
//    execute: (state: TextState, api: TextAreaTextApi) => {
//        // Adjust the selection to encompass the whole word if the caret is inside one
//        const newSelectionRange = selectWord({ text: state.text, selection: state.selection });
//        const state1 = api.setSelectionRange(newSelectionRange);
//        // Replaces the current selection with the bold mark up
//        const state2 = api.replaceSelection(`**${state1.selectedText}**`);
//        // Adjust the selection to not contain the **
//        api.setSelectionRange({
//            start: state2.selection.end - 2 - state1.selectedText.length,
//            end: state2.selection.end - 2,
//        });
//    },
//};

class MarkdownEditor extends React.Component<MarkdownEditorProps, MarkdownEditorState> {
    readonly localize: TFunction;

    constructor(props: MarkdownEditorProps) {
        super(props);
        this.localize = this.props.t;

        this.state = {
            theme: ""
        }
    }

    public async componentDidMount() {
        void app.initialize().then(() => {
            void app.getContext().then((context: any) => {
                this.setState({
                    theme: context.app.theme,
                })
            });
        });        
    }

    public render() { 
        return (
            <div data-color-mode={this.state.theme}>
                <MDEditor
                    placeholder={this.props.placeholder}
                    preview={'edit'}
                    visibleDragbar={false}
                    height={150}
                    value={this.props.value}
                    onChange={this.props.onChange}
                    commands={[
                        bold(this.localize),
                        italic(this.localize),
                        link(this.localize),
                        unorderedListCommand(this.localize),
                        orderedListCommand(this.localize),
                        commands.group([], {
                            name: "update",
                            groupName: "update",
                            icon: (
                                <Tooltip
                                    trigger={
                                        <EmojiIcon outline />
                                    }
                                    content={this.localize("AddEmoji")}
                                />
                            ),
                            children: (handle: any) => {
                                return (
                                    <Picker
                                        native
                                        color={"#6264a7"}
                                        theme={this.state.theme == "dark" ? "dark" : "light"}
                                        showPreview={false}
                                        enableFrequentEmojiSort={true}
                                        title={""}
                                        emoji={""}
                                        onSelect={(emojiObject: any) => {
                                            this.setState({
                                                emojiSelected: emojiObject.native
                                            }, () => {
                                                handle.execute();
                                            });
                                        }}
                                        i18n={{
                                            search: this.localize('Search'),
                                            notfound: this.localize('NoEmojiFound'),
                                            categories: {
                                                search: this.localize('SearchResults'),
                                                recent: this.localize('FrequentlyUsed'),
                                                people: this.localize('PeopleBody'),
                                                nature: this.localize('AnimalsNature'),
                                                foods: this.localize('FoodDrink'),
                                                activity: this.localize('Activity'),
                                                places: this.localize('TravelPlaces'),
                                                objects: this.localize('Objects'),
                                                symbols: this.localize('Symbols'),
                                                flags: this.localize('Flags'),
                                                custom: this.localize('Custom'),
                                            }
                                        }}
                                    />
                                );
                            },
                            execute: (
                                state: commands.TextState,
                                api: commands.TextAreaTextApi
                            ) => {
                                if (this.state.emojiSelected) {
                                    api.replaceSelection(this.state.emojiSelected);
                                }
                                this.setState({
                                    emojiSelected: null
                                })
                            },
                            buttonProps: { "aria-label": "Insert Emoji" }
                        })
                    ]}
                    extraCommands={[]}
                />
            </div>
        );
    }
}


const markdownEditorWithTranslation = withTranslation()(MarkdownEditor);
export default markdownEditorWithTranslation;