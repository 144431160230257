import * as React from "react";
import { app, dialog } from "@microsoft/teams-js";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { initializeIcons } from '@fluentui/react';
import { withTranslation, WithTranslation } from "react-i18next";
import { TFunction } from "i18next";
import moment, { locale } from "moment";
import { getQuizData } from '../../apis/messageListApi';
import { Button, Checkbox, Flex, FlexItem, Image, Text, RadioGroup } from "@fluentui/react-northstar";
import {
    ChevronEndIcon, ChevronStartIcon
} from '@fluentui/react-icons-northstar';
import "./quizRespondPage.scss";
import { ActionDataColumnValueType, PagesQuiz } from "../../utility/SharedEnum";
import { Utils } from "../../utility/Utils";

export interface QuizRespondState {
    data: any;
    questionLength: number;
    questionNumber: number;
    currentPage: PagesQuiz;
    submitButtonDisabled: boolean;
}

export type QuizRespondProps = {} & RouteComponentProps & WithTranslation;

class QuizRespond extends React.Component<QuizRespondProps, QuizRespondState> {
    private localize: TFunction;
    aadId: any = null;
    tid: any = null;
    searchParam?: any = null;    

    constructor(props: QuizRespondProps) {
        super(props);
        this.localize = this.props.t;

        this.state = {
            data: null,
            questionNumber: 1,
            questionLength: 1,
            currentPage: PagesQuiz.Start,
            submitButtonDisabled: false,
        }

        this.searchParam = new URLSearchParams(window.location.search);
    }

    public async componentDidMount(){
        void app.initialize().then(() => {
            void app.getContext().then((context: any) => {
                this.aadId = context.user.id;
                this.tid = context.user.tenant.id;

                if (this.searchParam.get("code")) {
                    this.getQuizData(this.searchParam.get("code"));
                }
            });
        });
    }

    private getQuizData = async (code: string) => {
        try {
            const response = await getQuizData(code, this.tid, this.aadId);
            const data = response.data;
            
            if (data)
            {
                this.setState({
                    data: data,
                    questionLength: data.quiz?.questions?.length,
                })
            }
        } catch (error) {
            return error;
        }
    }

    public renderFooterSection() {
        
        return (
            <Flex className="footer-layout" gap={"gap.smaller"}>                
                <FlexItem push>
                    <Button
                        primary
                        content={this.localize("start")}
                        onClick={() => { this.goToPage(PagesQuiz.QuestionContainer) }}>
                    </Button>
                </FlexItem>
            </Flex>
        );
    }

    private goToPage = (page: PagesQuiz) => {
        this.setState({
            currentPage: page
        });
    }

    private nextQuestion = () => {        
        this.setState({
            //questionNumber: (this.state.questionNumber + 1) > this.state.questionLength ? this.state.questionNumber : (this.state.questionNumber + 1),
            questionNumber: (this.state.questionNumber + 1),
        })
    }

    private prevQuestion = () => {
        this.setState({
            questionNumber: (this.state.questionNumber - 1),
        })
    }

    private isNextButtonDisabled = () => {
        return this.state.data.quiz?.questions[this.state.questionNumber - 1].options?.some((opt: any) => opt.checked == true);
    }

    public renderQuestionContainer() : JSX.Element{
       
        return (
           <Flex column gap="gap.medium">
                {this.state.data?.quiz?.imageQuiz &&
                    <Flex hAlign="start">
                        <FlexItem>
                            <Image className="quiz-img-preview" src={this.state.data?.quiz?.imageQuiz} />
                        </FlexItem>
                    </Flex>
                }                                
               <FlexItem>
                    <Text weight="bold" size="medium" content={ `${this.localize("question")} # ${this.state.questionNumber}` } ></Text>
               </FlexItem>
               <FlexItem>
                   <Text size="large" content={this.state.data?.quiz?.questions[this.state.questionNumber - 1].text} ></Text>
               </FlexItem>
               <FlexItem>
                    {this.renderQuestionOptions(this.state.questionNumber - 1)}
               </FlexItem>
           </Flex>
        );
    }

    public getItemsRadioGroup = (questionIndex: number) => {
        
        let optionChecked: string = "";
        let items: any[] = [];
        
        this.state.data.quiz?.questions[questionIndex].options.forEach((opt: any, index: number) => {
            
            if (opt.checked) {
                optionChecked = opt.name
            }

            items.push(
                {
                    name: `${questionIndex}`,
                    key: `${questionIndex}-${opt.name}` ,
                    label: (
                        <Flex column>
                            {opt.image &&
                                <Flex className="questions-img-box">
                                    <FlexItem>
                                        <Image className="questions-img-preview" src={opt.image}></Image>
                                    </FlexItem>  
                                </Flex>
                            }                            
                            <FlexItem>
                                <Text content={opt.text}></Text>
                            </FlexItem>
                        </Flex>
                    ) ,
                    value: opt.name,
                    checked: opt.checked
                }
            )
        })

        return (
            <RadioGroup
                vertical
                checkedValue={optionChecked}
                items={items}
                onCheckedValueChange={this.setOptionValueRadio}
            />
        );
    }

    public getItemsCheckBox = (questionIndex: number) => {

        let items: any[] = [];
        this.state.data.quiz?.questions[questionIndex].options.forEach((opt: any, index: number) => {
            items.push(
                <Checkbox
                    id={opt.name}
                    checked={opt.checked}
                    label={
                        <Flex column>
                            {opt.image &&
                                <Flex className="questions-img-box">
                                    <FlexItem>
                                        <Image className="questions-img-preview" src={opt.image}></Image>
                                    </FlexItem>  
                                </Flex>
                            }                            
                            <FlexItem>
                                <Text content={opt.text}></Text>
                            </FlexItem>
                        </Flex>
                    }
                    onClick={(e: any, props: any) => {
                        this.setOptionValueCheckbox(questionIndex, opt.name, props.checked)
                    }}                        
                />
            )
        });

        return (
            <React.Fragment>
                {items}
            </React.Fragment>
        );
    }

    public setOptionValueCheckbox = (indexQuestion: number, indexOption: number, value: boolean) => { 
        const questionsCopy = [...this.state.data.quiz?.questions];
        
        questionsCopy[indexQuestion].options[indexOption].checked = value;

        this.setState({
            data: {
                ...this.state.data,
                quiz: {
                    ...this.state.data.quiz,
                    questions: questionsCopy,
                }                
            }
        })
    }

    public renderQuestionOptions(questionIndex: number) : JSX.Element {       
        
        let valueTypeQuestion = this.state.data.quiz?.questions[questionIndex].valueType;

        if (valueTypeQuestion == ActionDataColumnValueType.SingleOption) {
            return (
                <Flex>
                    {this.getItemsRadioGroup(questionIndex)}
                </Flex>                
            );            
        }
        else {
            return (
                <Flex column className="options-checkbox">
                    <FlexItem>
                        {this.getItemsCheckBox(questionIndex)}    
                    </FlexItem>                    
                </Flex>
            );
        }
    }

    public renderFooterQuestion() : JSX.Element{
        return (
            <Flex space="between" className="footer-layout"> 
                <FlexItem>
                    <Button icon={<ChevronStartIcon />} onClick={() => this.prevQuestion()} disabled={this.state.questionNumber == 1} content={this.localize("prev")} text primary />
                </FlexItem>
                <FlexItem>
                    <Text content={Utils.getStringLocalizer(this.localize("xofy"), ...[this.state.questionNumber, this.state.questionLength] )} ></Text>
                </FlexItem>
                
                {this.state.questionNumber >= this.state.questionLength &&
                    <FlexItem>
                        <Button 
                            content={this.localize("submit")} 
                            primary 
                            disabled={(this.state.submitButtonDisabled || !this.isNextButtonDisabled())}
                            onClick={() => 
                                this.sendResponseUser()
                            }
                        />
                    </FlexItem>
                }
                    
                {this.state.questionNumber < this.state.questionLength &&
                    <FlexItem>
                        <Button
                            icon={<ChevronEndIcon />}
                            iconPosition="after"
                            onClick={() => this.nextQuestion()}
                            disabled={!this.isNextButtonDisabled()}
                            content={this.localize("next")}
                            text
                            primary
                        />
                    </FlexItem>
                }                

            </Flex>
        );
    }

    public sendResponseUser = () => {

        this.setState({
            submitButtonDisabled: true,
        })

        let response = {
            quiz: this.state.data,               
            aadId: this.aadId,
            tid: this.tid,
            code: this.searchParam.get("code")
        }

        dialog.url.submit(response);
    }

    public setOptionValueRadio = (e: any, props: any) => {
        const questionsCopy = [...this.state.data.quiz?.questions];

        questionsCopy[props.name].options.forEach((opt: any, index: number) => {
            
            if (props.value == opt.name) {
                opt.checked = true;
            }
            else{
                opt.checked = false;
            }

        });   

        this.setState({
            data: {
                ...this.state.data,
                quiz: {
                    ...this.state.data.quiz,
                    questions: questionsCopy,
                }                
            }
        })

    }

    public renderStartPage = () => {
        var totalQuestions = [
            this.state.data?.quiz?.questions.length,
            this.state.data?.quiz?.questions.length > 1 ? this.localize("questions") : this.localize("question")
        ]

        return (
            <Flex column gap="gap.medium">
                {this.state.data?.quiz?.imageQuiz &&
                    <FlexItem>                        
                        <Flex hAlign="start">
                            <Image className="quiz-img-preview" src={this.state.data?.quiz?.imageQuiz} ></Image>
                        </Flex>                    
                    </FlexItem>
                }
                <FlexItem>
                    <Text weight="bold" size="large" content={this.state.data?.quiz?.title}></Text>
                </FlexItem>
                <FlexItem>
                    <Text content={this.state.data?.quiz?.description}></Text>
                </FlexItem>
                <FlexItem>
                    <Text weight="semibold" size="small" content={Utils.getStringLocalizer(this.localize("totalQuestionQuiz"), ...totalQuestions)} ></Text>
                </FlexItem>

            </Flex>
        )
    }

    public render(): JSX.Element {

        return (

            <div className="quiz-respond">
                <div className="formContainer">
                    <Flex column className="body-container">

                        {this.state.data?.quiz && this.state.currentPage == PagesQuiz.Start && 
                            <Flex column>
                                {this.renderStartPage()}
                                {this.renderFooterSection()}
                            </Flex>
                        }

                        {this.state.data?.quiz && this.state.currentPage == PagesQuiz.QuestionContainer &&
                            <Flex column>
                                {this.renderQuestionContainer()}
                                {this.renderFooterQuestion()}
                            </Flex>                            
                        }
                        
                    </Flex>
                </div>
            </div>


        );
    }

    
}

const quizRespondWithTranslation = withTranslation()(QuizRespond);
export default quizRespondWithTranslation;