import * as React from "react";
import { Flex, FlexItem } from "@fluentui/react-northstar";
import DatePickerView, { IDatePickerViewProps } from "./DatePickerView";
import { TimePickerView, ITimePickerViewProps } from "./TimePickerView";

export interface IDateTimePickerViewProps {
    placeholderDate?: string;
    placeholderTime?: string;
    value?: Date;
    minDate?: Date;
    intervalMinutes?: number;
    disabled?: boolean;
    isPreview?: boolean;
    locale?: string;
    onSelect?: (date: Date) => void;
}

export interface IDateTimePickerViewState {
    selectedDate: Date;
}

/**
 * <DateTimePickerView> component to provide datetime input
 */
export class DateTimePickerView extends React.Component<IDateTimePickerViewProps, IDateTimePickerViewState> {

    constructor(props: IDateTimePickerViewProps) {
        super(props);
        this.state = {
            selectedDate: this.props.value ? this.props.value : new Date(),
        };

        if(this.props.onSelect){
            this.props.onSelect(this.state.selectedDate);
        }
    }

    public async componentWillReceiveProps(nextProps: any) {
        if(nextProps !== this.props) {
            this.setState({
                selectedDate: nextProps.value,
            });
        }        
    }


    render() {
        let props: IDatePickerViewProps = {
            placeholder: this.props.placeholderDate,
            date: this.state.selectedDate,
            minDate: this.props.minDate,
            disabled: this.props.disabled,
            locale: this.props.locale,
            onSelectDate: (newDate: Date) => {
                if (!this.props.isPreview) {
                    this.dateSelectCallback(newDate);
                }
            }
        };

        let timePickerProps: ITimePickerViewProps = {
            placeholder: this.props.placeholderTime,
            minTimeInMinutes: this.getMinTimeInMinutes(this.state.selectedDate),
            date: this.state.selectedDate,
            intervalMinutes: this.props.intervalMinutes,
            defaultTimeInMinutes: DateTimePickerView.getTimeInMinutes(this.state.selectedDate),
            disabled: this.props.disabled,
            onTimeChange: (dateWithTime: Date) => {
                this.timeSelectCallback(dateWithTime);
            },
            locale: this.props.locale
        };
        
        return (
            <Flex gap="gap.small" /*gap={this.props.renderForMobile ? null : "gap.small"} space={this.props.renderForMobile ? "between" : null}*/ >
                <DatePickerView {...props} />
                <TimePickerView {...timePickerProps} />
            </Flex>
        );
    }

    dateSelectCallback(newDate: Date) {
        let updatedDate = newDate;        
        let today = new Date();
        let isSelectedDateToday = updatedDate.getDate() == today.getDate() && updatedDate.getMonth() == today.getMonth()
                && updatedDate.getFullYear() == today.getFullYear();

        if (isSelectedDateToday) {
            updatedDate = this.getRoundedDate(this.props.intervalMinutes);
        }
        else{
            updatedDate.setHours(this.state.selectedDate.getHours());
            updatedDate.setMinutes(this.state.selectedDate.getMinutes());
        }

        this.setState({
            selectedDate: updatedDate
        });

        if(this.props.onSelect){
            this.props.onSelect(updatedDate);
        }
    }

    private getRoundedDate = (minutes: number = 30, d = new Date()) => {

        let ms = 1000 * 60 * minutes; // convert minutes to ms
        let roundedDate = new Date(Math.ceil(d.getTime() / ms) * ms);

        return roundedDate;
    }

    timeSelectCallback(dateWithTime: Date) {        
        this.setState({
            selectedDate: dateWithTime
        });
        if(this.props.onSelect){
            this.props.onSelect(dateWithTime);
        }
    }

    getMinTimeInMinutes(givenDate: Date) {
        let isSelectedDateToday: boolean = false;
        let today = new Date();
        if (givenDate) {
            isSelectedDateToday = givenDate.getDate() == today.getDate() && givenDate.getMonth() == today.getMonth()
                && givenDate.getFullYear() == today.getFullYear();
        }

        let minTime: number = 0;
        if (isSelectedDateToday) {
            minTime = today.getHours() * 60 + today.getMinutes();
        }
        return minTime;
    }

    static getTimeInMinutes(givenDate: Date) {
        let defaultTime: number = 0;
        if (givenDate) {
            defaultTime = givenDate.getHours() * 60 + givenDate.getMinutes();
        }
        return defaultTime;
    }
}