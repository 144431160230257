import * as React from "react";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { initializeIcons } from '@fluentui/react';
import { withTranslation, WithTranslation } from "react-i18next";
import { TFunction } from "i18next";
import moment, { locale } from "moment";
import { Utils } from "../../../utility/Utils";
import { UxUtils } from "../../../utility/UxUtils";
import { Flex, ChevronStartIcon, Text, Menu, ArrowLeftIcon } from "@fluentui/react-northstar";
import { ViewTypeSummary } from "../../../utility/SharedEnum";
import NonResponderView from "./nonResponderView";
import ResponderView from "./responderView";


export interface TabViewState {}

export interface TabViewProps extends WithTranslation {
    notificationData: any;
    pollData: any;
    answerData: any;
    currentView: ViewTypeSummary;
    setCurrentView: (value:any) => void;
}

class TabView extends React.Component<TabViewProps, TabViewState> {

    private localize: TFunction;
   
    constructor(props: TabViewProps) {
        super(props);
        
        this.localize = this.props.t;
    }

    public render() {
        // Quantidade de pessoas que responderam
        let rowCount: number = this.props.answerData ? this.props.answerData?.totalUsersAnswered : 0;
        // Quantidade de pessoas que receberam o Poll
        let memberCount: number = this.props.notificationData ? this.props.notificationData?.totalMessageCount : 0;
        
        let participation: string =
            rowCount == 1
                ? Utils.getStringLocalizer(this.localize("ParticipationIndicatorSingular"),  rowCount, memberCount)
                : Utils.getStringLocalizer(this.localize("ParticipationIndicatorPlural"),  rowCount, memberCount);

        return (
            <Flex column className="tabview-container no-mobile-footer">
                <Text className="participation-title" size="small" weight="bold">
                    {participation}
                </Text>
                <Menu
                    role="tablist"
                    fluid
                    defaultActiveIndex={0}
                    items={this.getItemsMenu()}
                    underlined
                    primary
                />
                {this.props.currentView == ViewTypeSummary.ResponderView ? 
                    (<ResponderView 
                        answerData={this.props.answerData}
                        pollData={this.props.pollData}
                    />) 
                : 
                    (<NonResponderView />)
                }
                {this.getFooterElement()}
            </Flex>
        );
    }

    private getItemsMenu() {
        return [
            {
                key: "responders",
                role: "tab",
                "aria-selected": this.props.currentView == ViewTypeSummary.ResponderView,
                "aria-label": this.localize("Responders"),
                content: this.localize("Responders"),
                onClick: () => {
                    this.props.setCurrentView(ViewTypeSummary.ResponderView);
                },
            },
            // {
            //     key: "nonResponders",
            //     role: "tab",
            //     "aria-selected":this.props.currentView == ViewTypeSummary.NonResponderView,
            //     "aria-label": this.localize("NonResponders"),
            //     content: this.localize("NonResponders"),
            //     onClick: () => {
            //         this.props.setCurrentView(ViewTypeSummary.NonResponderView);
            //     },
            // },
        ];
    }

    private getFooterElement() {
        
        return (
            <Flex className="footer-layout tab-view-footer" gap={"gap.smaller"}>
                <Flex
                    vAlign="center"
                    className="pointer-cursor"
                    {...UxUtils.getTabKeyProps()}
                    onClick={() => {
                        this.props.setCurrentView(ViewTypeSummary.Main);
                    }}
                >
                    <ChevronStartIcon xSpacing="after" size="small" />
                    <Text content={this.localize("Back")} />
                </Flex>
            </Flex>
        );
    }

}

const tabViewWithTranslation = withTranslation()(TabView);
export default tabViewWithTranslation;