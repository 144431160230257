export class NewQuizConstants {

    public static newOption = (stringPlaceHolder: string) => {
        var newOption = {
            text: "",            
            image: "",
            optionError: false,
            imageName: "",
            checked: false,
            correctOption: false,
            choicePlaceholder: stringPlaceHolder
        }
        
        return newOption;
    };
}