import * as React from "react";
import { app } from "@microsoft/teams-js";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { initializeIcons } from '@fluentui/react';
import { withTranslation, WithTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { Box, Flex, Text, ProviderConsumer as FluentUIThemeConsumer } from "@fluentui/react-northstar";
import "./summary.scss";
import "./answersResultsPage.scss";
import { getAnswersPollResultsData } from '../../../apis/messageListApi';
import PieChart from "../../chart/Charts/Pie";
import { NewPollConstants } from "../../Constants/newPoll";

export interface AnswersResultsPageState {
    answersData: any;
    chartData: any; 
    loaderAnswers: boolean;
}

export type AnswersResultsPageProps = {} & RouteComponentProps & WithTranslation;

class AnswersResultsPage extends React.Component<AnswersResultsPageProps, AnswersResultsPageState> {

    private localize: TFunction;
    aadId: any = null;
    tid: any = null;
    searchParam?: any = null;

    constructor(props: AnswersResultsPageProps) {
        super(props);
        
        this.localize = this.props.t;

        this.state = {
            answersData: null,
            loaderAnswers: true,
            chartData: {
                labels: [],
                datasets: [
                    {
                        data: [],
                    },
                ],
            },
        }

        this.searchParam = new URLSearchParams(window.location.search);
    }

    public async componentDidMount(){
        void app.initialize().then(() => {
            void app.getContext().then((context: any) => {
                this.aadId = context.user.id;
                this.tid = context.user.tenant.id;

                if (this.searchParam.get("code")) {
                    this.getAnswerData(this.searchParam.get("code"));
                }
            });
        });
    }

    public render() {    
                  
        return (
            <Flex 
                gap="gap.medium" 
                column 
                className="body-container"
                id="bodyContainer"
            >              

                {this.state.loaderAnswers ?
                    <Flex column styles={{marginTop: "4.3rem"}}>
                        <Flex vAlign="center" hAlign="center">
                            <svg viewBox="0 0 64 64" className="emptyPie">
                                <circle r="25%" cx="50%" cy="50%" style={{ strokeDasharray: "75.3 100" }}>
                                </circle>
                                <circle r="25%" cx="50%" cy="50%" style={{ strokeDasharray: "19.9 100", stroke: "#D1D2D4", strokeDashoffset: "-75.3", animationDelay: "0.25s" }} >
                                </circle>
                            </svg>
                        </Flex>
                        
                        <Flex vAlign="center" hAlign="center" styles={{ padding: "2rem 4rem" }}>                
                            <Text size="smaller" weight="bold" content={this.localize("LoadingText")} styles={{ color: "rgb(138, 138, 138)" }} />
                        </Flex>
                    </Flex>
                :
                    <Flex column>

                        <Text size="large" content={this.state.answersData[0].questionTitle} styles={{marginBottom: "2rem"}} />

                        <FluentUIThemeConsumer
                            render={(globalTheme) => {
                                return (
                                    <PieChart
                                        {...{
                                            title: this.localize("VisaoGeralAtendimentos"),
                                            data: this.state.chartData,
                                            siteVariables: globalTheme.siteVariables,
                                        }}
                                    />
                                )
                            }}
                        />

                        <Flex
                            className="chartLabels"
                            gap="gap.small"
                            styles={{
                                marginTop: "3rem",
                                height: "5.5rem",
                            }}
                            column
                        >
                            {this.getLegendChart()}                            
                        </Flex>

                    </Flex>                                        
                }
                
            </Flex>

            
         );       
    }

    private getAnswerData = async (code: string) => {
        try {
            const response = await getAnswersPollResultsData(code, this.tid, this.aadId);
            const data = response.data;
            
            var labels:any[] = [];
            var dataChart:any[] = [];
            
            data.forEach((answer: any) => { 
                labels.push(answer.answerTitle);
                dataChart.push(answer.score);
            });            

            this.setState({
                loaderAnswers: false,
                answersData: data,
                chartData: {
                    labels: labels,
                    datasets: [
                        {
                            data: dataChart,
                        },
                    ],
                }                
            })
        } catch (error) {
            return error;
        }
    }

    private getLegendChart = () => {
        var legendChart: any[] = []
        
        this.state.chartData.labels.forEach((label: string, index: number) => {
            legendChart.push(
                <Flex>
                    <Box styles={{ backgroundColor: NewPollConstants.ColorsChartPie[index], width: ".5rem", height: "2rem", marginRight: "1.5rem", marginTop: ".5rem" }}></Box>
                    <Flex column styles={{marginTop: ".5rem"}}>
                        <Text size="small" content={`${this.state.chartData?.datasets[0].data[index]}%`} />
                        <Text size="smaller" weight="bold" content={label} />
                    </Flex>
                </Flex>
            );
        })

        return legendChart;
    }
}


const answersResultsPageWithTranslation = withTranslation()(AnswersResultsPage);
export default answersResultsPageWithTranslation;